<template>
  <div class="row mt-3 gy-3">
    <div class="col col-xs-12">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="agreeTermsCheck" v-model="agree" @change="emitDisclaimerAgreed()">
        <label class="form-check-label" for="agreeTermsCheck">
          {{ $t('registration.informationCheck') }}
        </label>
      </div>
      <div :class="{ 'has-error': agreementChecked }">
        <span v-if="agreementChecked" class="help-block" >{{ $t(agreementChecked)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CorrectDataDisclaimer',
    props: {
      attempts: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        agree: false
      }
    }, 
    computed: {
      agreementChecked() {
        if (this.attempts > 0) {
          if (!this.agree) return 'registration.agreementNotChecked'
        }

        return ''
      }
    },
    methods: {
      emitDisclaimerAgreed() {
        this.$emit('disclaimer-agreed', this.agree)
      }      
    }
  }
</script>