<template>
  <div class="carousel-item" v-bind:class="{ active: isActive }">
    <div class="row">
      <div class="col">
        <div class="card carousel-card">
          <div
              class="opp-container opp-container__carousel opp-container--is-aligned"
              :style="columnStyle"
          >
            <opportunity-miniature
                v-for="opp in opportunities"
                :key="opp.id"
                :opportunity="opp"
                :isEditable="false"
                @showOpportunityDetail="$emit('showOpportunityDetail', $event)"
                :isCompact="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OpportunityMiniature from "@/components/opportunity/OpportunityMiniature.vue";

export default {
  name: "CarouselItems",
  components: {
    OpportunityMiniature
  },
  props: {
    isActive: {
      type: Boolean,
      default: true
    },
    opportunities: {
      type: Array,
      default: () => []
    },
    maxOppsPerRow: {
      type: Number,
      default: 4
    }
  },
  computed: {
    columnStyle() {
      return (
          "grid-template-columns: repeat(" +
          this.maxOppsPerRow +
          ", minmax(0, 1fr))"
      );
    }
  }
};
</script>
