<template>
  <div v-if="isRequestInProgress">
    <loading></loading>
  </div>
  <div v-else>
    <div class="white-box">
      <div class="col h2 text-center">
        <p><strong>{{ $t("registration.passwordReset") }} </strong></p>
      </div>
      <div class="col h2 mb-3 gy-3">
        <p class="h3"> {{ $t("registration.passwordResetText") }} </p>
      </div>
      <div class="row mb-3 gy-3" :class="{ 'has-error': newPasswordError }">
        <div class="col col-md-6 col-lg-6">
          <label class="control-label" for="newPassword">{{
              $t("registration.newPassword")
            }}</label>
          <password-input
            :autocomplete="'on'"
            :input-id="'newPasswordInput'"
            v-model="newPassword">
          </password-input>
          <span v-if="newPasswordError" class="help-block">{{
              $t(newPasswordError)
            }}</span>
        </div>
      </div>
      <div class="row mb-3 gy-3" :class="{ 'has-error': newPasswordConfError }">
        <div class="col col-md-6 col-lg-6">
          <label class="control-label" for="newPasswordConf">{{
              $t("registration.newPasswordConf")
            }}</label>
          <password-input
              :autocomplete="'on'"
              :input-id="'newPasswordInputConf'"
              v-model="newPasswordConf">
          </password-input>
          <span v-if="newPasswordConfError" class="help-block">{{
              $t(newPasswordConfError)
            }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button
              @click="resetPassword()"
              class="btn btn-lg btn-primary"
              type="submit"
          >
            {{ $t("passwordRecovery.submit") }}
          </button>
        </div>
      </div>

      <div v-if="errorMessage" class="form-group has-error">
        <p class="help-block">{{ $t(errorMessage) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {ApiService} from "@/rest.js";
import {validPasswordLight} from "@/validations";
import Loading from "@/components/utilities/Loading.vue";
import Modal from "@/components/utilities/Modal.vue";
import PasswordInput from "@/components/user/PasswordInput.vue";

export default {
  name: "SetNewPassword",
  components: {
    Loading,
    Modal,
    PasswordInput
  },
  data() {
    return {
      newPassword: "",
      newPasswordConf: "",
      errorMessage: "",
      isRequestInProgress: false,
      attempts: 0
    };
  },
  props: {
    passwordResetToken: {
      type: String,
      default: ""
    }
  },
  computed: {
    newPasswordError() {
      if (this.attempts > 0) {
        if (!validPasswordLight(this.newPassword))
          return "error-messages.invalidPassword";
      }

      return "";
    },
    newPasswordConfError() {
      if (this.newPassword.length > 0 && this.newPasswordConf.length > 0) {
        if (this.newPassword !== this.newPasswordConf)
          return "error-messages.invalidPasswordConfirm";
      }

      return "";
    }
  },
  methods: {
    validateForm() {
      if (!validPasswordLight(this.newPassword)) return false;
      return this.newPassword === this.newPasswordConf;


    },
    resetPassword() {
      this.errorMessage = "";
      this.attempts++;

      if (this.validateForm()) {
        this.isRequestInProgress = true;
        ApiService.POST(
            "/users/passwordReset",
            {
              token: this.passwordResetToken,
              password: this.newPassword
            },
            (err, apiResponse) => {
              this.isRequestInProgress = false;
              if (err) {
                this.errorMessage = "error-messages.errorOccurred";
              } else {
                this.$router.push("/setNewPasswordResult");
              }
            }
        );
      }
    }
  }
};
</script>
