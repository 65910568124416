const currentYear = new Date().getFullYear();

class GlobalParameter {
  constructor({
    id = null,
    identifier = "",
    label = "",
    description = "",
    quantity = 0
  } = {}) {
    this.id = id;
    this.identifier = identifier;
    this.label = label;
    this.description = description;
    this.quantity = quantity;
  }
}

class Opportunity {
  constructor({
    id = null,
    status = "",
    brand = "",
    model = "",
    style = "",
    motorSizeCC = "",
    year = currentYear,
    mileage = "",
    price = 0,
    exteriorColor = "",
    interiorColor = "",
    fuelType = "GASOLINE",
    transmissionType = "MANUAL",
    knownDamage = "",
    payTaxes = "",
    carIdNumber = "",
    passengersNumber = "",
    province = "",
    canton = "",
    district = "",
    extraList = "",
    phoneList = "",
    attachmentIds = "",
    user = {},
    currency = "CRC",
    level = 4,
    message = "",
    autoApproval = false,
    mileageUnit = "KM",
    transferPrice = 0
  } = {}) {
    this.id = id;
    this.brand = brand;
    this.status = status;
    this.model = model;
    this.style = style;
    this.motorSizeCC = motorSizeCC;
    this.year = year;
    this.mileage = mileage;
    this.price = price;
    this.exteriorColor = exteriorColor;
    this.interiorColor = interiorColor;
    this.fuelType = fuelType;
    this.transmissionType = transmissionType;
    this.knownDamage = knownDamage;
    this.payTaxes = payTaxes;
    this.carIdNumber = carIdNumber;
    this.passengersNumber = passengersNumber;
    this.province = province;
    this.canton = canton;
    this.district = district;
    this.extraList = extraList;
    this.phoneList = phoneList;
    this.attachmentIds = attachmentIds;
    this.user = new User();
    this.currency = currency;
    this.level = level;
    this.message = message;
    this.autoApproval = autoApproval;
    this.mileageUnit = mileageUnit;
    this.transferPrice = transferPrice;
  }
}

class Invoice {
  constructor ({
    id = null,
    opportunityId = null,
    codigoint = "",
    amount = "0.00",
    idType = "NATIONAL_ID",
    identification = "",
    fullname = "",
    email = "",
    phone = "",
    province = "",
    canton = "",
    district = "",
    address = "",
    refnum = "",
    status = "NEW",
    paymentMethod = null
  } = {}) {
    this.id = id;
    this.opportunityId = opportunityId;
    this.codigoint = codigoint;
    this.amount = amount;
    this.idType = idType;
    this.identification = identification;
    this.fullname = fullname;
    this.email = email;
    this.phone = phone;
    this.province = province;
    this.canton = canton;
    this.district = district;
    this.address = address;
    this.refnum = refnum;
    this.status = status;
    this.paymentMethod = paymentMethod;
  }
}

class User {
  constructor({
    id = null,
    email = "",
    password = "",
    name = "",
    lastName = "",
    lastName2 = "",
    userType = "PERSON",
    province = "",
    canton = "",
    district = "",
    userRol = null,
    idType = "NATIONAL_ID",
    identification = "",
    status = "",
    language = "",
    phoneList = [],
    merchantName = ""
  } = {}) {
    this.id = id;
    this.email = email;
    this.password = password;
    this.name = name;
    this.lastName = lastName;
    this.lastName2 = lastName2;
    this.userType = userType;
    this.province = province;
    this.canton = canton;
    this.district = district;
    this.userRol = userRol;
    this.idType = idType;
    this.identification = identification;
    this.status = status;
    this.language = language;
    this.phoneList = phoneList;
    this.merchantName = merchantName;
  }
}

class UserInfo {
  constructor({
    id = null,
    name = "",
    lastName = "",
    lastName2 = "",
    role = "",
    isLogged = false,
    isAdmin = false,
    language = ""
  } = {}) {
    this.id = id;
    this.name = name;
    this.lastName = lastName;
    this.lastName2 = lastName2;
    this.role = role;
    this.isLogged = isLogged;
    this.isAdmin = isAdmin;
    this.language = language;
  }
}

class ChartData {
  constructor({ labels = [], data = [] } = {}) {
    this.labels = labels;
    this.data = data;
  }
}

class DashboardInfo {
  constructor({
                generalData = new GeneralData(),
                periodData = new PeriodData()
  } = {}) {
    this.generalData = generalData;
    this.periodData = periodData;
  }
}

class GeneralData {
  constructor({
                totalRevenue = 0,
                allOpportunitiesSummary = new OpportunitiesSummary(),
                inPlanPaidOpportunitiesSummary = new OpportunitiesSummary(),
                individuallyPaidOpportunitiesSummary = new OpportunitiesSummary(),
                plansSummary = new PlansSummary(),
                renewingSummary = new RenewingSummary(),
                usersSummary = new UsersSummary()
              } = {}) {
    this.totalRevenue = totalRevenue;
    this.allOpportunitiesSummary = allOpportunitiesSummary;
    this.inPlanPaidOpportunitiesSummary = inPlanPaidOpportunitiesSummary;
    this.individuallyPaidOpportunitiesSummary = individuallyPaidOpportunitiesSummary;
    this.plansSummary = plansSummary;
    this.renewingSummary = renewingSummary;
    this.usersSummary = usersSummary
  }
}

class ChartSeries {
  constructor({
                free = new ChartData(),
                paid = new ChartData()
              } = {}) {
    this.free = free;
    this.paid = paid;
  }
}

class PeriodData {
  constructor({
                totalRevenue = 0,
                opportunitiesSummary = new OpportunitiesSummary(),
                plansSummary = new PlansSummary(),
                renewingSummary = new RenewingSummary(),
                chartSeries = new ChartSeries(),
                usersSummary = new UsersSummary(),
                totalReportedOpportunities = 0
              } = {}) {
    this.totalRevenue = totalRevenue;
    this.opportunitiesSummary = opportunitiesSummary;
    this.plansSummary = plansSummary;
    this.renewingSummary = renewingSummary;
    this.chartSeries = chartSeries;
    this.usersSummary = usersSummary;
    this.totalReportedOpportunities = totalReportedOpportunities;
  }
}

class OpportunitiesSummary {
  constructor({
                totalCreated = 0,
                totalFree = 0,
                totalPaid = 0,
                totalGoldLevel = 0,
                totalSilverLevel = 0,
                totalBronzeLevel = 0,
                immediateApproval = 0
  } = {}) {
    this.totalCreated = totalCreated;
    this.totalFree = totalFree;
    this.totalPaid = totalPaid;
    this.totalGoldLevel = totalGoldLevel;
    this.totalSilverLevel = totalSilverLevel;
    this.totalBronzeLevel = totalBronzeLevel;
    this.immediateApproval = immediateApproval;
  }
}

class PlanSummary {
  constructor({
                totalCreated = 0,
                totalGoldLevel = 0,
                totalSilverLevel = 0,
                totalBronzeLevel = 0
              } = {}) {
    this.totalCreated = totalCreated;
    this.totalGoldLevel = totalGoldLevel;
    this.totalSilverLevel = totalSilverLevel;
    this.totalBronzeLevel = totalBronzeLevel;
  }
}

class PlansSummary {
  constructor({
                paidPlans = new PlanSummary(),
                sponsoredPlans = new PlanSummary()
              } = {}) {
    this.paidPlans = paidPlans;
    this.sponsoredPlans = sponsoredPlans;
  }
}

class RenewingSummary {
  constructor({
                pending = 0,
                expired = 0
              } = {}) {
    this.pending = pending;
    this.expired = expired;
  }
}

class ImpersonalSummary {
  constructor({
                totalRegistered = 0,
                totalConfirmed = 0,
                totalNoConfirmed = 0
              } = {}) {
    this.totalRegistered = totalRegistered;
    this.totalConfirmed = totalConfirmed;
    this.totalNoConfirmed = totalNoConfirmed;
  }
}

class UsersSummary {
  constructor({
                totalCreated = 0,
                organic = 0,
                impersonalSummary = new ImpersonalSummary()
              } = {}) {
    this.totalCreated = totalCreated;
    this.organic = organic;
    this.impersonalSummary = impersonalSummary;
  }
}

class PaginatedItems {
  constructor({
    pageNumber = 1,
    totalItems = 1,
    perPage = 10,
    items = []
  } = {}) {
    this.pageNumber = pageNumber;
    this.totalItems = totalItems;
    this.perPage = perPage;
    this.items = items;
  } plain() {
    return {
      pageNumber: this.pageNumber,
      totalItems: this.totalItems,
      perPage: this.perPage,
      items: this.items
    };
  } clone() {
    return new PaginatedItems(this.plain());
  }
}

class AdvancedSearchFilters {
  constructor({
    pageNumber = "1",
    brand = "",
    model = "",
    style = "",
    yearFrom = null,
    yearTo = null,
    priceFrom = null,
    priceTo = null,
    transmissionType = "",
    fuelType = "",
    motorSizeCCFrom = "",
    motorSizeCCTo = "0",
    passengersNumber = "0",
    exteriorColor = "",
    interiorColor = "",
    mileageMax = "0",
    currency= 'CRC'
  } = {}) {
    this.pageNumber = pageNumber;
    this.brand = brand;
    this.model = model;
    this.style = style;
    this.yearFrom = yearFrom;
    this.yearTo = yearTo;
    this.priceFrom = priceFrom;
    this.priceTo = priceTo;
    this.transmissionType = transmissionType;
    this.fuelType = fuelType;
    this.motorSizeCCFrom = motorSizeCCFrom;
    this.motorSizeCCTo = motorSizeCCTo;
    this.passengersNumber = passengersNumber;
    this.exteriorColor = exteriorColor;
    this.interiorColor = interiorColor;
    this.mileageMax = mileageMax;
    this.currency = currency;
  }
}

class ChartDataset {
  constructor({
    label = "",
    backgroundColor = "",
    borderColor = "",
    data = [],
    type = "line",
    pointRadius = 3,
    fill = false,
    lineTension = 0,
    borderWidth = 3
  } = {}) {
    this.label = label;
    this.backgroundColor = backgroundColor;
    this.borderColor = borderColor;
    this.data = data;
    this.type = type;
    this.pointRadius = pointRadius;
    this.fill = fill;
    this.lineTension = lineTension;
    this.borderWidth = borderWidth;
  }
}

class Credentials {
  constructor({ email = "", currentPassword = "", newPassword = "" } = {}) {
    this.email = email;
    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
  }
}

class LocationsCR {
  constructor({ province = "", canton = "", district = "" } = {}) {
    this.province = province;
    this.canton = canton;
    this.district = district;
  }
}

class Language {
  constructor({ name = "", code = "" } = {}) {
    this.name = name;
    this.code = code;
  }
}

class Plan {
  constructor({ planLevel = "3", totalOpportunities = 0 } = {}) {
    this.planLevel = planLevel;
    this.totalOpportunities = totalOpportunities;
  }
}

class LevelPrices {
  constructor({ goldLevel = 0, silverLevel = 0, bronzeLevel = 0  } = {}) {
    this.goldLevel = goldLevel;
    this.silverLevel = silverLevel;
    this.bronzeLevel = bronzeLevel;
  }
}

class Prices {
  constructor(
      { single = new LevelPrices(),
        inPlan = new LevelPrices(),
        quickApproval = 0
      } = {}) {
    this.single = single;
    this.inPlan = inPlan;
    this.quickApproval = quickApproval;
  }
}

class SystemVersions {
  constructor({ backEndVersion = 0, databaseVersion = 0  } = {}) {
    this.backEndVersion = backEndVersion;
    this.databaseVersion = databaseVersion;
  }
}

class PaymentData {
  constructor(
      { redirectUrl = "", isPayed = false

              } = {}) {
        this.redirectUrl = redirectUrl;
        this.isPayed = isPayed;
  }
}

class PaymentResultData {
  constructor(
      { code = "", description = "", auth = "", order = "", tpt = "", crd = "",
        padded = "", authorization = "", brand = "", lastDigits = "",
        gatewayTransaction = "", tilopayTransaction = "", OrderHash = ""
      } = {}) {
    this.code = code;
    this.description = description;
    this.auth = auth;
    this.tpt = tpt;
    this.crd = crd;
    this.padded = padded;
    this.authorization = authorization;
    this.brand = brand;
    this.lastDigits = lastDigits;
    this.gatewayTransaction = gatewayTransaction;
    this.tilopayTransaction = tilopayTransaction;
    this.OrderHash = OrderHash;
  }
}

class UserPlanInfo {
  constructor(
      { planLevel = "", availableOpportunities = 0, expirationDate = ""

      } = {}) {
    this.planLevel = planLevel;
    this.availableOpportunities = availableOpportunities;
    this.expirationDate = expirationDate;
  }
}
class UserPhone {
  constructor(
      { phone = "", phoneType = ""
      } = {}) {
    this.phone = phone;
    this.phoneType = phoneType;
  }
}

class SellerInfo {
  constructor(
      { id = "", fullName = "", phones = [UserPhone]
      } = {}) {
    this.id = id;
    this.fullName = fullName;
    this.phones = phones;
  }
}

class Extra {
  constructor(
      { key = "", label = ""
      } = {}) {
    this.key = key;
    this.label = label;
  }
}

class ExtraCategory {
  constructor(
      { name = "", extras = []
      } = {}) {
    this.name = name;
    this.extras = extras;
  }
}

class MotorSizeRange {
  constructor(
      key = 0, textToDisplay = "", from = 0, to= 0) {
    this.key = key;
    this.textToDisplay = textToDisplay;
    this.from = from;
    this.to = to;
  }
}

export {
  GlobalParameter,
  Opportunity,
  User,
  UserInfo,
  OpportunitiesSummary,
  PlansSummary,
  ImpersonalSummary,
  UsersSummary,
  RenewingSummary,
  DashboardInfo,
  PaginatedItems,
  AdvancedSearchFilters,
  ChartData,
  ChartDataset,
  Credentials,
  LocationsCR,
  Language,
  Invoice,
  Plan,
  Prices,
  SystemVersions,
  PaymentData,
  PaymentResultData,
  UserPlanInfo,
  UserPhone,
  SellerInfo,
  Extra,
  ExtraCategory,
  MotorSizeRange
};
