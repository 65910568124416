<template>
  <div style="display: none;">
      <datalist id="excolours" style="display: none;">
      <option>{{ $t('opportunity.colors.white') }}</option>
      <option>{{ $t('opportunity.colors.silver') }}</option>
      <option>{{ $t('opportunity.colors.black') }}</option>
      <option>{{ $t('opportunity.colors.grey') }}</option>
      <option>{{ $t('opportunity.colors.blue') }}</option>
      <option>{{ $t('opportunity.colors.red') }}</option>
      <option>{{ $t('opportunity.colors.brown') }}</option>
      <option>{{ $t('opportunity.colors.green') }}</option>
    </datalist>
    <datalist id="incolours" style="display: none;">
      <option>{{ $t('opportunity.colors.black') }}</option>
      <option>{{ $t('opportunity.colors.grey') }}</option>
      <option>{{ $t('opportunity.colors.beige') }}</option>
    </datalist>
    <datalist id="topmodels" style="display: none;">
      <option v-for="model in typeAheadModels" :key="model">{{ model }}</option>
    </datalist>
  </div>
</template>

<script>
import { modelsForMake } from "@/cardata.js"
import { titleCase } from "@/helper.js"

export default {
  name: 'DataList',
  props: {
    brand: {
      type: String,
      default: ''
    }
  },
  computed: {
    typeAheadModels() {
      return modelsForMake(titleCase(this.brand))
    }
  }
}
</script>