export default {
  setUserInfo (state, userInfo) {
    state.userInfo = userInfo
  },

  setLocale (state, locale) {
    state.locale = locale
  },

  clearUserInfo (state) {
    state.userInfo = null
  }
}