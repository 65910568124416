<template>
  <div class="container">
    <div class="white-box">
      <div class="row">
        <div class="col-xs-12">
          <h4>
            {{ $t("footer.contact") }}
          </h4>
          <div><strong>{{ $t('footer.emailLabel') }}: </strong> {{ $t('footer.contactEmail') }}</div>
          <div><strong>{{ $t('footer.phoneLabel') }}: </strong> {{ $t('footer.contactPhone') }}</div>
          <div><strong>{{ $t('footer.whatsappLabel') }}: </strong> {{ $t('footer.contactPhone') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUsView",
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
