<template>
    <div class="input-group justify-content-center">
      <input
          :type="showText ? 'input' : 'password'"
          :id="inputId"
          class="ccmc_input form-control"
          :autocomplete="autocomplete"
          :value="value"
          @input="emitValue($event)"
          @change="emitChange($event)"
          :placeholder="placeholder"
      >
      <span class="ccmc_input" @click="showText = !showText" >
        <i v-if="!showText" class="bi bi-eye"></i>
        <i v-else class="bi bi-eye-slash"></i>
      </span>
    </div>
</template>

<script>

export default {
  name: "PasswordInput",
  props: {
    inputId: {
      type: String,
      default: ""
    },
    autocomplete: {
      type: String,
      default: "off"
    },
    value: {
      type: String,
      default: ""
    },
    placeholder : {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showText: false
    };
  },
  methods: {
    emitValue(event) {
      this.$emit('input', event.target.value);
    },
    emitChange(event) {
      this.$emit('change', event.target.value);
    }
  }
};
</script>
