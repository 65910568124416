<template>
  <div>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col col-md-8">
          <div id="ccmc_advancedSearch_expand_contract"
               class="search_main_bar mb-2"
               @click.prevent="switchDisplaySearchForm()"
               :class="[!displaySearchForm ? 'search_main_bar--pink' : 'search_main_bar--gray']">
            <i v-if="displaySearchForm" class="bi bi-chevron-up mt-2 me-1"></i>
            <i v-else class="bi bi-chevron-down mb-2 me-1"></i>
            <span> <a href="#">{{ $t("search.advancedSearch") }}
            </a> </span>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col col-md-8" v-if="this.displaySearchForm">
          <div class="white-box">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-sm-6 text-center mb-2">
                  <button id="ccmc_advancedSearch_primarySearch" type="button"
                          class="btn btn-primary btn-block search-button primary-search-button"
                          @click="filterResults()">
                    {{ $t("commons.search") }}
                  </button>
                </div>
              </div>
              <div class="row justify-content-center mt-2 mt-md-0">
                <div class="col-12 col-md-auto text-center">
                  <button type="button" class="btn btn-sm btn-secondary text-white me-md-2"
                          @click="switchDisplayMoreFilters()">
                    {{ displayMoreOrLest() }} {{ $t("search.filters") }}
                  </button>
                  <button id="ccmc_advancedSearch_clean" type="button" class="btn btn-sm btn-primary text-white ms-1"
                          @click="resetForm()">
                    {{ $t("commons.clear") }}
                  </button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div>
                  <car-brands
                      v-model="advancedSearchFilters.brand"
                      mode="search"
                      :key="brandsComponentKey"
                  ></car-brands>
                </div>
              </div>
              <div class="col-6">
                <input class="ccmc_input form-control"
                       id="model"
                       type="text"
                       v-model="advancedSearchFilters.model"
                       list="topmodels"
                       maxlength="60"
                       :placeholder="$t('opportunity.model') + ' ' + $t('commons.any')"
                       :title="$t('opportunity.model')"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <car-styles
                    v-model="advancedSearchFilters.style"
                    mode="search"
                ></car-styles>
              </div>
              <div class="col-6">
                <car-fuels
                    v-model="advancedSearchFilters.fuelType"
                    mode="search"
                ></car-fuels>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <multirange-slider
                    key="pricesSlider"
                    :title="'search.priceRange'"
                    :isCurrencyFormat="true"
                    :priceInitialMinValue="priceInitialMinValue"
                    :priceInitialMaxValue="priceInitialMaxValue"
                    @currency-value="advancedSearchFilters.currency = $event"
                    @min-value="advancedSearchFilters.priceFrom = $event"
                    @max-value="advancedSearchFilters.priceTo = $event">
                </multirange-slider>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <multirange-slider
                    key="yearsSlider"
                    :title="'commons.year'"
                    :isCurrencyFormat="false"
                    :yearInitialMinValue="yearInitialMinValue"
                    :yearInitialMaxValue="yearInitialMaxValue"
                    @min-value="advancedSearchFilters.yearFrom = $event"
                    @max-value="advancedSearchFilters.yearTo = $event">
                </multirange-slider>
              </div>
            </div>
            <div v-show="displayMoreFilters">
              <div class="row mt-1">
                <div class="col-6">
                  <car-transmitions
                      v-model="advancedSearchFilters.transmissionType"
                      mode="search"
                  ></car-transmitions>
                </div>

                <div class="col-6">
                  <select
                      class="ccmc_input form-control"
                      id="mileage"
                      v-model="advancedSearchFilters.mileageMax"
                  >
                    <option value="0">{{ $t("opportunity.mileage") }} {{ $t("commons.any") }}</option>
                    <option value="50000">< 50,000</option>
                    <option value="100000">< 100,000</option>
                    <option value="150000">< 150,000</option>
                    <option value="200000">< 200,000</option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <select
                      class="ccmc_input form-control"
                      id="passengersNumber"
                      v-model="advancedSearchFilters.passengersNumber"
                  >
                    <option value="0">{{ $t("opportunity.passengersNumber") }} {{ $t("commons.any") }}</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5+</option>
                  </select>
                </div>
                <div class="col-6">
                  <select
                      class="ccmc_input form-control"
                      id="motorSizeCC"
                      v-model="selectedMotorSize"
                      @change="evaluateMotorSizes()"
                      :title="$t('opportunity.motorSizeCC')"
                  >
                    <option v-for="range in motorRanges" :value="range.key">{{
                        range.textToDisplay
                      }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <input
                      class="ccmc_input form-control"
                      id="exteriorColor"
                      type="text"
                      v-model="advancedSearchFilters.exteriorColor"
                      list="excolours"
                      :placeholder="$t('opportunity.interiorColor')+' '+$t('commons.any')"
                      maxlength="40"
                  />
                </div>
                <div class="col-6">
                  <input
                      class="ccmc_input form-control"
                      id="interiorColor"
                      type="text"
                      v-model="advancedSearchFilters.interiorColor"
                      list="incolours"
                      :placeholder="$t('opportunity.exteriorColor')+' '+$t('commons.any')"
                      maxlength="40"
                  />
                </div>

              </div>

              <div class="row justify-content-center mt-2">
                <div class="col-4">
                  <button id="ccmc_advancedSearch_secondarySearch" type="button"
                          class="btn btn-primary btn-block search-button primary-search-button text-white"
                          @click="filterResults()"
                          style="width: 100%;"> {{ $t("commons.search") }}
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    <datalist id="excolours" style="display: none;">
      <option>{{ $t("opportunity.colors.white") }}</option>
      <option>{{ $t("opportunity.colors.silver") }}</option>
      <option>{{ $t("opportunity.colors.black") }}</option>
      <option>{{ $t("opportunity.colors.grey") }}</option>
      <option>{{ $t("opportunity.colors.blue") }}</option>
      <option>{{ $t("opportunity.colors.red") }}</option>
      <option>{{ $t("opportunity.colors.brown") }}</option>
      <option>{{ $t("opportunity.colors.green") }}</option>
    </datalist>
    <datalist id="incolours" style="display: none;">
      <option>{{ $t("opportunity.colors.black") }}</option>
      <option>{{ $t("opportunity.colors.grey") }}</option>
      <option>{{ $t("opportunity.colors.beige") }}</option>
    </datalist>
    <datalist id="topmodels" style="display: none;">
      <option v-for="model in typeAheadModels" :key="model">{{ model }}</option>
    </datalist>
  </div>
</template>

<script>
import {modelsForMake} from "@/cardata.js";
import {titleCase} from "@/helper.js";
import {AdvancedSearchFilters, MotorSizeRange} from "@/entities.js";
import CarBrands from "@/components/opportunity/CarBrands.vue";
import CarStyles from "@/components/opportunity/CarStyles.vue";
import CarFuels from "@/components/opportunity/CarFuels.vue";
import CarTransmitions from "@/components/opportunity/CarTransmitions.vue";
import MultirangeSlider from "@/components/search/MultirangeSlider.vue";
import {i18n} from "@/main";

function yearOptions() {
  const temp = [];

  for (let y = new Date().getFullYear() + 1; y >= 1990; y -= 1) {
    temp.push(`${y}`);
  }

  temp.push("1900");

  return temp;
}

export default {
  name: "OpportunitySearch",
  components: {
    CarBrands,
    CarStyles,
    CarFuels,
    CarTransmitions,
    MultirangeSlider
  },
  data() {
    return {
      advancedSearchFilters: new AdvancedSearchFilters(),
      yearOptions: yearOptions(),
      brandsComponentKey: 0,
      displaySearchForm: false,
      displayMoreFilters: false,
      selectedMotorSize: 0,
      motorRanges: [
        new MotorSizeRange(0, i18n.t("opportunity.motorSizeCC") + " " + i18n.t("commons.any"), 0, 10000),
        new MotorSizeRange(1, "< 1000 cc", 0, 999),
        new MotorSizeRange(2, "1000 - 2000 cc", 1000, 1999),
        new MotorSizeRange(3, "2000 - 3000 cc", 2000, 2999),
        new MotorSizeRange(4, "3000 - 4000 cc", 3000, 3999),
        new MotorSizeRange(5, "> 4000 cc", 4000, 10000)
      ],
      priceInitialMinValue: null,
      priceInitialMaxValue: null,
      yearInitialMinValue: null,
      yearInitialMaxValue: null
    };
  },
  computed: {
    typeAheadModels() {
      return modelsForMake(titleCase(this.advancedSearchFilters.brand));
    }
  },
  methods: {
    evaluateMotorSizes() {
      for (const range of this.motorRanges) {
        if (range.key === this.selectedMotorSize) {
          this.setMotorSize(range.from, range.to);
          break;
        }
      }
    },
    setMotorSizeFromQuery(from) {
      for (const range of this.motorRanges) {
        if (range.from === from) {
          this.selectedMotorSize = range.key;
          this.setMotorSize(range.from, range.to);
          break;
        }
      }
    },
    setMotorSize(from, to) {
      this.advancedSearchFilters.motorSizeCCFrom = from;
      this.advancedSearchFilters.motorSizeCCTo = to;
    },
    loadStoredQuery() {
      let existingQuery = localStorage.getItem("query");

      if (existingQuery) {
        existingQuery = JSON.parse(existingQuery);

        this.advancedSearchFilters.brand = existingQuery.brand;
        this.advancedSearchFilters.model = existingQuery.model;
        this.advancedSearchFilters.style = existingQuery.style;
        this.advancedSearchFilters.transmissionType = existingQuery.transmissionType;
        this.advancedSearchFilters.fuelType = existingQuery.fuelType;
        this.advancedSearchFilters.exteriorColor = existingQuery.exteriorColor;

        this.setPrices(existingQuery.priceFrom, existingQuery.priceTo);
        this.setYears(existingQuery.yearFrom, existingQuery.yearTo);

        this.setMotorSizeFromQuery(existingQuery.motorSizeCCFrom);

        return true;
      }

      return false;
    },
    setPrices(priceFrom, priceTo) {
      this.advancedSearchFilters.priceFrom = priceFrom;
      this.advancedSearchFilters.priceTo = priceTo;

      this.priceInitialMinValue = priceFrom;
      this.priceInitialMaxValue = priceTo;
    },
    setYears(yearFrom, yearTo) {
      this.advancedSearchFilters.yearFrom = yearFrom;
      this.advancedSearchFilters.yearTo = yearTo;

      this.yearInitialMinValue = yearFrom;
      this.yearInitialMaxValue = yearTo;
    },
    runInitialQuery() {
      if (this.loadStoredQuery()) {
        this.$emit("query", this.advancedSearchFilters);
      } else {
        this.$emit("query", null);
      }
    },
    switchDisplaySearchForm() {
      this.displaySearchForm = !this.displaySearchForm;

      if(this.displaySearchForm) this.loadStoredQuery();
    },
    switchDisplayMoreFilters() {
      this.displayMoreFilters = !this.displayMoreFilters;
    },
    displayMoreOrLest() {
      return this.displayMoreFilters ? '-' : '+';
    },
    filterResults() {
      localStorage.setItem("query", JSON.stringify(this.advancedSearchFilters));
      this.$emit("query", this.advancedSearchFilters);
      this.switchDisplaySearchForm();
    },
    resetForm() {
      this.advancedSearchFilters = new AdvancedSearchFilters();
      this.brandsComponentKey += 1;
      localStorage.removeItem("query");
      this.filterResults()
    }
  },
  mounted() {
    this.runInitialQuery();
  },
  beforeDestroy() {
    localStorage.removeItem("query");
  }
};
</script>