<template>
  <div :class="{ 'has-error': styleError }">
    <select
      id="style"
      class="ccmc_input form-control"
      :value="value"
      v-model="selectedCarStyle"
      @change="updateStyle()"
      :title="$t('opportunity.style')"
    >
      <option v-if="mode === 'search'" value="">{{ $t('opportunity.style') }} {{ $t("commons.any") }}</option>
      <option v-else value="" disabled>{{ $t('opportunity.style') }}  {{ $t("commons.asterisk") }}</option>
      <option
        v-for="style in styleList"
        :key="style"
        :value="style"
        >{{ $t('carStyles.' + style) }}</option
      >
    </select>
    <span v-if="styleError" class="help-block">{{ $t(styleError) }}</span>
  </div>
</template>

<script>
import { isEmpty } from "@/validations";

export default {
  name: "CarStyles",
  props: {
    value: String,
    attempts: {
      type: Number,
      default: 0
    },
    mode: {
      type: String,
      default: "form" // or 'search'
    }
  },
  data() {
    return {
      styleList: [
        "SEDAN",
        "HATCHBACK",
        "COUPE",
        "TODO_TERRENO_4X2",
        "TODO_TERRENO_4X4",
        "PICK_UP_4X2",
        "PICK_UP_4X4",
        "CONVERTIBLE",
        "VAN",
        "PANEL",
        "CAMION"
      ],
      selectedCarStyle: this.value
    };
  },
  computed: {
    styleError() {
      if (this.attempts > 0) {
        if (isEmpty(this.value)) return "error-messages.invalidStyle";
      }
    }
  },
  methods: {
    updateStyle() {
      this.$emit("input", this.selectedCarStyle);
    }
  }
};
</script>
