<template>
  <input
    class="ccmc_input form-control"
    v-model="stringValue"
    @focusout="processTypedValue"
    @focusin="onFocusIn"
    :placeholder="placeHolderText + ' *'"
    :title="tooltip"
  />
</template>

<script>
function parse(text) {
  const v = parseFloat(text.replace(/[^0-9.]/g, ''));

  if (isNaN(v)) {
    return 0;
  }

  if (v < 0) {
    return 0;
  }

  if (v > 1000000000) {
    return 1000000000;
  }

  return parseFloat(v.toFixed(2));
}

import {priceFormatter} from "@/helper";

export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    currency: {
      type: String,
      default: 'CRC'
    },
    tooltip: {
      type: String,
      default: ''
    },
    placeHolderText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      numericValue: this.value,
      stringValue: ''
    };
  },
  watch: {
    currency(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.buildStringValue();
      }
    }
  },
  methods: {
    processTypedValue(e) {
      const actualValue = e.target.value;
      if(this.isValidStringAmount(actualValue)) {
        this.numericValue = parse(actualValue);
        this.buildStringValue();
      } else {
        this.reset();
      }
      this.$emit("input", this.numericValue);
    },
    buildStringValue(){
      this.stringValue = priceFormatter(this.numericValue, this.currency)
    },
    onFocusIn() {
      if(!this.isValidStringAmount(this.stringValue)) {
        this.reset();
      }
    },
    isValidStringAmount(value) {
      const regex = /^[0-9, $₡.]+$/;
      return regex.test(value);
    },
    reset() {
      this.numericValue = 0;
      this.stringValue = '';
      this.currencySimbol = '';
    }
  },
  mounted() {
    if(!this.isValidStringAmount() || this.numericValue < 1) {
      this.reset();
    }
  }
};
</script>
