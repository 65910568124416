<template>
  <div class="container">
    <template v-if="!isEditing">
      <opportunity-list
        :paginatedItems="localPaginatedItems"
        :getOpportunities="loadPageItems"
        :isEditableList="true"
        :sendToEdit="sendToEdit"
      />
    </template>
    <template v-if="isEditing">
      <opportunity-register :toUpdateOpportunity="toUpdateOpportunity" :isEditing="isEditing"/>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import { ApiService } from "@/rest.js";
import OpportunityList from "@/components/opportunity/OpportunityList.vue";
import { PaginatedItems, Opportunity } from "@/entities.js";
import OpportunityRegister from "@/components/opportunity/OpportunityRegister.vue";

export default {
  name: "UserActiveOpportunitiesView",
  components: {
    OpportunityList,
    OpportunityRegister
  },
  data() {
    return {
      localPaginatedItems: new PaginatedItems(),
      currentPage: 1,
      toUpdateOpportunity: new Opportunity(),
      isEditing: false
    };
  },
  methods: {
    loadPageItems(pageNumber) {
      this.currentPage = pageNumber;
      ApiService.GET(
        "/opportunity/protected/userActiveOpportunities/" +
          this.currentPage +
          "/" +
          this.$route.params.userId,
        (err, apiResponse) => {
          if (!err) this.localPaginatedItems = apiResponse;
        }
      );
    },
    sendToEdit(editingOpportunity) {
      this.isEditing = true;
      this.toUpdateOpportunity = editingOpportunity;
    }
  },
  created() {
    this.loadPageItems(this.currentPage);
  }
};
</script>