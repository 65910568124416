<template>
  <div class="white-box">
    <div class="row mt-4">
      <div class="col h2 text-center">
        <p> <strong>{{ $t(primaryText) }} </strong> </p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col h3 text-center">
        <p>{{ $t(secondaryText) }}</p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col text-center">
        <form role="form">
          <button @click="redirect()" class="btn btn-lg btn-primary" type="submit">{{ $t(buttonText) }}</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CommonResult',
    props: {
      primaryText: {
        type: String,
        default: ''
      },
      secondaryText: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: 'commons.continue'
      },
      redirecPath: {
        type: String,
        default: ''
      }
    },
    methods: {
      redirect() {
        this.$router.push(this.redirecPath)
      }
    },
    mounted() {
      window.scrollTo(0,0);
    }
  }
  </script>
