<template>
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="white-box">
        <div v-if="isActivationInProgress">
          <loading></loading>
        </div>
        <div v-else>
          <br>
          <div class="text-center"><h2>{{ $t('company.ccmc') }}</h2></div>
          <div id="white-box">
            <div class="text-center">
              <p><strong>{{ $t(resultMessage) }}.</strong><br><br>
                {{ $t('registration.goToLink') }}: <a href="https://cualcarromecompro.com/#/signin">{{ $t('company.url') }}</a><br>
              </p>
            </div>
            <br>
          </div>
          <div class="text-center"><small>{{ $t('registration.footerMessage') }}.</small></div>
          <br><br><br>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ApiService } from '@/rest.js'
  import Loading from '@/components/utilities/Loading.vue'

  export default {
    name: 'AccountConfirmed',
    components: {
      Loading
    }, 
    props: {
      registrationLink: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isActivationInProgress : true,
        resultMessage : ''
      }
    },
    methods: {
      doSave() {
        let callback = (err, apiResponse) => {
            if (err) {
              if(err.responseText) {
                this.resultMessage = 'error-codes.errorCode_'+err.responseText;
              } else {
                this.resultMessage = 'error-messages.errorOccurred'
              }
            } else {
            this.resultMessage = 'registration.acountConfirmed';
          }
          this.isActivationInProgress = false;   
        } 

        ApiService.POST('/users/accountConfirm', this.registrationLink, callback);
      },
    },
    mounted() {
      this.doSave();

    }
  }
</script>
