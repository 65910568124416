<template>
  <header class="bg-primary">
    <page-menu></page-menu>
    <autocomplete v-if="displaySimpleSearch" @query="query($event)"></autocomplete>
  </header>
</template>

<script>
import PageMenu from '@/components/PageMenu.vue'
import Autocomplete from '@/components/search/Autocomplete.vue'
import {ccmcRedirect} from "@/helper";

export default {
  name: "CommonHeader",
  components: {PageMenu, Autocomplete},
  data() {
    return {};
  },
  computed: {
    displaySimpleSearch() {
      return this.$route.path !== '/searchingOpportunities';
    }
  },
  methods: {
    query(query) {
      localStorage.setItem("query", JSON.stringify(query));
      ccmcRedirect(this.$router, "/searchingOpportunities");
    }
  }
};
</script>
