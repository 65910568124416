<template>
  <div>
    <div v-show="isRequestInProgress">
      <loading :message="buildLoadingMessage()"/>
    </div>
    <div v-show="!isRequestInProgress && isEverythingLoaded">
      <div class="container">
        <opportunity-register
            :toUpdateOpportunity="opportunity"
            :is-building-opportunity="isBuildingOpportunity"
            @opportunity-data="opportunity = $event"
            @button-text-label="updateTextToDisplayInButton($event)"
            @invoice-data="invoice = $event"
            @actual-section="updateActualSection($event)"
        />
      </div>
      <section class="button-box" v-if="actualSection === 3">
        <button
            id="ccmc_payment_opportunity"
            class="btn btn-lg btn-primary payment__button"
            type="button"
            @click="createOpportunity()"
        >
          <span>{{ textToDisplayInButton }} </span>
        </button>
      </section>
    </div>
  </div>
</template>

<script>
import OpportunityRegister from "@/components/opportunity/OpportunityRegister.vue";
import {ApiService} from "@/rest";
import {Opportunity, PaymentData} from "@/entities";
import Loading from "@/components/utilities/Loading.vue";

export default {
  name: "OpportunityRegisterView",
  components: {
    Loading,
    OpportunityRegister
  },
  data() {
    return {
      opportunity: new Opportunity(),
      invoice: null,
      isRequestInProgress: false,
      isBuildingOpportunity: false,
      textToDisplayInButton: 'commons.payForOpportunity',
      isExpectingToLoadOpp: false,
      isExpectingToLoadInvoice: false,
      actualSection: 1
    };
  },
  computed: {
    isEverythingLoaded() {
      return !this.isExpectingToLoadOpp && !this.isExpectingToLoadInvoice
    }
  },
  methods: {
    updateActualSection(event) {
      this.actualSection = event;
    },
    updateTextToDisplayInButton(event){
      this.textToDisplayInButton = this.$i18n.t(event);
    },
    createOpportunity() {
      if (!this.opportunity || this.invoice === "error") {
        return;
      }

      this.isRequestInProgress = true;

      ApiService.POST(
          "/opportunity/protected/",
          {
            opportunity: this.opportunity,
            invoice: this.invoice
          },
          (err, apiResponse) => {
            if (!err) {
              localStorage.setItem('buildingOpportunityId', apiResponse.opportunityId);

              let paymentData = Object.assign(new PaymentData(), apiResponse)

              if (paymentData.isPayed) {
                window.location.replace(apiResponse.redirectUrl + "");
              } else {
                this.$router.push("/noPayedOpportunityResultView");
              }
            } else {
              this.isRequestInProgress = false;
              window.scrollTo(0, document.body.scrollHeight);
            }
          }
      );
      window.scrollTo(0, 0);
    },
    getOpportunity(buildingOpportunityId) {
      this.isExpectingToLoadOpp = true;
      ApiService.GET(
          "/opportunity/" + buildingOpportunityId,
          (err, apiResponse) => {
            if (!err) {
              this.opportunity = apiResponse;
            }
            this.isExpectingToLoadOpp = false;
          }
      );
    },
    getInvoice(buildingOpportunityId) {
      this.isExpectingToLoadInvoice = true;
      ApiService.GET(
          "/opportunity/protected/invoice/" + buildingOpportunityId,
          (err, apiResponse) => {
            if (!err) {
              this.invoice = apiResponse;
            }
            this.isExpectingToLoadInvoice = false;
          }
      );
    },
    isPayedOpportunity() {
      return this.opportunity !== null && this.invoice !== null && this.invoice.amount !== 0;
    },
    buildLoadingMessage() {
      return this.isPayedOpportunity() ? 'payment.loadingPaymentForm' : 'opportunity.freeRegistrationInProcess'
    }
  },
  mounted() {
    const buildingOpportunityId = localStorage.getItem('buildingOpportunityId');

    if (buildingOpportunityId !== 'null' && buildingOpportunityId != null) {
      this.isBuildingOpportunity = true;
      this.getOpportunity(buildingOpportunityId)
      this.getInvoice(buildingOpportunityId)
    }
  }
};
</script>
