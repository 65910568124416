<template>
  <div class="container">
    <template v-if="isRequestInProgress">
      <loading></loading>
    </template>
    <template v-else>
      <h2>
        {{ $t('user.userRegister') }}
      </h2>
      <div class="white-box">
        <div class="row mb-3 gy-3">
          <div class="col">
            <h3>{{$t('user.sortBy')}}</h3>
          </div>
          <div class="col-auto">
            <button
                type="button"
                @click="impersonalRegister()"
                class="btn btn-primary"
            >
              {{ $t("user.createNewUser") }}
            </button>
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col col-md-6 col-lg-4">
            <select id="searchBy" class="form-control"
                    :value="filterBy" v-model="filterBy">
              <option v-for="sortOption of filterOptionsProxy" :key="sortOption.value" :value="sortOption.value">{{ $t(sortOption.label) }}</option>
            </select>
          </div>
          <div class="col col-md-6 col-lg-4">
            <input type="text" id="sortingValue" class="form-control"
                   v-model="filterCriteria"
                   v-on:keyup.enter="filterUsers()"/>
          </div>
        </div>
        <template v-if="filteredUsers.length > 0">
          <users-list :users="filteredUsers"/>
        </template>
        <template v-else>
          <not-results-found></not-results-found>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
// @ is an alias to /src
import { ApiService } from '@/rest.js'
import UsersList from "@/components/user/UsersList";
import Loading from '@/components/utilities/Loading.vue'
import NotResultsFound from '@/components/common/NotResultsFound.vue'

let filterOptions = [
  {"value": "NAME", "label": "user.name"},
  {"value": "LASTNAME", "label": "user.lastName"},
  {"value": "EMAIL", "label": "commons.email"}
];

export default {
  name: 'AdminUsersMaintenanceView',
  components: {
    UsersList,
    Loading,
    NotResultsFound
  },
  data: function () {
    return {
      users: [],
      filteredUsers: [],
      selectedUserId: null,
      filterBy: 'NAME',
      filterOptionsProxy: filterOptions,
      filterCriteria: '',
      isRequestInProgress: false
    };
  },
  methods: {
    loadUsers() {
      this.isRequestInProgress = true;
      ApiService.GET('/users/protected/all', (err, apiResponse) => {
        this.isRequestInProgress = false;
        if (!err){
          this.users = apiResponse;
          this.filteredUsers = this.users.slice();
        }
      });
    },
    filterUsers() {
      if (this.filterCriteria !== '') {
        this.filterCriteria = this.filterCriteria.toLowerCase();
        let filteredUsers = [];
        switch(this.filterBy) {
          case "NAME":
            filteredUsers = this.users.filter(user => user.name.toLowerCase().includes(this.filterCriteria));
            break;
          case "LASTNAME":
            filteredUsers = this.users.filter(user => user.lastName.toLowerCase().includes(this.filterCriteria));
            break;
          case "EMAIL":
            filteredUsers = this.users.filter(user => user.email.toLowerCase().includes(this.filterCriteria));
            break;
        }

        this.filteredUsers = filteredUsers;

      } else {
        this.filteredUsers = this.users.slice();
      }
    },
    impersonalRegister(){
      this.$router.push('/impersonalRegister')
    }
  },
  created() {
    this.loadUsers();
  }
}
</script>
