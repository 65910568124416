<template>
  <div>
    <div v-for="(line, index) in phoneListTmp" v-bind:key="index">
      <div class="row">
        <div class="col-4" :class="{ 'has-error': !line.valid }">
          <input type="text"
                 class="ccmc_input form-control"
                 :id="'phone' + index" :name="'phone' + index" v-model="line.phone"
                 @blur="validatePhone(index)" @input="checkPhone(index)"
                 :placeholder="$t('commons.phone')"
                 :title=" $t('commons.phone') ">
          <span v-if="!line.valid && attempts > 0" class="help-block">{{ $t('error-messages.invalidPhone') }}</span>
        </div>
        <div class="col-4">
          <select :id="'phoneType' + index"
                  class="ccmc_input form-control"
                  v-model="line.phoneType"
                  :title=" $t('commons.phone') ">
            <option value="" disabled selected>{{ $t('commons.type') }}</option>
            <option value="COMERCIAL">{{ $t('phoneDescription.merchant') }}</option>
            <option value="HOME">{{ $t('phoneDescription.home') }}</option>
            <option value="MOBILE">{{ $t('phoneDescription.mobile') }}</option>
            <option value="WHATSAPP">{{ $t('phoneDescription.whatsapp') }}</option>
          </select>
        </div>
        <div class="col">
          <button type="button"
                  class="btn btn-primary phone_button phone_button--pink"
                  @click="addLine(index)"
                  :disabled="phoneListTmp.length >= limit">
            <em class="bi bi-plus-square"></em>
          </button>&nbsp;
          <button type="button"
                  class="btn btn-primary phone_button"
                  @click="removeLine(index)"
                  :disabled="phoneListTmp.length < 2">
            <em class="bi bi-trash"></em>
          </button>
        </div>
      </div>
    </div>
    <div v-if="requiredError" class="has-error">
      <span class="help-block">{{ $t('error-messages.atLeastOnePhone') }}</span>
    </div>
  </div>
</template>
<script>
import {validPhone} from '@/validations';

export default {
  name: 'Phones',
  props: {
    phoneList: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 5
    },
    required: {
      type: Boolean,
      default: true
    },
    attempts: {
      type: Number,
      default: 0
    }
  },
  data() {
    const list = this.phoneList.map(e => ({
      phone: e.phone,
      phoneType: e.phoneType,
      valid: true
    }));

    return {
      phoneListTmp: list.length ? list : [{
        phone: "",
        phoneType: ""
      }]
    };
  },
  watch: {
    phoneListTmp: {
      handler() {
        this.phoneList.length = 0
        this.phoneListTmp.filter(line => {
          return line.phone && validPhone(line.phone)
        }).forEach(line => this.phoneList.push({
          phone: line.phone,
          phoneType: line.phoneType || 'MOBILE'
        }));

        this.$emit('phone-list', this.phoneList);
      },
      deep: true
    }
  },
  methods: {
    addLine(index) {
      this.phoneListTmp.splice(index + 1, 0, {
        phone: null,
        phoneType: null,
        valid: true
      })
    },
    removeLine(index) {
      this.phoneListTmp.splice(index, 1)
    },
    validatePhone(index) {
      let line = this.phoneListTmp[index]
      line.valid = !line.phone || validPhone(line.phone)
    },
    checkPhone(index) {
      if (!this.phoneListTmp[index].valid) {
        this.validatePhone(index)
      }
    }
  },
  computed: {
    requiredError() {
      if (this.required && this.attempts > 0) {
        let validCount = 0;

        this.phoneListTmp.forEach(line => {
          if (line.valid && line.phone) {
            validCount++;
          }
        });

        if (validCount === 0) return true;
      }

      return false;
    }
  },
  mounted() {
    if (this.phoneListTmp.length < 1) {
      this.addLine()
    }
  }
}
</script>
