import Vue from "vue";
import VueI18n from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { dictionary, getPreferedLocale } from "./i18n";
import Fragment from 'vue-fragment';
import { STORAGE_KEY } from "./store/user/state";
import "./scss/common.scss";
/*import { makeServer } from "./server";

if (process.env.NODE_ENV === "development") {
  makeServer();
}*/

Vue.config.productionTip = false;

Vue.use(Fragment.Plugin);

Vue.use(VueI18n);

export const GlobalEventEmitter = new Vue();

export const i18n = new VueI18n({
  locale: "es",
  fallbackLocale: 'es',
  messages: dictionary
});

Vue.filter("currency", function(value) {
  let num = parseFloat(value);

  if (isNaN(num)) {
    return "--";
  }

  return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

window.app = new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
  mounted() {
    this.$i18n.locale =
      window.localStorage.getItem(STORAGE_KEY + ".locale") ||
      getPreferedLocale();
  }
}).$mount("#app");
