<template>
  <div>
    <div class="table-responsive">
      <table class="table table-striped" aria-hidden="true">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('opportunityStatusHistory.email') }}</th>
            <th scope="col">{{ $t('opportunityStatusHistory.fullName') }}</th>
            <th scope="col">{{ $t('opportunityStatusHistory.status') }}</th>
            <th scope="col">{{ $t('opportunityStatusHistory.statusDate') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(history, index) in statusHistory" :key="statusHistory.opportunityId">
            <th scope="row">{{index + 1}}</th>
            <td>{{history.email}}</td>
            <td>{{history.fullName}}</td>
            <td>{{ $t('opportunityStatus.' + history.status) }}</td>
            <td>{{formattedDate(history.statusDate)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import {formatIso8601ToShorTime} from "@/helper";

export default {
  name: 'OpportunityStatusList',
  props: {
    statusHistory: {
      type: Array,
      default: () => []
    }
  },
  methods: {

    formattedDate(statusDateTime) {
      return formatIso8601ToShorTime(statusDateTime);
    }
  }
}
</script>
