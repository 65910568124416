<template>
  <modal ref="loginModal" title="mainMenu.login" ok-text="commons.send"
      :displayButtons="false"
      @proceed="signin()">
      <user-credentials
        ref="userCredentials"
        :attempts="signinAttempts"
        :isSignin="isSignin"
        @user-credentials="credentials = $event">
      </user-credentials>
      <p v-if="signinMessage" class="text-danger">
        {{ $t(signinMessage) }}
      </p>
      <div>
        {{ $t("registration.forgotPassword") }}?
        <a href="#" id="password-recovery" @click.prevent="resetPassword()">{{
            $t("registration.passwordReset")
          }}</a>
        <hr class="my-4" />
        <div class="row">
          <div class="col text-center">
            <button
                id="signin"
                @click="singin()"
                class="btn btn-lg btn-primary"
                type="submit"
            >
              {{ $t("signin.signin") }}
            </button>
          </div>
        </div>
      </div>
  </modal>
</template>
<script>
  import { ApiService } from '@/rest'
  import { UserInfo } from '@/entities'

  import Loading from '@/components/utilities/Loading.vue'
  import Modal from '@/components/utilities/Modal.vue'
  import UserCredentials from '@/components/user/UserCredentials.vue'

  export default {
    name: 'LoginDialog',
    components: {
      Loading,
      Modal,
      UserCredentials
    },
    data() {
      return {
        credentials: null,
        isRequestProcessing : false,
        isSignin: true,
        signinAttempts: 0,
        signinMessage: '',
      };
    },
    methods: {
      displayLoading(isRequestProcessing){
        this.isRequestProcessing = isRequestProcessing;
      },
      singin () {
        this.signinAttempts++;
        this.signinMessage = '';

        if (this.credentials != null) {
          this.isRequestProcessing = true;

          ApiService.POST('/users/login', { email: this.credentials.email, password: this.credentials.currentPassword }, (err, apiResponse) => {

            this.isRequestProcessing = false;
            if (err) {
              if(err.responseText) {
                this.signinMessage = 'error-codes.errorCode_'+err.responseText;
              } else {
                this.signinMessage = 'error-messages.errorOccurred'
              }
            } else {
                localStorage.setItem("JWT", apiResponse.token);

                let userInfo = new UserInfo(apiResponse)
                userInfo.isLogged = true
                userInfo.isAdmin = userInfo.role == "ADMINISTRATOR"

                this.$store.dispatch('setUserInfo', userInfo)
                this.$i18n.locale = userInfo.language
                this.hideLoginDialog();
            }
          })
        }        
      },
      showLoginDialog() {
        this.$refs.loginModal.show()
      },
      hideLoginDialog() {
        this.$refs.loginModal.close()
      },
      resetPassword() {
        this.$refs.loginModal.close()
        this.$router.push("forgotPassword");
      }
    }
  }
</script>