<template>
  <div>
    <div class="row">
      <div class="col">
        {{ $t('dashboard.purchases.quantity') }}: {{ totalPurchased }}
      </div>
    </div>
    <div class="row">
      <div class="col">
        {{ $t('dashboard.purchases.paidAmount') }}: {{ '₡' }}{{ paidAmount | currency }}
      </div>
    </div>
    </div>
</template>
<script>
import { formatAmount} from "@/helper";
export default {
  name: "PurchasesTotals",
  props: {
    totalPurchased: {
      type: Number,
      default: 0
    },
    paidAmount: {
      type: Number,
      default: 0
    }
  },
  methods: {
    formattedAmount(amount) {
      return formatAmount(amount);
    }
  }
};
</script>
