<template>
  <div>
    <!--TEMPLATE-START-->
    <h3  class="small-terms-h3" >{{ $t("tc.title") }}</h3>
    <div class="tc-wrapper">
      <p  class="small-terms" >{{ $t("tc.subtitle") }}</p>
      <h3  class="small-terms-h3" >{{ $t("tc.c1.h") }}</h3>
      <p  class="small-terms" v-html="$t('tc.c1.p1')"></p>
      <h3  class="small-terms-h3" >{{ $t("tc.c2.h") }}</h3>
      <p  class="small-terms"  v-html="$t('tc.c2.p1')"></p>
      <h3  class="small-terms-h3" >{{ $t("tc.c3.h") }}</h3>
      <p  class="small-terms"  v-html="$t('tc.c3.p1')"></p>
      <h3  class="small-terms-h3" >{{ $t("tc.c4.h") }}</h3>
      <ol>
        <li  class="small-terms"  v-html="$t('tc.c4.p1')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p2')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p3')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p4')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p5')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p6')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p7')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p8')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p9')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p10')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p11')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p12')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p13')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p14')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p15')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p16')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p17')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p18')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p19')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p20')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p21')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p22')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p23')"></li>
        <li  class="small-terms"  v-html="$t('tc.c4.p24')"></li>
      </ol>
      <h3  class="small-terms-h3" >{{ $t("tc.c5.h") }}</h3>
      <p  class="small-terms"  v-html="$t('tc.c5.p1')"></p>
      <h3  class="small-terms-h3" >{{ $t("tc.c6.h") }}</h3>
      <p  class="small-terms"  v-html="$t('tc.c6.p1')"></p>
      <h3  class="small-terms-h3" >{{ $t("tc.c7.h") }}</h3>
      <p  class="small-terms"  v-html="$t('tc.c7.p1')"></p>
      <p  class="small-terms"  v-html="$t('tc.c7.p2')"></p>
      <h3  class="small-terms-h3" >{{ $t("tc.c8.h") }}</h3>
      <p  class="small-terms"  v-html="$t('tc.c8.p1')"></p>
      <h3  class="small-terms-h3" >{{ $t("tc.c9.h") }}</h3>
      <p  class="small-terms"  v-html="$t('tc.c9.p1')"></p>
      <h3  class="small-terms-h3" >{{ $t("tc.c10.h") }}</h3>
      <p  class="small-terms"  v-html="$t('tc.c10.p1')"></p>
      <p  class="small-terms"  v-html="$t('tc.c10.p2')"></p>
      <p  class="small-terms"  v-html="$t('tc.c10.p3')"></p>
      <h3  class="small-terms-h3" >{{ $t("tc.c11.h") }}</h3>
      <p  class="small-terms"  v-html="$t('tc.c11.p1')"></p>
      <p  class="small-terms"  v-html="$t('tc.c11.p2')"></p>
      <p  class="small-terms"  v-html="$t('tc.c11.p3')"></p>
      <p  class="small-terms"  v-html="$t('tc.c11.p4')"></p>
      <h3  class="small-terms-h3" >{{ $t("tc.c12.h") }}</h3>
      <p  class="small-terms"  v-html="$t('tc.c12.p1')"></p>
      <p  class="small-terms"  v-html="$t('tc.c12.p2')"></p>
      <h3  class="small-terms-h3" >{{ $t("tc.c13.h") }}</h3>
      <p  class="small-terms"  v-html="$t('tc.c13.p1')"></p>
    </div>
    <!--TEMPLATE-END-->
    <div class="form-check mt-3">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="tcc"
        :checked="accepted"
        @click="confirmTC($event)"
      />
      <label class="form-check-label" for="tcc">
        {{ $t("opportunity.step4.termCheckText") }}
        <a
          :href="getUrl('legal/TermsAndConditionsOfService.pdf')"
          target="_blank"
          >{{ $t("opportunity.step4.termCheckLink") }}</a
        >.
      </label>
    </div>
  </div>
</template>
<style>
.small-terms-h3 {
  font-size: 0.80rem;
}
.small-terms {
  font-size: 0.75rem;
}
</style>
<script>
import { buildUrl } from "@/helper";

export default {
  name: "TermsConditions",
  props: {
    value: false
  },
  data() {
    return {
      accepted: this.value
    };
  },
  watch: {
    accepted() {
      this.$emit("input", this.accepted);
    }
  },
  methods: {
    getUrl(resource) {
      return buildUrl(resource);
    },
    confirmTC() {
      this.accepted = !this.accepted;
    }
  }
};
</script>
