<template>
  <div class="text-center">
    <nav :aria-label="$t('pagination.aria-label')">
      <ul class="pagination justify-content-center flex-wrap">
        <li v-if="previousEnabled" class="page-item">
          <a
            href="#"
            :aria-label="$t('pagination.first')"
            @click.prevent="gotoPage(1)"
            class="page-link"
          >
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li v-else class="disabled page-item">
          <span :aria-label="$t('pagination.first')" class="page-link">
            <span aria-hidden="true">&laquo;</span>
          </span>
        </li>

        <li v-if="previousEnabled" class="page-item">
          <a
            href="#"
            :aria-label="$t('pagination.previous')"
            @click.prevent="gotoPage(Math.max(currentPage - numberOfBuckets, 1))"
            class="page-link"
          >
            <span aria-hidden="true">&lsaquo;</span>
          </a>
        </li>
        <li v-else class="disabled page-item">
          <span :aria-label="$t('pagination.previous')" class="page-link">
            <span aria-hidden="true">&lsaquo;</span>
          </span>
        </li>

        <template v-for="page in pages">
          <li v-if="page !== currentPage" :key="page" class="page-item">
            <a
              href="#"
              :aria-label="$t('pagination.goto', { page })"
              @click.prevent="gotoPage(page)"
              class="page-link"
              >{{ page }}</a
            >
          </li>
          <li v-else :key="page" class="active page-item">
            <span aria-current="true" class="page-link">{{ page }}</span>
          </li>
        </template>

        <li v-if="nextEnabled" class="page-item">
          <a
            href="#"
            :aria-label="$t('pagination.next')"
            @click.prevent="gotoPage(Math.min(currentPage + numberOfBuckets, lastPage))"
            class="page-link"
          >
            <span aria-hidden="true">&rsaquo;</span>
          </a>
        </li>
        <li v-else class="disabled page-item">
          <span :aria-label="$t('pagination.next')" class="page-link">
            <span aria-hidden="true">&rsaquo;</span>
          </span>
        </li>

        <li v-if="nextEnabled" class="page-item">
          <a
            href="#"
            :aria-label="$t('pagination.last')"
            @click.prevent="gotoPage(lastPage)"
            class="page-link"
          >
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
        <li v-else class="disabled page-item">
          <span :aria-label="$t('pagination.last')" class="page-link">
            <span aria-hidden="true">&raquo;</span>
          </span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    total: {
      type: [Number, String]
    },
    perPage: {
      type: [Number, String],
      default: 10
    },
    pageNumber: {
      type: Number
    }
  },
  data() {
    return {
      currentPage: this.pageNumber
    };
  },
  computed: {
    previousEnabled() {
      return this.currentPage > 1;
    },
    lastPage() {
      return Math.ceil(this.total / this.perPage);
    },
    nextEnabled() {
      return this.currentPage < this.lastPage;
    },
    numberOfBuckets() {
      let maxNumberBuckets = 5;

      try {
        maxNumberBuckets = Math.min(
          21,
          Math.max(
            1,
            (window.innerWidth ||
              document.documentElement.clientWidth ||
              document.body.clientWidth) /
              60 -
              2
          )
        );
      } catch (err) {
        // Default value is used
      }

      return Math.round(maxNumberBuckets);
    },
    pages() {
      const maxPage = Math.max(1, Math.ceil(this.total / this.perPage));
      const list = [this.currentPage];

      for (let i = 1; i < this.numberOfBuckets; i += 1) {
        if (i % 2 == 1) {
          if (list[0] > 1) {
            list.unshift(list[0] - 1);
          } else if (list[list.length - 1] < maxPage) {
            list.push(list[list.length - 1] + 1);
          }
        } else {
          if (list[list.length - 1] < maxPage) {
            list.push(list[list.length - 1] + 1);
          } else if (list[0] > 1) {
            list.unshift(list[0] - 1);
          }
        }
      }

      return list;
    }
  },
  methods: {
    gotoPage(page) {
      this.currentPage = page;
      this.$emit("loadNewPageData", this.currentPage);
    }
  }
};
</script>
