<template>
  <div class="strength">
    <div class="strength-bar">
      <div class="strength-bar-fill" :class="fillClass"></div>
    </div>
    <div class="visually-hidden">
      <span v-if="strength === 4">{{ $t("password.strength.excellent") }}</span>
      <span v-else-if="strength === 3">{{ $t("password.strength.good") }}</span>
      <span v-else-if="strength === 2">{{ $t("password.strength.fair") }}</span>
      <span v-else-if="strength === 1">{{ $t("password.strength.weak") }}</span>
      <span v-else>{{ $t("password.strength.default") }}</span>
    </div>
    <div v-if="errors.length > 0" class="visually-hidden">
      <p>{{ $t("password.errorstitle") }}</p>
      <ul>
        <li v-for="e in errors" :key="e">{{ $t(e) }}</li>
      </ul>
    </div>
    <div v-if="hints.length > 0" class="visually-hidden">
      <p>{{ $t("password.hintstitle") }}</p>
      <ul>
        <li v-for="h in hints" :key="h">{{ $t(h) }}</li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.strength {
  display: inline-block;
  width: 8rem;
  margin-left: 0.5rem;
}
.strength-bar {
  background: #dbdbdb;
  height: 0.5em;
  border-radius: 0.25em;
  overflow: hidden;
}
.strength-bar-fill {
  height: 100%;
  background: currentColor;
  box-shadow: 0 0 2px currentColor;

  &.fill-0 {
    display: none;
  }
  &.fill-1 {
    color: #ff2600;
    width: 25%;
  }
  &.fill-2 {
    color: #ff9900;
    width: 50%;
  }
  &.fill-3 {
    color: #91c100;
    width: 75%;
  }
  &.fill-4 {
    color: #00c106;
    width: 100%;
  }
}
</style>

<script>
export default {
  name: "passwordStrength",
  props: {
    strength: {
      type: Number,
      default: 0
    },
    errors: {
      type: Array,
      default: () => []
    },
    hints: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    fillClass() {
      return `fill-${this.strength}`;
    }
  }
};
</script>
