<template>
  <div class="container">
    <div>
      <h2>
        {{ $t('dashboard.purchases.title') }}
      </h2>
      <div class="white-box">
          <user-purchases :startDate="startDate" :endDate="endDate"></user-purchases>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import UserPurchases from "@/components/administration/opportunity/UserPurchases";

export default {
  name: 'UserPurchasesView',
  components: {
    UserPurchases
  },
  data() {
    return {
      startDate: this.$route.params.startDate,
      endDate: this.$route.params.endDate
    };
  }
}
</script>
