<template>
  <div class="container">
    <common-result 
      :primaryText="'user.registration.success.title'" 
      :secondaryText="'user.registration.success.text'"
      :redirecPath="'/signin'" />
  </div>
</template>

<script>
// @ is an alias to /src
import CommonResult from '@/components/common/CommonResult.vue'

export default {
  name: 'RegistrationResultView',
  components: {
    CommonResult
  },
  mounted() {
    window.scrollTo(0,0);
  }
}
</script>
