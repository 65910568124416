<template>
  <div>
    <div class="row" :class="{ 'has-error': emailError }">
      <div class="col">
        <input
            type="text"
            id="email"
            class="ccmc_input form-control"
            v-model="credentialsProxy.email"
            :disabled="disableEmail"
            maxlength="64"
            :placeholder="$t('commons.email')"
        />
        <span v-if="emailError" class="help-block">{{ $t(emailError, {value: credentialsProxy.email}) }}</span>
      </div>
    </div>
    <div
        v-show="displayCurrentPassword()"
        class="row"
        :class="{ 'has-error': currentPassword }"
    >
      <div class="col">
        <password-input
            v-model="credentialsProxy.currentPassword"
            input-id="currentPassword"
            :autocomplete="'on'"
            :placeholder="$t('registration.currentPassword')"
        >
        </password-input>
        <span v-if="currentPassword" class="help-block">{{
            $t(currentPassword)
          }}</span>
      </div>
    </div>
    <div v-show="displayNewPasswordAndConfirmBoxes()">
      <div class="row" :class="{ 'has-error': newPasswordError }">
        <div class="col">
          <PasswordStrength
              :strength="passwordDetails.strength"
              :errors="passwordDetails.errors"
              :hints="passwordDetails.hints"
          ></PasswordStrength>
          <password-input
              v-model="credentialsProxy.newPassword"
              input-id="newPassword"
              :autocomplete="'on'"
              :placeholder="$t('registration.newPassword')"
          ></password-input>
          <span v-if="newPasswordError" class="help-block">{{
              $t(newPasswordError)
            }}</span>
        </div>
      </div>
      <div class="row justify-content-center" :class="{ 'has-error': newPasswordConfirmationError }">
        <div class="col">
          <password-input
              v-model="newPasswordConfirmation"
              input-id="newPasswordConfirmation"
              :autocomplete="'on'"
              @change="emitUserCredentials()"
              :placeholder="$t('registration.newPasswordConf')"
          ></password-input>
          <span v-if="newPasswordConfirmationError" class="help-block">{{
              $t(newPasswordConfirmationError)
            }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordStrength from "@/components/user/PasswordStrength.vue";
import PasswordInput from "@/components/user/PasswordInput.vue";
import {validEmail, validPasswordLight} from "@/validations.js";
import {Credentials} from "@/entities";

export default {
  name: "UserCredentials",
  components: {
    PasswordStrength,
    PasswordInput
  },
  props: {
    credentials: {
      type: Object,
      default: () => new Credentials()
    },
    attempts: {
      type: Number,
      default: 0
    },
    isRegistering: {
      type: Boolean,
      default: false
    },
    isSignin: {
      type: Boolean,
      default: false
    },
    isUpdatingMyProfile: {
      type: Boolean,
      default: false
    },
    isAdminUpdate: {
      type: Boolean,
      default: false
    },
    disableEmail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      credentialsProxy:
          this.credentials !== undefined ? this.credentials : new Credentials(),
      newPasswordConfirmation: ""
    };
  },
  computed: {
    passwordDetails() {
      return validPasswordLight(this.credentialsProxy.newPassword);
    },
    emailError() {
      if (this.attempts > 0) {
        if (!validEmail(this.credentialsProxy.email))
          return "error-messages.invalidEmail";
      }
    },
    currentPassword() {
      if (this.isSignin && this.attempts > 0) {
        if (validPasswordLight(this.credentialsProxy.currentPassword).strength === 0)
          return "error-messages.invalidPassword";
      }
    },
    newPasswordError() {
      if (this.attempts > 0) {
        if (validPasswordLight(this.credentialsProxy.newPassword).strength === 0)
          return "error-messages.invalidPassword";
        if (
            this.displayCurrentPassword() &&
            this.displayNewPasswordAndConfirmBoxes()
        ) {
          if (
              this.credentialsProxy.currentPassword ==
              this.credentialsProxy.newPassword
          )
            return "error-messages.oldAndNewPasswordMustBeDifferent";
        }
      }
    },
    newPasswordConfirmationError() {
      if (this.attempts > 0) {
        if (this.credentialsProxy.newPassword !== this.newPasswordConfirmation)
          return "error-messages.invalidPasswordConfirm";
      }
    }
  },
  watch: {
    credentialsProxy: {
      deep: true,
      handler() {
        this.emitUserCredentials();
      }
    }
  },
  methods: {
    emitUserCredentials() {
      if (this.validateForm()) {
        this.$emit("user-credentials", this.credentialsProxy);
      } else {
        this.$emit("user-credentials", null);
      }
    },
    validateForm() {
      if (!validEmail(this.credentialsProxy.email)) return false;

      if (this.isSignin) {
        if (validPasswordLight(this.credentialsProxy.currentPassword).strength === 0)
          return false;
      } else {
        if (validPasswordLight(this.credentialsProxy.newPassword).strength === 0)
          return false;
        if (this.credentialsProxy.newPassword !== this.newPasswordConfirmation)
          return false;
        if (
            this.credentialsProxy.currentPassword ==
            this.credentialsProxy.newPassword
        ) {
          return false;
        }
      }

      return true;
    },
    displayCurrentPassword() {
      return (this.isSignin || this.isUpdatingMyProfile) && !this.isAdminUpdate;
    },
    displayNewPasswordAndConfirmBoxes() {
      return this.isRegistering || this.isUpdatingMyProfile;
    }
  }
};
</script>
