<template>
  <div class="container">
    <forgot-password/>
  </div>
</template>

<script>
// @ is an alias to /src
import ForgotPassword from '@/components/user/ForgotPassword.vue'

export default {
  name: 'ForgotPasswordView',
  components: {
    ForgotPassword
  }
}
</script>
