<template>
  <div>
    <div class="container-fluid">
      <div class="plans-section-title mb-4">
        <span>{{ $t('commons.prepaidPlans') }} </span>
      </div>
      <div class="row">
        <div class="col mb-4 text-center">
          <strong class="plan-section-form-title">{{ $t('plan.remarkablePlanSuggestion') }}</strong>
        </div>
      </div>
      <div class="row">
        <div class="col text-center plan-section-discount mb-3">
          <strong>{{ $t('plan.remarkableInstructions') }}</strong>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-8">
          <opportunity-plan
              :disabled="false"
              :target="'plan'"
              :is-collapsed="false"
              :user-plan-info="userPlanInfo"
              :prices="prices"
              @setPlan="handlePlanChange($event)"
          />
        </div>
        <div class="col-12 col-lg-4">
          <div class="miniature-wrapper">
            <opportunity-miniature
                :opportunity="previewOpportunity"
                :isPreview="true"/>
            <div class="step-4__miniature-label">
              {{ $t("opportunity.step4.mniatureLabel") }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">

          <h2>{{ $t("plan.buy.itemsInPlanTitle") }}</h2>
          <section class="white-box">
            <p>{{ $t("plan.buy.itemsInPlan") }}</p>

            <div class="row g-3">
              <div class="col-auto" :class="{ 'has-error': quantityError }">
                <div class="input-group">
                  <label for="quantity" class="visually-hidden">{{ $t("plan.buy.itemsInPlanTitle") }}</label>
                  <input
                      id="quantity"
                      v-model.number="quantity"
                      type="text"
                      class="form-control"
                      aria-label="Recipient's username with two button addons"
                  />
                  <button class="btn btn-outline-primary spinner-btn" type="button" :disabled="quantity >= 100"
                          @click="quantity += 1">
                    +
                  </button>
                  <button class="btn btn-outline-primary spinner-btn" type="button"
                          :disabled="quantity <= minNumberOfOpportunitiesToBuyInPlan" @click="quantity -= 1">
                    -
                  </button>
                </div>
                <span v-if="quantityError" class="help-block">{{ quantityErrorLabel }}</span>
              </div>
              <div class="col-auto pt-2">
                <strong>{{ labelMinimumNumberOfOpportunitiesToBuyInPlan }}</strong>
              </div>
            </div>
          </section>


        </div>
      </div>
      <div class="row">
        <div class="col">
          <opportunity-payment
              :total="total"
              :registerAttempts="registerAttempts"
              @invoice-data="updateInvoice($event)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <terms-conditions v-model="termsCondCheck"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.miniature-wrapper {
  position: sticky;
  top: 1rem;
  margin: auto;
  max-width: 400px;
}

.spinner-btn {
  min-inline-size: 2.5rem;
}
</style>

<script>
import {isEmpty} from "@/validations";
import {mapGetters} from "vuex";

import OpportunityMiniature from "@/components/opportunity/OpportunityMiniature.vue";
import OpportunityPayment from "@/components/opportunity/OpportunityPayment.vue";
import AlertModal from "@/components/utilities/AlertModal.vue";
import TermsConditions from '@/components/legal/TermsConditions.vue';
import {ApiService} from "@/rest";
import Loading from "@/components/utilities/Loading.vue";
import OpportunityPlan from "@/components/opportunity/OpportunityPlan/OpportunityPlan.vue";
import PlanStatus from "@/components/opportunity/PlanStatus.vue";
import {UserPlanInfo, Prices} from "@/entities";

const PLANS = {
  '1': 'FREE',
  '3': 'SILVER',
  '4': 'GOLD'
}

export default {
  name: "BuyPlan",
  components: {
    PlanStatus,
    OpportunityPlan,
    Loading,
    OpportunityMiniature,
    AlertModal,
    OpportunityPayment,
    TermsConditions
  },
  data() {
    return {
      plan: 4,
      totalPerOp: 0,
      quantity: 10,
      total: 0,
      termsCondCheck: false,
      minNumberOfOpportunitiesToBuyInPlan: 10,
      userPlanInfo : new UserPlanInfo(),
      prices : new Prices(),
      target: 'plan'
    };
  },
  props: {
    registerAttempts: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters(["isLogged"]),

    quantityError() {
      return isEmpty(this.quantity) || this.quantity < this.minNumberOfOpportunitiesToBuyInPlan
    },
    quantityErrorLabel() {
      if (isEmpty(this.quantity) || this.quantity < this.minNumberOfOpportunitiesToBuyInPlan)
        return this.$i18n.t("error-messages.invalidQuantity",
            {minimum: this.minNumberOfOpportunitiesToBuyInPlan}
        );
    },
    previewOpportunity() {
      let opp = Object.assign({});
      opp.price = 10000000;
      opp.mileage = 50000;
      opp.preview = true;
      opp.level = this.plan;
      return opp;
    },

    labelMinimumNumberOfOpportunitiesToBuyInPlan() {
      return this.$i18n.t("plan.buy.miniumItemsInPlan",
          {minimum: this.minNumberOfOpportunitiesToBuyInPlan}
      );
    },
  },
  watch: {
    totalPerOp() {
      this.total = this.totalPerOp * this.quantity;
      this.updatePlanData();
    },
    quantity() {
      this.total = this.totalPerOp * this.quantity;
      this.updatePlanData();
    },
    plan() {
      this.total = this.totalPerOp * this.quantity;
      this.updatePlanData();
    }
  },
  methods: {
    handlePlanChange(plan) {
      this.plan = plan;
      this.calculatePrices(PLANS[plan]);
    },
    calculatePrices(temPlan) {

      if (temPlan === 'GOLD') {
        this.totalPerOp = this.target === 'plan' ? this.prices.inPlan.goldLevel : this.prices.single.goldLevel;
      } else if (temPlan === 'SILVER') {
        this.totalPerOp = this.target === 'plan' ? this.prices.inPlan.silverLevel : this.prices.single.silverLevel;
      } else if (temPlan === 'FREE') {
        if (this.tempAutomaticApproval) {
          this.totalPerOp = this.prices.quickApproval;
        }
      }
    },
    isValidForm() {
      return !this.quantityError;
    },
    updatePlanData() {

      if (this.isValidForm()) {

        this.$emit("plan-data", {
          planLevel: this.plan,
          totalOpportunities: this.quantity
        });

      } else {
        this.$emit("plan-data", null);
      }

    },
    updateInvoice(invoice) {
      this.$emit("invoice-data", invoice);
    },
    loadMinimumOpportunitiesToBuyInPlan() {
      ApiService.GET(
          "/opportunity/minNumberOppToBuyInPlan", (err, apiResponse) => {
            if (!err) {
              this.minNumberOfOpportunitiesToBuyInPlan = apiResponse;
            }
          }
      );
    },
    loadPrices() {
      ApiService.GET(
          "/opportunity/plansPrices/",(err, apiResponse) => {
            if (!err) {
              this.prices = apiResponse;
              this.handlePlanChange(this.plan);
            }
          }
      );
    },
    loadPlanStatus() {
      if (this.isLogged) {
        ApiService.GET("/users/protected/planstatus", (err, apiResponse) => {
          if (!err) {
            this.userPlanInfo = apiResponse;
          }
        });
      }
    }
  },
  created() {

  },
  mounted() {
    if (!this.isLogged) {
      localStorage.setItem("afterLogin", "/buyPlan");
      this.$router.push("/signin");
    } else {

      this.loadMinimumOpportunitiesToBuyInPlan();
      this.loadPrices();
      this.loadPlanStatus();

    }
  }
};
</script>