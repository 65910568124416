<template>
  <div class="row justify-content-center">
    <div class="col">
      <section class="plan-box" :class="'plan-box__'+value">
        <div>
          <input
              v-bind:data-bs-toggle="isBuyingPlan ? undefined : 'collapse'"
              v-bind:href="isBuyingPlan ? undefined : '#collapse-plan-' + value"
              v-bind:role="isBuyingPlan ? undefined : 'button'"
              v-bind:aria-expanded="isBuyingPlan"
              v-bind:aria-controls="isBuyingPlan ? undefined : 'collapse-plan-' + value"
              class="form-check-input plan-box__checkbox"
              type="radio"
              :id="'plan-' + value"
              :checked="isChecked"
              :value="value"
              :disabled="disabled"
              @change="radioChange($event)"
          />
          <label :for="'plan-' + value">
            <span class="plan-box__title">{{ label }}</span>
          </label>
          <strong class="plan-box__price">
            {{ priceToDisplay() }}
          </strong>
          <span v-if="value !== 'FREE'">
            {{ buildTextToDisplay() }}</span>
        </div>
        <div :class="displayCollapsed" :id="'collapse-plan-' + value">
          <div>
            <hr class="plan-box__hr">
            <div class="mb-1" v-if="preamble">
              {{ preamble }}
            </div>
            <div v-if="target === 'plan'">
              <strong>{{ $t("plan.buy.features") }}:</strong>
            </div>
            <div>
              <ul class="checked-list">
                <li v-for="bullet in bullets" :key="bullet">{{ bullet }}</li>
              </ul>
            </div>
            <div>
              <slot></slot>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {UserPlanInfo} from "@/entities";

export default {
  name: "PlanOption",
  props: {
    modelValue: {default: ""},
    value: {type: String, default: undefined},
    target: {type: String, default: undefined},
    label: {type: String, default: "", required: true},
    preamble: {type: String, default: "", required: false},
    bullets: {type: Array, default: [], required: false},
    price: {type: Number, default: 0, required: true},
    disabled: {type: Boolean, default: false},
    userPlanInfo: {type: Object, default: () => new UserPlanInfo()},
    isCollapsed: {type: Boolean, default: true}
  },
  model: {
    prop: "modelValue",
    event: "change"
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
    displayCollapsed() {
      return this.isCollapsed
          ? "collapse"
          : "collapse show"
    },
    isBuyingPlan() {
      return this.target === 'plan'
    },
  },
  methods: {
    radioChange($event) {
      this.$emit("change", $event.target.value);
    },
    formattedPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    priceToDisplay() {

      if (this.isBuyingPlan) {
        const price = this.formattedPrice(this.price);
        return this.$i18n.t("plan.buy.price", { price });
      }

      const planInfo = this.userPlanInfo[this.value];
      const isOpportunityAvailable = planInfo && planInfo.availableOpportunities > 0;
      const price = this.formattedPrice(isOpportunityAvailable ? 0 : this.price);
      return this.$i18n.t("plan.price", { price });
    },
    buildTextToDisplay() {
      const planInfo = this.userPlanInfo[this.value];
      return planInfo ? this.availableOpportunitiesText(planInfo.availableOpportunities) : this.availableOpportunitiesText(0);
    },
    availableOpportunitiesText(count) {
      return this.$i18n.t("plan.availableOpportunities", {available: count});
    },
    formattedDate(expirationDate) {
      if (!expirationDate) return "";
      let date = new Date(expirationDate);
      return this.$i18n.t("commons.dateFormats.short", {
        year: date.getFullYear(),
        month: date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1,
        day: date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
      });
    },
    buildExpirationDateMessage(expirationDate) {
      return expirationDate ? `${this.$i18n.t("plan.expirationDate")}: ${this.formattedDate(expirationDate)}` : '';
    }
  }
}
</script>
