import Vue from "vue";
import Router from "vue-router";
import Home from "./views/HomeView.vue";
import Register from "./views/user/RegisterView.vue";
import AdminGlobalParametersView from "./views/administrator/configuration/GlobalParametersView.vue";
import AdminUsersMaintenance from "./views/user/AdminUsersMaintenanceView.vue";
import OpportunityRegisterComp from "./views/opportunity/OpportunityRegisterView.vue";
import OpportunityAdministrationView from "./views/administrator/opportunity/OpportunityAdministrationView.vue";
import MyOpportunitiesView from "./views/opportunity/MyOpportunitiesView.vue";
import MyWishListView from "./views/opportunity/MyWishListView.vue";
import UserActiveOpportunitiesView from "./views/opportunity/UserActiveOpportunitiesView.vue";
import SearchingOpportunitiesView from "./views/opportunity/SearchingOpportunitiesView.vue";
import AccountConfirmed from "./components/user/AccountConfirmed.vue";
import SigninView from "./views/signin/SigninView.vue";
import ForgotPasswordView from "./views/user/ForgotPasswordView.vue";
import ForgotPasswordResultView from "./views/user/ForgotPasswordResultView.vue";
import SetNewPasswordView from "./views/user/SetNewPasswordView.vue";
import SetNewPasswordResultView from "./views/user/SetNewPasswordResultView.vue";
import RegistrationResultView from "./views/user/RegistrationResultView.vue";
import EditMyProfileView from "./views/user/EditMyProfileView.vue";
import EditMyProfileResultView from "./views/user/EditMyProfileResultView.vue";
import AdminUserMaintenanceView from "./views/user/AdminUserEditView.vue";
import AdminUserMaintenanceResultView from "./views/user/AdminUserEditResultView.vue";
import OpportunityRegisterResultView from "./views/opportunity/OpportunityRegisterResultView.vue";
import OpportunityUpdateView from "./views/opportunity/OpportunityUpdateView.vue";
import OpportunityDetailView from "./views/opportunity/OpportunityDetailView.vue";
import OpportunityUpdateResultView from "./views/opportunity/OpportunityUpdateResultView.vue";
import UserOpportunitiesViewAdmin from "./views/administrator/opportunity/UserOpportunitiesViewAdmin.vue";
import BuyPlanView from "./views/opportunity/BuyPlanView.vue";
import BuyPlanResultView from "./views/opportunity/BuyPlanResultView.vue";
import ImpersonalRegisterView from "./views/administrator/user/ImpersonalRegisterView.vue";
import ImpersonalRegistrationResultView from "./views/user/ImpersonalRegistrationResultView.vue";
import ReportedOpportunitiesView from "./views/administrator/opportunity/ReportedOpportunitiesView.vue";
import UserPurchasesView from "./views/administrator/opportunity/UserPurchasesView.vue";
import GlobalParametersEditResultView from "./views/administrator/configuration/GlobalParametersEditResultView.vue";
import OpportunityStatusHistoryView from "@/views/administrator/opportunity/OpportunityStatusHistoryView";
import UserInvoicesView from "@/views/administrator/opportunity/UserInvoicesView";
import BuyingUsedCardTips from "@/views/content/BuyingUsedCardTips.vue";
import SellingUsedCardTips from "@/views/content/SellingUsedCardTips.vue";
import PaymentResultView from "@/views/payment/PaymentResultView.vue";
import NoPayedOpportunityResultView from "@/views/payment/NoPayedOpportunityResultView.vue";
import SellerOpportunitiesView from "@/views/opportunity/SellerOpportunitiesView.vue";
import ContactUsView from "@/views/general/ContactUsView.vue";

const DashboardView = () => import(/* webpackChunkName: "DashboardView" */ "./views/administrator/dashboard/DashboardView.vue");

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/register",
      name: "register",
      component: Register
    },
    {
      path: "/registrationResult",
      name: "registrationResult",
      component: RegistrationResultView
    },
    {
      path: "/signin",
      name: "signin",
      component: SigninView
    },
    {
      path: "/forgotPassword",
      name: "forgotPassword",
      component: ForgotPasswordView
    },
    {
      path: "/forgotPasswordResult",
      name: "forgotPasswordResult",
      component: ForgotPasswordResultView
    },
    {
      path: "/passwordReset/:passwordResetToken",
      name: "passwordReset",
      component: SetNewPasswordView,
      props: true
    },
    {
      path: "/setNewPasswordResult",
      name: "setNewPasswordResult",
      component: SetNewPasswordResultView
    },
    {
      path: "/editprofile",
      name: "editprofile",
      component: EditMyProfileView
    },
    {
      path: "/editMyProfileResult",
      name: "editMyProfileResult",
      component: EditMyProfileResultView
    },
    {
      path: "/globalParameters",
      name: "AdminGlobalParametersView",
      component: AdminGlobalParametersView
    },
    {
      path: "/usersMaintenance",
      name: "adminUsersMaintenance",
      component: AdminUsersMaintenance
    },
    {
      path: "/adminUserMaintenance",
      name: "adminUserMaintenance",
      component: AdminUserMaintenanceView,
      props: true
    },
    {
      path: "/adminUserMaintenanceResult",
      name: "adminUserMaintenanceResult",
      component: AdminUserMaintenanceResultView,
      props: true
    },
    {
      path: "/opportunityRegister",
      name: "opportunityRegisterComp",
      component: OpportunityRegisterComp
    },
    {
      path: "/opportunityRegisterResultView",
      name: "opportunityRegisterResultView",
      component: OpportunityRegisterResultView
    },
    {
      path: "/opportunityUpdateView",
      name: "opportunityUpdateView",
      component: OpportunityUpdateView,
      props: true
    },
    {
      path: "/opportunityUpdateResultView",
      name: "OpportunityUpdateResultView",
      component: OpportunityUpdateResultView
    },
    {
      path: "/opportunityAdministration",
      name: "OpportunityAdministrationView",
      component: OpportunityAdministrationView
    },
    {
      path: "/myOpportunities",
      name: "MyOpportunitiesView",
      component: MyOpportunitiesView
    },
    {
      path: "/opportunity/:opportunityId",
      name: "OpportunityDetailView",
      component: OpportunityDetailView,
      props: true
    },
    {
      path: "/myWhishList",
      name: "MyWishListView",
      component: MyWishListView
    },
    {
      path: "/dashboard",
      name: "DashboardView",
      component: DashboardView
    },
    {
      path: "/userActiveOpportunities",
      name: "UserActiveOpportunitiesView",
      component: UserActiveOpportunitiesView
    },
    {
      path: "/searchingOpportunities",
      name: "SearchingOpportunitiesView",
      component: SearchingOpportunitiesView
    },
    {
      path: "/accountConfirmed/:registrationLink",
      name: "AccountConfirmed",
      component: AccountConfirmed,
      props: true
    },
    {
      path: "/userOpportunitiesViewAdmin",
      name: "userOpportunitiesViewAdmin",
      component: UserOpportunitiesViewAdmin,
      props: true
    },
    {
      path: "/buyPlan",
      name: "buyPlan",
      component: BuyPlanView
    },
    {
      path: "/buyPlanResult",
      name: "buyPlanResult",
      component: BuyPlanResultView
    },
    {
      path: "/impersonalRegister",
      name: "impersonalRegister",
      component: ImpersonalRegisterView
    },
    {
      path: "/impersonalRegistrationResult",
      name: "impersonalRegistrationResult",
      component: ImpersonalRegistrationResultView
    },
    {
      path: "/reportedOpportunities",
      name: "reportedOpportunities",
      component: ReportedOpportunitiesView
    },
    {
      path: "/userPurchases/:startDate/:endDate",
      name: "userPurchases",
      component: UserPurchasesView
    },
    {
      path: "/globalParametersEditResult",
      name: "globalParametersEditResult",
      component: GlobalParametersEditResultView
    },
    {
      path: "/opportunityStatusHistory",
      name: "opportunityStatusHistory",
      component: OpportunityStatusHistoryView,
      props: true
    },
    {
      path: "/userInvoices/:userId",
      name: "userInvoices",
      component: UserInvoicesView
    },
    {
      path: "/buyingUsedCardTips",
      name: "buyingUsedCardTips",
      component: BuyingUsedCardTips
    },
    {
      path: "/sellingUsedCardTips",
      name: "sellingUsedCardTips",
      component: SellingUsedCardTips
    },
    {
      path: "/paymentResultView",
      name: "paymentResultView",
      component: PaymentResultView
    },
    {
      path: "/noPayedOpportunityResultView",
      name: "noPayedOpportunityResultView",
      component: NoPayedOpportunityResultView
    },
    {
      path: "/sellerOpportunities/:sellerId",
      name: "sellerOpportunitiesView",
      component: SellerOpportunitiesView,
      props: true
    },
    {
      path: "/contactUs",
      name: "contactUs",
      component: ContactUsView
    }
  ]
});
