<template>
  <div class="row row-cols-1 justify-content-center text-center">
    <div class="col-12">
      <img :src="require(`@/assets/${filename}`)" alt="" width="110px" height="110pm" class="tips_image">
      <div>
        <h2>{{ $t(title) }}</h2>
      </div>
      <div>
        <p>{{ $t(adviceText) }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Advice",
  props: {
    filename: {
      type: String,
      default: ""
    },
    title:{
      type: String,
      default: ''
    },
    adviceText:{
      type: String,
      default: ''
    }
  }
};
</script>
