<template>
  <div class="status-container">
    <div v-if="showCounters">
      <h2>{{ $t("plan.status.availableTitle") }}</h2>
      <section class="white-box">
        <div class="d-flex justify-content-around">
          <section class="gold-box">
            <div class="col">
              <strong>{{ $t("plan.status.goldLabel") }}</strong>
              <br />
              <span class="status-counter">{{ goldPlanInfo.availableOpportunities }}</span>
              <br />
              <span>{{ buildExpirationDateMessage(formattedDate(goldPlanInfo.expirationDate)) }}</span>
            </div>
          </section>
          <section class="silver-box">
            <div class="col">
              <strong>{{ $t("plan.status.silverLevel") }}</strong>
              <br />
              <span class="status-counter">{{ silverPlanInfo.availableOpportunities }}</span>
              <br />
              <span>{{ buildExpirationDateMessage(formattedDate(silverPlanInfo.expirationDate)) }}</span>
            </div>
          </section>
          <section class="bronze-box">
            <div class="col">
              <strong>{{ $t("plan.status.bronzeLevel") }}</strong>
              <br />
              <span class="status-counter">{{ bronzePlanInfo.availableOpportunities }}</span>
              <br />
              <span>{{ buildExpirationDateMessage(formattedDate(bronzePlanInfo.expirationDate)) }}</span>
            </div>
          </section>
        </div>
        <div v-if="mode === 'opp-creation'" class="mt-3">
          <span>{{ $t("plan.status.replenishInfo") }} </span>
          <router-link to="/buyPlan">{{ $t("plan.status.replenishInfoLink") }}</router-link>
        </div>
      </section>
    </div>
    <div v-if="!showCounters && mode == 'opp-creation'" class="alert alert-info" role="alert">
      <h3>{{ $t("plan.status.dealerInfo") }} </h3>
      <router-link to="/buyPlan">{{ $t("plan.status.dealerInfoLink") }}</router-link>
    </div>
  </div>
</template>

<style lang="scss">
.status-container {
  .status-counter {
    font-size: 2rem;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { ApiService } from "@/rest";
import { UserPlanInfo } from "@/entities.js";

export default {
  name: "PlanStatus",
  props: {
    mode: {
      type: String,
      default: 'plan-admin' // or 'opp-creation'
    }
  },
  data() {
    return {
      userPlansInfo: {},
      goldPlanInfo: new UserPlanInfo(),
      silverPlanInfo: new UserPlanInfo(),
      bronzePlanInfo: new UserPlanInfo()
    };
  },
  computed: {
    ...mapGetters(["isLogged"]),

    showCounters(){
      return Object.keys(this.userPlansInfo).length > 0
    }
  },
  methods : {
    formattedDate(expirationDate) {

      if(expirationDate == "") return "";

      let date = new Date(expirationDate);

      return this.$i18n.t("commons.dateFormats.short",
          { year : date.getFullYear(),
            month : (date.getMonth()+1+"").padStart(2,'0'),
            day : (date.getDate()+"").padStart(2,'0')
          }
      );
    },
    buildExpirationDateMessage(expirationDate) {
      if(expirationDate == '') return ''

      return this.$i18n.t("plan.expirationDate") + ": " + expirationDate;
    },
    loadPlanStatus(){

      if (this.isLogged) {
        ApiService.GET("/users/protected/planstatus", (err, apiResponse) => {
          if (!err) {
            this.userPlansInfo = apiResponse;

            if(this.userPlansInfo["GOLD"] != null) {
              this.goldPlanInfo = this.userPlansInfo["GOLD"];
            }
            if(this.userPlansInfo["SILVER"] != null) {
              this.silverPlanInfo = this.userPlansInfo["SILVER"];
            }
            if(this.userPlansInfo["BRONZE"] != null) {
              this.bronzePlanInfo = this.userPlansInfo["BRONZE"];
            }
          }
        });
      }
    }
  },
  mounted() {
    this.loadPlanStatus();
  }
};
</script>
