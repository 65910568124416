<template>
  <div class="container">
    <div class="white-box">
      <h1 class="mt-4 mb-5">{{ $t("content.buyingUsedCardTips.title") }}</h1>
      <section>
        <h2>{{ $t("content.buyingUsedCardTips.tip1.title") }}</h2>
        <p>{{ $t("content.buyingUsedCardTips.tip1.detail") }}</p>
      </section>
      <section>
        <h2>{{ $t("content.buyingUsedCardTips.tip2.title") }}</h2>
        <p>{{ $t("content.buyingUsedCardTips.tip2.detail") }}</p>
      </section>
      <section>
        <h2>{{ $t("content.buyingUsedCardTips.tip3.title") }}</h2>
        <p>{{ $t("content.buyingUsedCardTips.tip3.detail") }}</p>
      </section>
      <section>
        <h2>{{ $t("content.buyingUsedCardTips.tip4.title") }}</h2>
        <p>{{ $t("content.buyingUsedCardTips.tip4.detail") }}</p>
      </section>
      <section>
        <h2>{{ $t("content.buyingUsedCardTips.tip5.title") }}</h2>
        <p>{{ $t("content.buyingUsedCardTips.tip5.detail") }}</p>
      </section>
      <section>
        <h2>{{ $t("content.buyingUsedCardTips.tip6.title") }}</h2>
        <p>{{ $t("content.buyingUsedCardTips.tip6.detail") }}</p>
      </section>
      <section>
        <h2>{{ $t("content.buyingUsedCardTips.tip7.title") }}</h2>
        <p>{{ $t("content.buyingUsedCardTips.tip7.detail") }}</p>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: "BuyingUsedCardTips"
};
</script>