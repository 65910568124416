export default {
  carStyles: {
    COUPE: "Coupe",
    HATCHBACK: "Hatchback",
    PICK_UP_4X2: "PickUp 4x2",
    PICK_UP_4X4: "PickUp 4x4",
    SEDAN: "Sedan",
    TODO_TERRENO_4X2: "All-Terrain 4x2",
    TODO_TERRENO_4X4: "All-Terrain 4x4",
    CONVERTIBLE: "Convertible",
    VAN: "Van",
    PANEL: "Panel",
    CAMION: "Truck"
  },
  commons: {
    accept: "Accept",
    actions: "Actions",
    active: "Active",
    actives: "Actives",
    addImages: "Add Images",
    addPhone: "Add phone",
    addToList: "Add to list",
    addToWishList: "Add to List",
    allUserOpportunities: "All user's opportunities",
    amount: "Amount",
    approved: "Approved",
    asterisk: "*",
    back: "Back",
    cancel: "Cancel",
    checkPlans: 'Buy Remarkable Ads',
    clear: "Clear",
    close: "Close",
    conditions: "conditions",
    confirm: "Confirm",
    continue: "Continue",
    create: "Create",
    currency: "Currency",
    currentLanguage: "English",
    dateCreated: "Date created",
    details: "Details",
    edit: "Edit",
    email: "Email",
    facebook: "Facebook",
    from: "From",
    fullName: "Full name",
    goldOpportunities: "Gold level opportunity",
    id: "Id",
    inactive: "Inactive",
    list: "List",
    loading: "Loading...",
    loading_user: "Loading User Profile...",
    loginInformation: "Login information",
    message: "Message",
    miniatures: "Miniatures",
    new: "New",
    next:'Next',
    no: "No",
    noResultsFound: "Sorry, not results found!.",
    ok: "Ok",
    option: "Option",
    other: "Other",
    phone: "Phone",
    plans: "Plans",
    prepaidPlans: "Prepaid Plan",
    processing: "Processing",
    payForOpportunity: "Pay Opportunity",
    removeFromList: "Remove",
    request: "Request",
    requiredField: "Required field",
    save: "Save",
    search: "Search",
    send: "Send",
    share: "Share",
    status: "Status",
    statusSaved: "Status Saved",
    step: "Step",
    thanks: "Thanks",
    to: "To",
    total: "Total",
    type: "Type",
    update: "Update",
    version: "Version",
    view: "View",
    viewMore: "See more",
    viewDetail: "View Detail",
    whatsapp: "WhatsApp",
    year: "Year",
    yes: "Yes",
    any: "Any",
    dateFormats: {
      short : "{month}-{day}-{year}",
      shortTime : "{day}-{month}-{year} {hour}:{minutes}:{seconds}"
    },
    generalStatus :{
      ACTIVE: "Active",
      NOT_CONFIRMED:"Not Confirmed",
      BLOCKED_BY_FAILS: "Blocked (attempts)"
    },
    viewOpportunity: "Ver Anuncio"
  },
  company: {
    ccmc: "CualCarroMeCompro",
    url: "cualcarromecompro.com",
    luzmax: "Luzmax Soluciones S.A.",
    luzmaxJuridicalPersonId: "3-101-467489",
    luzmaxJuridicalPersonIdLabel: "Cédula Jurídica"
  },
  content : {
    buyingUsedCardTips: {
      titlePart1: "Tips when",
      titlePart2: "buying",
      titlePart3: "your car",
      tip1: {
        title: "Test drive",
        detail: "Try to do a test drive on both slow and fast roads (on the freeway, for example). This will give you a better idea of the vehicle's performance - engine, gearbox, steering, brakes, suspension - at low and high speeds. During the test, be on the lookout for any unusual noises and/or behavior."
      },
      tip2: {
        title: "Appearance",
        detail: "There are two factors that also play an important role in the price of a used car: the aesthetic condition of the body and the cabin. Be sure to carefully check the interior of the car and any repairs to the exterior. If you find minor defects or defects that can be repaired, this should not prevent you from buying the car, remember that on the road the vehicle is exposed to wear and tear. Of course, evaluate the defect well and negotiate with the seller."
      },
      tip3: {
        title: "Leaks",
        detail: "It is also important to visually examine the engine. Dirty and rusty parts can be strong evidence of problems down the road. Any fluid leakage in an automobile is a sign that repairs are needed. Therefore, it is important to thoroughly inspect the engine from the top and bottom. A black fluid may indicate an oil leak, a green fluid may indicate an antifreeze leak and a pink fluid may indicate a transmission leak."
      },
      tip4: {
        title: "Investigate the price",
        detail: "To find out if the seller's asking price is fair, it is also necessary to check the Internet for prices. While condition and mileage will affect the price, you can get a good estimate of the asking price by searching for similar vehicles. Do a search for similar vehicles on CualCarroMeCompro.com and compare prices."
      },
      tip5: {
        title: "Professional Inspection",
        detail: "Have the vehicle checked by your trusted repair shop. As they are professionals, it is easier for them to discover problems."
      },
      tip6: {
        title: "Do not make a hasty decision",
        detail: "To avoid regrets, it is vital to never make a quick decision to buy a vehicle. Take the time to do a thorough research and negotiate to get the best price."
      },
      tip7: {
        title: "Check VIN",
        detail: "Checking the VIN of the vehicle on the Internet allows you to know if it is legally registered or if it is not a product of cloning, a common type of fraud in the sale of used vehicles."
      }
    },
    sellingUsedCardTips: {
      titlePart1: "Tips when",
      titlePart2: "selling",
      titlePart3: "your car",
      tip1: {
        title: "Good photographs",
        detail: "Take the best pictures you can. An ad without pictures does not attract attention or generate distrust in potential buyers. Besides, for most buyers, pictures are relevant when making their decision. The more pictures, the better! Include as many pictures as you can, both of the exterior and interior of the vehicle."
      },
      tip2: {
        title: "Set a reasonable price",
        detail: "Not too high and not too low. Before setting the price look for similar vehicles and compare with the price you want to set. Keep in mind that the value of the vehicle decreases with age and use (mileage)."
      },
      tip3: {
        title: "Be prepared to talk to the buyer",
        detail: "Think about the questions you might be asked about the vehicle and whether you have answers to all of them. Be honest and tell them what you know about your vehicle to give them confidence and transparency."
      },
      tip4: {
        title: "Appearance counts",
        detail: "The cleanliness of your car and its parts reflects the overall care it has been given. So give it a thorough cleaning when you put it up for sale, it will add up to a lot of points."
      }
    }
  },
  currency: {
    crc: "CRC",
    usd: "USD"
  },
  dashboard: {
    opportunitiesInPeriodChart: "Created opportunities in period",
    calendarUpdated: "Calendar updated",
    diagram: {
      freeOpportunities: "Free Opportunities",
      leftLabel: "Total Opportunities",
      payedOpportunities: "Payed Opportunities"
    },
    commons: {
      title: "Statistics Overview",
      revenue: "Total Revenue",
      opportunities: {
        onlineTitle: "Online Opportunities",
        paidWithPlanTitle: "Paid with Plan",
        paidIndividuallyPlanTitle: "Individually paid",
        created: "Valid Created",
        free: "Free",
        paid: "Paid",
        gold: "Gold",
        silver: "Silver",
        bronze: "Bronze",
        invalid: "Invalid",
        immediateApproval: "Immediate Approval "
      },
      plans: {
        title: "Purchased Plans",
        total: "Total",
        sponsoredPlans: "Sponsored Plans"
      },
      renewals: {
        title: "Renewals",
        pending: "Pending",
        expired: "Expired"
      },
      reported: {
        title: "Reported",
        subTitle: "Total"
      },
      users : {
        total: "Total",
        organic: "Organics",
        impersonalized: {
          total: "Impersonalized",
          confirmed: "Confirmed",
          notConfirmed: "Not Confirmed",
        }
      }
    },
    period: {
      title: "Statistics for Period",
      revenue: "Period Revenue"
    },
    donutChart: "Donut chart",
    justNow: "just now",
    newOrders: "New orders",
    opportunityId: "# Opportunity",
    otherService: "Other service",
    readAllNewMessages: "Read all new messages",
    recentCreatedOpportunities: "Just created Opportunities",
    resolve: "Resolve",
    resolved: "Resolved",
    statisticsOverview: "Statistics overview",
    supportTickets: "Support tickets",
    tasksPanel: "Tasks panel",
    totalCreatedOpportunities: "Total Created Opportunities",
    totalFreeOpportunities: "Total Free Opportunities",
    totalPayedOpportunities: "Total Payed Opportunities",
    totalRevenueInPeriod: "Period Revenue",
    totalRevenue: "Total Revenue",
    numberOfPendingOpportunitiesToRenew: "Total pending Opportunities to Renew",
    numberOfPendingOpportunitiesToExpire: "Total pending Opportunities to Expire",
    purchases: {
      opportunities:"Opportunities",
      plans:"Plans",
      title: "Users's purchases",
      fullName: "User",
      goldLevel: "Gold Level",
      silverLevel: "Silver Level",
      bronzeLevel: "Bronze Level",
      freeLevel: "Free",
      userData: "User",
      seeList: "Check list",
      quantity: "Qt",
      paidAmount: "Paid",
      userOpportunities: "Opportunities",
      totalPaid: "Total Paid",
      publishedQuantity: "Published",
      purchasedQuantity: "Purchased",
      invoices: "Invoices"
    }
  },
  domain: {
    baseUrl: "https://cualcarromecompro.com/#/"
  },
  "error-codes": {
    errorCode_0001: "Invalid email or password",
    errorCode_0002: "Your account is not verified",
    errorCode_0004: "Invalid confirmation link",
    errorCode_0005: "Account blocked by failed attempts. Try again after 30 minutes or change your password"
  },
  "error-messages": {
    atLeastOnePhone: "Please add at least one phone number",
    errorOccurred: "An error occurred, please try again",
    invalidAddress: "Please enter a valid address",
    invalidBrand: "The brand is invalid. Select an option",
    invalidCarIdNumber: "The car id number: {value}is invalid. Car id number must be alphabetic or numeric characters only",
    invalidCurrency: "The currency is invalid. Select an option",
    invalidPassengersNumber: "The number of passengers is invalid. Select an option",
    invalidEmail: "The email {value} is invalid. Please type a valid email",
    invalidEmailVerified: "If your email is already verified you will receive and email for password reset. Thanks",
    invalidExteriorColor: "The exterior color {value} is invalid. Only letters, numbers, spaces and, hyphen are allowed",
    invalidFuelType: "The fuel type is invalid. Select an option",
    invalidIdentification: "The identification {value} is invalid. Identification must be alphabetic or numeric characters only",
    invalidIdType: "The identification type is invalid. Select an option",
    invalidInteriorColor: "The interior color {value} is invalid. Only letters, numbers, spaces and, hyphen are allowed",
    invalidKnownDamage: '{value} chars maximum',
    invalidLastName2User: "The second last name {value} is invalid. Second last name must be alphabetic characters only with 2 minimum long",
    invalidLastNameUser: "The last name {value} is invalid. Last name  must be alphabetic characters only with 2 minimum long",
    invalidMileage: "The mileage: {value} is invalid. Mileage must be numeric characters only",
    invalidModel: "The model {value} is invalid. Only letters, numbers, spaces and, hyphen are allowed",
    invalidMotorSizeCC: "The motorSizeCC: {value} is invalid. MotorSizeCC must be alphabetic characters only with 3 minimum long",
    invalidName: "The name {value} is invalid. Name must be minimum 2 characters length",
    invalidNameExtra: "The name {name} is not valid. Name must has 2 minimum long",
    invalidNameUser: "The name {value} is invalid. Name must be alphabetic characters only with 2 minimum long",
    invalidMerchantNameUser: "The merchant name {value} is invalid. Name must be alphabetic characters only with 2 minimum long",
    invalidPassword: "The password length must be from 6 to 20 characters with no blank spaces",
    invalidPasswordConfirm: "Passwords did not match.",
    invalidPayTaxes: "The pay taxes is invalid. Select an option",
    invalidPhone: "Phone must be numeric characters only (min 8)",
    invalidPrice: "The price: {value} is invalid. Price must be greater than 0",
    invalidProvince: "The province is invalid. Select an option",
    invalidTransferPrice: "The transfer price: {value} is invalid.",
    invalidQuantity: "Advertisement's quantity must be higher than 10",
    invalidSellerType: "The seller type is invalid. Select an option",
    invalidStatus: "The status is invalid. Select an option",
    invalidStatusExtra: "The status is invalid. Select an option",
    invalidStyle: "The style is invalid. Select an option",
    invalidTransmissionType: "The transmission type is invalid. Select an option",
    invalidUserRol: "The user rol is invalid. Select an option",
    invalidUserType: "The user type is invalid. Select an option",
    invalidYear: "The year is invalid. Select an option",
    PasswordTooShort: "Password is empty or too short, please check",
    oldAndNewPasswordMustBeDifferent: "The current password and the new password must be different",
    invalidLanguage: "The language is invalid. Select an option",
    required: "Required",
    invalidNumber: "The value must be a number",
    opportunityReviewAlert: "Please review the errors above in order to continue",
    invalidTermsAndConditions : 'Please accept the terms and conditions'
  },
  extra: {
    extras: "Extras"
  },
  footer: {
    contact: "Contact Info",
    contactEmail: "soporte@cualcarromecompro.com",
    contactPhone: "8738-7020",
    emailLabel: "Email",
    phoneLabel: "Phone",
    privacyPolicy: "Privacy Policy",
    whatsappLabel: "WhatsApp",
    copyright: "CualCarroMeCompro.com All rights reserved.",
    version : {
      backend : "BE",
      frontend : "FE",
      database :"DB"
    }
  },
  fuelTypes: {
    diesel: "Diesel",
    electric: "Electric",
    gasoline: "Gasoline",
    hybrid: "Hybrid",
    lpg: "Gas"
  },
  fupload: {
    again: "Try again. Check that you are loading an image file with extension .jpg or .png",
    error: "Uploaded failed",
    message: "Drag and drop your files here,",
    more: "Upload more",
    submessage: "or click to browse",
    maxAttach: "(up to {maxAttachments} files)",
    success: "Uploaded {fileCount} file(s) successfully.",
    uploading: "Uploading {fileCount} files..."
  },
  globalParameters: {
    globalParameters: "Global Parameters",
    TOTAL_ITEMS_TO_DISPLAY_ON_LISTS: "Quantity of items to display on Lists",
    USER_MAX_QUANTITY_FAVOURITES: "Maximum quantity of allowed items in My favourites per user",
    USER_SESSION_DURATION: "User session time (in minutes)",
    PAYED_OPPORTUNITY_EXPIRATION_DAYS: "Time in days for a PAYED opportunity to expire",
    FREE_OPPORTUNITY_EXPIRATION_DAYS: "Time in days for a FREE opportunity to expire",
    NUMBER_OF_DAYS_BEFORE_EXPIRATION_TO_NOTIFY: "Number of days to send the notification before the opportunity expires",
    NUMBER_OF_GOLD_OPPORTUNITIES_IN_CAROUSEL: "Number of gold opportunities to display in Homepage's carousel",
    SINGLE_GOLD_LEVEL_OPPORTUNITY_PRICE: "Single Gold level opportunities price",
    SINGLE_SILVER_LEVEL_OPPORTUNITY_PRICE: "Single Silver level opportunities price",
    SINGLE_BRONZE_LEVEL_OPPORTUNITY_PRICE: "Singo Bronze level opportunities price",
    PLAN_GOLD_LEVEL_OPPORTUNITY_PRICE: "Single Gold level opportunities price in a plan",
    PLAN_SILVER_LEVEL_OPPORTUNITY_PRICE: "Single Silver level opportunities price in a plan",
    PLAN_BRONZE_LEVEL_OPPORTUNITY_PRICE: "Single Bronze level opportunities price in a plan",
    IMMEDIATE_APPROVAL_PRICE:"Immediately approval price",
    MIN_OPPORTUNITIES_TO_BUY_IN_PLAN: "Minimum number of advertisements to buy with a plan",
    successUpdate: "Global Parameters successfully updated"
  },
  identificationTypes: {
    juridicId: "Juridic ID",
    NITE: "NITE",
    foreignId: "Foreign Id",
    none: "None",
    nationalId: "National ID",
    residenceId: "Residence ID",
    title: "Identification Type"
  },
  payment : {
    methods :{
      creditOrDebitCard: "Credit or Debit Card",
      sinpeMovil: "SINPE Móvil"
    },
    result : {
      paymentCompleted : "Payment Completed",
      clarification : "Thanks for your purchase. Your card advertisement will be available soon!",
      backButton : "Back to Home",
      loadingPaymentForm: "Loading payment form"
    }
  },
  home: {
    mainTitle: "Find the vehicle you are looking for",
    mainButton: "See Vehicles",
    goldBox: {
      title: "Highlight your advertisement with at GOLD level",
      text: "Your ad will be displayed in the first search results within CualCarroMeCompro.com (first than Silver level and free ads). It will also be published on our Facebook page.",
      button: "See Plans"
    },
    silverBox: {
      title: "Highlight your advertisement with at GOLD level",
      text: "Your ad will be displayed in a better position than the other (free) ads in the search results of CualCarroMeCompro.com. It will not be published on our Facebook page",
      button: "See Plans"
    }
  },
  invoice: {
    title: "Payment information",
    instructions: "The payment of the advertisement can be made via SINPE Móvil, follow these steps:",
    instruction1: "Check the total amount to pay as shown above.",
    instruction2: "Enter your SINPE Móvil and wire the payment to the following phone number:",
    instruction2_1: "to name",
    instruction3: "Type the provided reference number in the field below.",
    instruction4: "If you have any questions, do not hesitate to contact us.",
    refnum: "SINPE reference number",
    electronic: "I need a electronic invoice",
    invoiceInstructions: "Please complete the following form with the invoice information:",
    idType: "ID Type",
    identification: "ID Number",
    fullname: "Full name",
    address: "Address",
    email: "Email",
    phone: "Phone number",
    amount: "Amount",
    createdDate: "Date",
    status:"Status"
  },
  mainMenu: {
    admin: "Manager",
    advertisement: "Publish Opportunity",
    dashboard: "Dashboard",
    home:"Home",
    login: "Session Expired or Invalid. Please log-in again",
    logout: "Log out",
    myAdvertisements: "My advertisements",
    opportunitiesAdministration: "Management advertisements",
    signInLogin: "Sign in/Login",
    usedVehicles: "Used vehicles",
    bookmarks: "My bookmark",
    plans: "My plan"
  },
  opportunity: {
    addToFavorites: "Add to My Favorites",
    bluetooth: "Bluetooth",
    brand: "Brand",
    camion: "Truck",
    canton: "Canton",
    carIdNumber: "Car id number (Won't be displayed in the advertisement)",
    colors: {
      beige: "beige",
      black: "black",
      blue: "blue",
      brown: "brown",
      green: "green",
      grey: "grey",
      red: "red",
      silver: "silver",
      white: "white"
    },
    contactInfo: "Contact information",
    contactInformation: "Contact information",
    contactName: "Nombre del Contacto",
    contactSeller: "Contact Seller",
    cover: "Cover",
    descriptionCar: "Car Description",
    district: "District",
    exteriorColor: "Exterior color",
    extrasCategory: {
      confort: "Confort",
      segurity: "Segurity",
      audioVideo: "Audio - Video",
      exterior: "Exterior"
    },
    extras: {
      ABS_BRAKES: "ABS brakes",
      ADJUSTABLE_STEERING_WHEEL: "Adjustable steering wheel",
      AIRBAGS: "Airbags",
      AIR_CONDITIONING: "Air conditioning",
      ALARM: "Alarm",
      CENTRAL_LOCKING: "Central locking",
      CRUISE_CONTROL: "Cruise control",
      DASH_SCREEN:"Dash screen",
      DIRECTIONAL_MIRROR:"Directional at mirrors",
      DUAL_GEARBOX: "Dual gearbox",
      DVD_SCREEN: "DVD screen",
      ELECTRIC_GLASSES: "electricGlasses",
      ELECTRIC_MIRRORS: "Electric mirrors",
      ELECTRONIC_STABILITY: "Electronic stability",
      FRONT_SENSORS: "Front sensors",
      HALOGEN: "Halogen",
      HYDRAULIC_STEERING: "Hydraulic steering",
      LEATHER_UPHOLSTERY: "Leather upholstery",
      LEATHER_UPHOLSTERY_CLOTH:"Leather upholstery and cloth",
      LUXURY_RINGS: "Luxury rings",
      LUGGAGE_COVER:"Luggage cover",
      MEMORY_SEAT: "Memory seat",
      MULTIFUNCTION_STEERING: "Multifunction steering wheel",
      POLARIZED_GLASSES: "Polarized glasses",
      POWER_SEATS: "Power seats",
      RACKS:"Racks",
      RADIO_WITH_CASSETTE: "Radio with cassette",
      RADIO_WITH_CD: "Radio with CD",
      RADIO_WITH_USB: "Radio with USB",
      RAIN_SENSOR: "Rain sensor",
      REAR_DEFROSTER: "Rear defroster",
      REVERSE_CAMERA: "Reverse camera",
      REVERSE_SENSORS: "Reverse sensors",
      ROOF_BASKET:"Roof basket",
      ROOF_RAILS:"Roof rails",
      RUBBER_MAT:"Rubber mat",
      SELF_RETRACTING_MIRRORS: "Self retracting mirrors",
      SMART_KEY: "Smart key",
      STEERING_WHEEL_CONTROL: "Steering wheel control",
      STEERING_WHEEL_GEARSHIFT:"Steering wheel gearshift",
      SUNROOF: "Sunroof",
      TIRE_PRESSURE_MONITOR: "Tire pressure monitor",
      TRAVEL_COMPUTER: "Travel computer",
      TURBO: "Turbo",
      XENON_LIGHTS: "Xenon lights"
    },
    fuelType: "Type fuel",
    id: "ID",
    interiorColor: "Interior color",
    knownDamage: "Details",
    level: "Level",
    messages: {
      completeForm: "Please fill out all the required fields"
    },
    mileage: "Milage",
    mileageMax: "Max Mileage",
    mileageUnit: {
      title: "Mileage unit",
      kilometers: "Kilometers",
      miles: "Miles",
      notAvailable:"not available",
      KM: "Km",
      MI: "Mi"
    },
    model: "Model",
    modified: "Modified",
    moreThan7: "more than 7",
    motorSizeCC: "Motor size CC",
    motorSizeCCRange: "Motor Size",
    new: "New",
    passengersNumber: "Number of Passengers",
    outstanding: "Special Offer",
    payTaxes: "Pay taxes",
    price: "Price",
    province: "Province",
    reportAd: "Report this ad",
    reportAdTitle: "Report Ad",
    reportAdDescription: "Please enter a brief description about why you want to report this ad.",
    reportAdLabel: "Reason",
    reportAdReport: "Report",
    reportAdThanks: "Your report was registered, thank you!",
    sellerType: "Seller type",
    sendWhatsApp: "Send WhatsApp",
    sendWhatsAppMessage: "I am interested in the car advertisement I saw in CualCarroMeCompro.com about the ",
    stepOf: "Step {value} of 3",
    step4: {
      emptyExtras: "Selected extras appear here...",
      messageCheckboxText: "Selecting this option allows you to select an additional message from among several recommended text alternatives, or write the personalized message.",
      messageCheckboxTitle: "Custom Message:",
      mniatureLabel: "(preview)",
      newCheckboxText: 'By checking this option the ad will be labeled as "new" during the first 4 days of its publication. Ads tagged as "new " have priority in the recent ad section (higher than ads that are only highlighted)',
      newCheckboxTitle: "New ad tag:",
      optionChange: "Barter",
      optionCustom: "Custom Message",
      optionFullExtras: "Full extras",
      optionNegotiable: "Negotiable",
      optionPerfect: "Perfect state",
      outstandingCheckboxText: "With this option, the ad has priority over regular ads in the search results of users. Featured ads also have priority in other sections of the website, such as the recent ad section. Additionally there are other special options exclusively available for featured ads.",
      outstandingCheckboxTitle: "Outstanding ad:",
      payedByPlan:"Discounted from plan",
      price: "Price:",
      resaltarAnuncio: "Confirmation",
      termCheckLink: "Terms and Conditions of Use",
      termCheckText: "I have read, understand and accept the",
      total: "Total:"
    },
    uploadPhotos:"Upload Photos",
    landscapePhotoAdvice: "To get a better result, we recommend to take the picture with your phone or camera in landscape format.",
    style: "Style",
    sellerOpportunities: "See seller's more opportunities",
    transferPrice: "Transfer cost (aproximate)",
    transmission: "Transmission",
    vehicleExtras: "Vehicle extras",
    views: "Views",
    viewPhones: "View Phones",
    yearModel: "Model Year",
    freeRegistrationInProcess: "Creating your advertisement",
    freeRegistrationCompleted: {
      mainMessage: "Your advertisement was successfully registered",
      clarification : "Thanks, your advertisement will be available online soon",
      backButton : "Back to Home"
    }
  },
  opportunityStatus: {
    APPROVED: "Approved",
    MODIFIED: "Modified",
    PENDING_APPROVAL: "Pending Approval",
    REJECTED: "Rejected",
    SOLD: "Sold",
    EXPIRED: "Expired",
    RENEWED: "Renewed",
    PUBLIC_ONLINE: "Public Online",
  },
  opportunityStatusHistory: {
    id: "Id",
    status: "Status",
    fullName: "Full Name",
    email: "Email",
    statusDate: "Status Date",
    history: "History",
    title: "History for Opportunity"
  },
  messagesTitle: {
    error: "Error",
    info: "Information",
    success: "Success",
    warning: "Warning"
  },
  pagination: {
    "aria-label": "Pagination Navigation",
    current: "(current)",
    first: "Go to first page",
    goto: "Go to Page {page}",
    last: "Go to last page",
    next: "Next",
    previous: "Previous"
  },
  passwordRecovery: {
    title: "Forgot Your Password?",
    mainText: "Enter the email you use to sign into your account.",
    submit: "Submit",
    resetedTitle: "Your password has been contraseña se a reestablished",
    resetedText: "Please Sign'in using you password.",
    resultTitle: "Check Your Email",
    resultText: "If we have an account for the email address you provided, we have emailed a link to reset your password",
    continue: "Continue"
  },
  personTypes: {
    legal: "Legal person",
    natural: "Natural person"
  },
  phoneDescription: {
    home: "Home",
    landline: "Landline",
    merchant: "Merchant",
    mobile: "Mobile",
    whatsapp: "Whatsapp"
  },
  plan: {
    availableOpportunities: 'Prepaid {available} ',
    title: 'Ad level',
    label: 'Plan level',
    expirationDate : 'Expiration',
    preamble1: 'Your sale is important and your ad is important too, demonstrate it by highlighting your ad so that it appears before other advertisers.',
    preamble2: 'Highlighting your ad increases views and builds trust with potential buyers.',
    gold: {
      radio: 'GOLD Level',
      preamble: 'Your vehicle sells faster by appearing at the top of searches. The ad is much more viewed.',
      p1: 'Positioned before silver, bronze and free ads.',
      p2: 'Large size photograph.',
      p3: 'Automatic renewal.',
      p4: 'More photos (up to 20 photos) than silver, bronze and free ads.',
      p5: 'Ad duration: 1 month.',
      p6: 'Automatic approval.',
      msg: 'Personalized message:',
      msgHelp: '{chars} chars maximum',
      name: 'Gold level plan'
    },
    silver: {
      radio: 'SILVER Level',
      p1: 'Ranked before bronze and free ads.',
      p2: 'Normal size photo.',
      p3: 'Automatic renewal.',
      p4: 'More photos (up to 12 photos) than bronze and free ads.',
      p5: 'Ad duration: 1 month.',
      p6: 'Automatic approval.',
      name: 'Silver level plan'
    },
    bronze: {
      radio: 'Highlight Bronze Level',
      p1: 'Positioned before free ads.',
      p2: 'Normal size photo.',
      p3: 'Automatic renewal.',
      p4: 'More photos (up to 8 photos) than free ads.',
      p5: 'Ad duration: 1 month.',
      p6: 'Automatic approval.',
      name: 'Bronze level plan'
    },
    free: {
      radio: 'FREE Level',
      p1: 'Normal size photo.',
      p2: 'Maximum 4 photos.',
      p3: 'Ad duration: 1 month.',
      payToApprove: 'Automatic approval (₡{price} colones)'
    },
    price: 'Price: ₡{price}',
    total: 'Total: ₡{price} colones',
    buy: {
      pageTitle: "Get or reload highlighted advertisement plan",
      sectionDescription1: "Highlight your advertisement with 50% discount when you get a plan!",
      sectionDescription2: "The plans are great for those used or new cars seller retails.",
      plansSectionTitle: "Choice the plan to buy or reload",
      features: "Features",
      itemsInPlanTitle: "Number of ads to buy",
      itemsInPlan: "Select the number of ads for the selected plan you wish to purchase, remember that the ads are valid for one month before expiration.",
      menuLabel: "Buy Prepaid",
      miniumItemsInPlan: "minimum ads 10",
      price: 'Price per ad: ₡{price}',
      process: "Pay",
      gold: {
        radio: 'Gold level plan',
        p7: "Custom messages"
      },
      silver: {
        radio: 'Silver level plan'
      },
      bronze: {
        radio: 'Bronze level plan'
      }
    },
    status: {
      availableTitle: "Available ads per plans",
      goldLabel: "Gold ads",
      silverLevel: "Silver ads",
      bronzeLevel: "Bronze ads",
      expirationDate: "Expiration date",
      dealerInfo: "We offer special plans to car dealerships!",
      dealerInfoLink: "compare plans",
      replenishInfo: "Remember that you can replenish your plan by accessing the",
      replenishInfoLink: "plan administration page"
    },
    remarkable: 'Choose the plan to remark you advertisement',
    remarkableInstructions: 'Check the remarkable level you want your Opportunity. Choose the number of them to buy',
    remarkablePlanSuggestion: 'Save until 80% buying your prepaid advertisements'
  },
  promotions: {
    main: "Buy your advertisement and win ₡100.000",
    termsAndConditions : 'Terms and Conditions'
  },
  impersonalRegistration: {
    title: "Impersonal User registrer",
    giftedOpportunities: "Number of gifted opportunities",
    promotionalPlan: "Datos del plan promocional",
    success: {
      title: "Impersonal Registration completed successfully",
      text:
          "A message has been sent to the provided email address, to complete the registration the user must follow the instructions in the email"
    }
  },
  registration: {
    acountConfirmed: "Account confirmed",
    agreementNotChecked: "To proceed, you must check this field.",
    alreadyRegistered: "Already registered",
    changePassTitle: "Change Password",
    changePassword: "Change my password",
    confirmNewPasword: "Confirm new password",
    createAccount: "Create an account",
    currentPassword: "Password",
    forgotPassword: "Forgot password",
    footerMessage: "A service of cualcarromecompro.com",
    goToLink: "Please go to the next link to login",
    informationCheck:
        "I certify that the information provided is true and accurate.",
    invalidLink: "The link is invalid or it was already used",
    login: {
      logining: "Loggin in"
    },
    newPassword: "Password",
    newPasswordConf: "Confirm Password",
    notRegistered: "Not registered",
    notRequestMaked: "If you did not make this request please contact us",
    passwordConfirm: "Confirm password",
    passwordReset: "Reset password",
    passwordResetText: "Please type your new password and its confirmation.",
    rememberPassword: "Remember your password",
    registerAccount: "Register",
    registingAccount: "Registing account",
    resetPassword: "Reset password",
    submitNewPassword: "Submit new password",
    title: "Create your account",
    phonesDisplayedWithOpportunities: "All registered phones will be displayed in your published cars"
  },
  search: {
    filters: "Filters",
    priceRange: "Price range",
    simpleSearchText: "Type Brand, Model, Year",
    mainLegend: "Find the vehicle you are looking for",
    maximum:"Max",
    minimum:"Min",
    advancedSearch:"Advanced Search",
    backToResults:"Back to Results",
    results: "Search Results"
  },
  sellerTypes: {
    carsSellerMerchant: "Merchant",
    individual: "Individual"
  },
  signin: {
    tittle: "Please Sign-in",
    signin: "Sign in"
  },
  "success-messages": {
    deletedSuccessExtra: "Extra deleted successfully.",
    emailVerified:
        "If your email is already verified you will receive and email for password reset. Thanks",
    registerOpportunity: "Successfully registered opportunity",
    updatePassword: "Your password has been successfully updated",
    updateUser: "You have been successfully update your user",
    updateUserByAdmin: "User´s data successfully updated",
    updatedOpportunity: "You have updated your opportunity successfully",
    boughtPlan: "Your purchase has been processed successfully",
    canceledPayment: "Canceled payment attempt"
  },
  tc: {
    title: 'Terms of Use and Conditions of Service',
    subtitle: 'Last updated: July 1, 2024',
    c1: {
      h: '1. Acceptance of Terms of Use',
      p1: 'Each time you use or access this Web Site, you are agreeing to be bound by the terms described herein and any future changes that may be made with or without notice. Please read these Terms of Use and Conditions of Service carefully before accessing or using our website. If you do not agree to all of the terms and conditions set forth herein, then you should not access the website or use the service.'
    },
    c2: {
      h: 'General Information',
      p1: 'Luzmax Soluciones S.A. is a company incorporated under the Laws of the Republic of Costa Rica, with Legal ID: 3-101-467489 and domiciled in Curridabat, San José, El Prado, which created and owns the website CualCarroMeCompro.com. Hereinafter the term "CualCarroMeCompro.com" or "CualCarroMeCompro" refers to Luzmax Soluciones S.A.'
    },
    c3: {
      h: '3. Services',
      p1: 'CualCarroMeCompro.com is intended to serve as a cloud-based tool that allows you to post car ads. You agree that the owners of this Web Site exclusively reserve the right and may, at any time with or without notice and without any liability to you, modify or discontinue the use of this Web Site and its services or delete the data you provide, temporarily or permanently.'
    },
    c4: {
      h: '4. Terms of Service',
      p1: 'All advertisements will be reviewed and must be approved by CualCarroMeCompro.com before being made public and appearing in the search results of the site.',
      p2: 'You agree that CualCarroMeCompro.com reserves the right and may at any time with or without notice and without any liability to you, modify or discontinue the use of this Web Site and its services.',
      p3: 'CualCarroMeCompro.com shall have no liability to You for the timeliness, cancellation, lack of space, or incorrect posting of information.',
      p4: 'You agree that all information of any kind, provided for the publication of an advertisement, shall be the sole responsibility of you as Advertiser.',
      p5: 'CualCarroMeCompro.com shall not be liable to you, in any way, for the content that is published in the advertisements, nor for any errors or omissions contained therein.',
      p6: 'CualCarroMeCompro.com reserves the right to limit the amount of free ads to any Advertiser.',
      p7: 'You agree that CualCarroMeCompro.com may remove text or images deemed objectionable or offensive.',
      p8: 'You are only allowed to advertise one car per ad.',
      p9: 'Only cars are allowed to be advertised. Vehicles such as motorcycles, quads, etc. are not allowed to be advertised.',
      p10: 'It is not allowed to publish ads with inaccurate or false information. The advertiser agrees to provide real information.',
      p11: 'The permanence time of an ad in CualCarroMeCompro.com will depend on the type of ad level and will be defined by CualCarroMeCompro.com. Currently for paid ads the permanence is one month and a half and for free ads two weeks.',
      p12: 'If the advertised vehicle is sold, the advertiser must remove it from CualCarroMeCompro.com.',
      p13: 'All ads expire. Those with a level other than "Free" will be automatically renewed once. It is the Advertiser\'s responsibility to be aware of the expiration date of the ad.',
      p14: 'The price of the advertised vehicle must be the TOTAL price that the buyer will pay for the vehicle.',
      p15: 'If you enter the value of the vehicle before paying taxes you must indicate it in the ad creation form by checking not checking the "already paid taxes?" option.',
      p16: 'Only photographs of the same vehicle advertised are allowed. All must show some part of the vehicle.',
      p17: 'Photographs must not have other ads inserted, or superimposed, telephone numbers, social networks, or advertisement of the advertiser.',
      p18: 'CualCarroMeCompro.com may remove photographs of ads that seem inadequate, whose resolution is bad or any other condition that in its consideration is sufficient reason to remove it.',
      p19: 'It is not allowed to publish duplicate free ads, that is to say, of the same vehicle. If that happens CualCarroMeCompro.com will delete any of the duplicates.',
      p20: 'It is only allowed to publish ads for the sale of vehicles. It is not allowed to publish ads for the purchase of a vehicle.',
      p21: 'CualCarroMeCompro.com reserves the right to remove ANY ad that it considers to be against its policies or may be detrimental to the image of the site, even if it does not violate any of the other conditions described here.',
      p22: 'Any user who does not comply with the Terms and Conditions described herein may be blocked from posting ads in the future.',
      p23: 'By registering in CualCarroMeCompro.com you agree to receive informative and/or advertising emails from CualCarroMeCompro.com and also to be contacted via telephone. The user can request to unsubscribe at any time if he/she wishes.',
      p24: 'You may request cancellation or update your user account information at any time by sending us an email to the contact address shown below.'
    },
    c5: {
      h: '5. Privacy Policy',
      p1: 'CualCarroMeCompro is concerned about the privacy of the data you provide, so it is committed not to share this information with people outside CualCarroMeCompro.com.'
    },
    c6: {
      h: '6. Consent to use of information',
      p1: 'Creating an account and posting ads on CualCarroMeCompro.com implies that the advertiser\'s information will be publicly available on the website so that potential buyers can contact the advertiser.'
    },
    c7: {
      h: '7. Registration and password',
      p1: 'When you register at CualCarroMeCompro.com you will create a new account linked to an email address and create a password. Each time you log in you must provide both.',
      p2: 'It is the responsibility of the user who registered the account, to manage and protect your password correctly. CualCarroMeCompro.com is not responsible for the negative consequences that you may suffer due to a bad management, voluntary or involuntary, of your password.'
    },
    c8: {
      h: '8. Reservation of rights',
      p1: 'CualCarroMeCompro.com is the sole and legitimate author of all intellectual property rights related to the website and all software platform as developed to offer the service.'
    },
    c9: {
      h: '9. Miscellaneous Information',
      p1: 'The invalidity of any point of this document does not invalidate the others.'
    },
    c10: {
      h: '10. Avoid Scams and Frauds - Considerations',
      p1: 'When calling the advertiser, you are communicating directly with the person who placed the ad, CualCarroMeCompro.com is not responsible for the content of the ad, nor the response of the advertiser.',
      p2: 'If possible, meet the seller in person.',
      p3: 'NEVER transfer money to strangers.'
    },
    c11: {
      h: '11. Plans',
      p1: 'Plans contain prepaid ads of a certain level that the user buys in advance of the publication of their ads. CualCarroMeCompro.com offers a discount on the cost of each ad purchased within a plan (usually 50%) for those who purchase a plan with prepaid ads.',
      p2: 'Prepaid ads within a Plan can be used later by the Advertiser, when he/she is creating a new ad in CualCarroMeCompro.com.',
      p3: 'The ads within the Plan must be used in a prudent time since they have an expiration date of one month. After this time the plan will be blocked and the ads within it cannot be used.',
      p4: 'In case the user buys a new plan (with ads of the same level of a blocked Plan), it will unblock the unused ads in the old blocked plan'
    },
    c12: {
      h: '12. Cancellation and Refund Policy',
      p1: 'Paid ads published in CualCarroMeCompro.com can be cancelled with maximum 1 day after being paid. In these cases CualCarroMeCompro.com will refund the amount paid. Refunds are made to the same credit card with which the ad was paid. To apply the cancellation and coordinate the refund, you must contact CualCarroMeCompro.com (see "Contact Information" section).',
      p2: 'Once the request to cancel an ad is received, it will be reviewed by the CualCarroMeCompro.com team, which will verify that the request was made by the user who published the ad. After this, the refund will be made and the ad will be removed from the web page.'
    },
    c13: {
      h: '13. Contact Information',
      p1: 'Questions about the Terms of Service or any other concerns should be sent to our customer support email: soporte@cualcarromecompro.com, write to us via WhatsApp application at +506 8738-7020 or call us at the same number.'
    }
  },
  transmissionTypes: {
    automatic: "Automatic",
    manual: "Manual",
    tiptronic: "Shiftronic"
  },
  user: {
    contactName: "Contact name",
    contactLastName: "Contact Last Name",
    contactLastName2: "Contact Second Last Name",
    createNewUser: "Create new User",
    dateCreated: "Creation Date",
    idNumber: "ID number",
    idType: "ID type",
    lastName: "Last name",
    language: "Language",
    name: "Name",
    maintenance: {
      ownOpportunities: "See Opportunities",
      userOpportunities: "User opportunities"
    },
    merchant: "Merchant",
    merchantName: "Merchant name",
    passRequirement: "Type your password for security",
    password: "Password",
    person: "Person",
    personalInformation: "Personal information",
    picture: "Picture",
    primaryPhone: "Phone",
    profile: "Profile",
    registration: {
      success: {
        title: "Registration completed successfully",
        text:
            "A message has been sent to the provided email address, to complete you registration please check your email and follow the instructions there"
      }
    },
    roles: {
      ADMINISTRATOR: "Administrator",
      ADVERTISER: "Advertiser",
      TESTUSER: "Test User",
      IMPERSONALIZATOR: "Impersonalizator"
    },
    secondLastName: "Second last name",
    sortBy: "Sort By",
    userId: "User Id",
    userProfile: "User Profile",
    userRegister: "Registered users",
    userRol: "User Rol",
    usersMaintenance: "Users's maintenance",
    userType: "User type"
  },
  password: {
    errorstitle: "Errors:",
    errors: {
      minlen: "The password must be at least 8 characters long",
      maxlen: "Password must not be longer than 64 characters",
      surroundingspaces:
          "Password cannot start or end with spaces",
      invalidchar: "The password contains one or more unsupported characters"
    },
    hintstitle: "Suggestions:",
    hints: {
      validchars:
          "Supported characters are letters (uppercase and lowercase without accents), numbers, spaces, and special symbols: @#$%&!?.,:;()[]{}<>'\"`~^+-*=\\|/_",
      repetition:
          "Avoid repeating character sequences like '111' or 'aaa' as they are easy to guess",
      sequences:
          "Avoid character sequences like 'abc' or '321' as they are easy to guess",
      keystrokes:
          "Avoid consecutive key sequences like 'qwerty' as they are easy to guess",
      common:
          "Avoid common passwords like 'passw0rd' as they are easy to guess",
      context:
          "Avoid using predictable information like your name or phone number as part of the password",
      strength:
          "You can improve the security by adding additional characters and mix different types of symbols"
    },
    strength: {
      excellent: "Congratulations, the password is great!",
      good: "The password is safe",
      fair: "The password is good, but it can be improved",
      weak:
          "Warning: supplied password can be easily guessed",
      default: ""
    }
  }
};