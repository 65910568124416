<template>
  <div>
    <transition name="slide">
      <div v-if="total > -1">
        <h2>{{ $t("invoice.title") }}</h2>
        <section class="white-box">
          <p>
            <strong>{{ $t("opportunity.step4.total")  }}</strong>

            <strong v-if="isPayedByPlan">{{ 0 | currency }}  CRC ({{ $t("opportunity.step4.payedByPlan") }})</strong>
            <strong v-else>{{ total | currency }} CRC</strong>
          </p>

          <div v-if="!isPayedByPlan" class="form-check mt-3">
            <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="ginvoice"
                v-model="generateInvoice"
            />
            <label for="ginvoice" class="form-check-label">{{
                $t("invoice.electronic")
              }}</label>
          </div>
          <div v-if="generateInvoice" class="mt-4">
            <div class="mb-2">
              {{ $t("invoice.invoiceInstructions") }}
            </div>
            <div class="row mb-3 gy-3">
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label" for="iidType">
                    {{ $t("invoice.idType") }}</label
                  >
                  <select
                      class="form-control"
                      id="iidType"
                      v-model="invoice.idType"
                  >
                    <option value="NATIONAL_ID">{{
                        $t("identificationTypes.nationalId")
                      }}</option>
                    <option value="JURIDIC_ID">{{
                        $t("identificationTypes.juridicId")
                      }}</option>
                    <option value="RESIDENCE_ID">{{
                        $t("identificationTypes.residenceId")
                      }}</option>
                    <option value="NITE">{{
                        $t("identificationTypes.NITE")
                      }}</option>
                    <option value="FOREIGN_ID">{{
                        $t("identificationTypes.foreignId")
                      }}</option>
                    <option value="NONE">{{
                        $t("identificationTypes.none")
                      }}</option>
                  </select>
                </div>
              </div>
              <div
                  class="col-md-6 col-lg-4"
                  :class="{ 'has-error': invoiceIdentificationError }"
              >
                <div class="form-group">
                  <label class="form-label" for="iidentification">
                    {{ $t("invoice.identification") }}
                  </label>
                  <input
                      class="form-control"
                      id="iidentification"
                      type="text"
                      v-model="invoice.identification"
                      maxlength="32"
                  />
                  <span v-if="invoiceIdentificationError" class="help-block">{{
                      $t(invoiceIdentificationError, {
                        value: invoice.identification
                      })
                    }}</span>
                </div>
              </div>
            </div>
            <div class="row mb-3 gy-3">
              <div
                  class="col-md-12 col-lg-8"
                  :class="{ 'has-error': invoiceFullNameError }"
              >
                <div class="form-group">
                  <label class="form-label" for="ifullname">
                    {{ $t("invoice.fullname") }}
                  </label>
                  <input
                      class="form-control"
                      id="ifullname"
                      type="text"
                      v-model="invoice.fullname"
                      maxlength="128"
                  />
                  <span v-if="invoiceFullNameError" class="help-block">{{
                      $t(invoiceFullNameError, { value: invoice.fullname })
                    }}</span>
                </div>
              </div>
            </div>
            <div class="row mb-3 gy-3">
              <div
                  class="col-md-12 col-lg-8"
                  :class="{ 'has-error': invoiceAddressError }"
              >
                <div class="form-group">
                  <label class="form-label" for="iaddress">
                    {{ $t("invoice.address") }}
                  </label>
                  <input
                      class="form-control"
                      id="iaddress"
                      type="text"
                      v-model="invoice.address"
                      maxlength="128"
                  />
                  <span v-if="invoiceAddressError" class="help-block">{{
                      $t(invoiceAddressError)
                    }}</span>
                </div>
              </div>
            </div>
            <div class="row gy-3">
              <div class="col-lg-8">
                <locations-costa-rica
                    :locationsCostaRica="locationsCR"
                    :attempts="registerAttempts"
                    @locations-CR="handleLocationsCr($event)"
                ></locations-costa-rica>
              </div>
            </div>
            <div class="row mb-3 gy-3">
              <div
                  class="col-md-12 col-lg-8"
                  :class="{ 'has-error': invoiceEmailError }"
              >
                <div class="form-group">
                  <label class="form-label" for="iemail">
                    {{ $t("invoice.email") }}
                  </label>
                  <input
                      class="form-control"
                      id="iemail"
                      type="text"
                      v-model="invoice.email"
                      maxlength="64"
                  />
                  <span v-if="invoiceEmailError" class="help-block">{{
                      $t(invoiceEmailError, { value: invoice.email })
                    }}</span>
                </div>
              </div>
            </div>
            <div class="row mb-3 gy-3">
              <div
                  class="col-md-6 col-lg-4"
                  :class="{ 'has-error': invoicePhoneError }"
              >
                <div class="form-group">
                  <label class="form-label" for="iphone">
                    {{ $t("invoice.phone") }}
                  </label>
                  <input
                      class="form-control"
                      id="iphone"
                      type="text"
                      v-model="invoice.phone"
                      maxlength="32"
                  />
                  <span v-if="invoicePhoneError" class="help-block">{{
                      $t(invoicePhoneError)
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import {Invoice, LocationsCR, User} from "@/entities.js";
import { isEmpty, validName, validPhone, validEmail } from "@/validations";
import LocationsCostaRica from "@/components/utilities/LocationsCostaRica.vue";
import {ApiService} from "@/rest";
import { mapGetters } from "vuex";

export default {
  name: "OpportunityPayment",
  components: {
    LocationsCostaRica
  },
  props: {
    total: {
      type: Number,
      default: 0
    },
    registerAttempts: {
      type: Number,
      default: 0
    },
    isPayedByPlan:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      invoice: new Invoice(),
      generateInvoice: false,
      locationsCR: new LocationsCR(),
      isUserDataLoaded: false
    };
  },
  computed: {
    ...mapGetters(["isLogged", "userInfo"]),
    invoiceRefnumError() {
      if (this.registerAttempts > 0) {
        if (isEmpty(this.invoice.refnum)) return "error-messages.required";
      }

      return "";
    },
    invoiceIdentificationError() {
      if (this.registerAttempts > 0) {
        if (isEmpty(this.invoice.identification))
          return "error-messages.required";
      }

      return "";
    },
    invoiceFullNameError() {
      if (this.registerAttempts > 0) {
        if (isEmpty(this.invoice.fullname)) return "error-messages.required";
        if (!validName(this.invoice.fullname))
          return "error-messages.invalidName";
      }
      return "";
    },
    invoiceAddressError() {
      if (this.registerAttempts > 0) {
        if (isEmpty(this.invoice.address))
          return "error-messages.invalidAddress";
      }
      return "";
    },
    invoiceEmailError() {
      if (this.registerAttempts > 0) {
        if (!validEmail(this.invoice.email))
          return "error-messages.invalidEmail";
      }
      return "";
    },
    invoicePhoneError() {
      if (this.registerAttempts > 0) {
        if (!validPhone(this.invoice.phone))
          return "error-messages.invalidPhone";
      }

      return "";
    }
  },
  watch: {
    total(val) {
      this.emitInvoiceData();
    },
    invoice: {
      deep: true,
      handler() {
        this.emitInvoiceData();
      }
    }
  },
  methods: {
    validateInvoiceForm() {
      if (this.generateInvoice) {
        if (isEmpty(this.invoice.identification)) return false;
        if (isEmpty(this.invoice.fullname) || !validName(this.invoice.fullname))
          return false;
        if (isEmpty(this.invoice.address)) return false;
        if (!validEmail(this.invoice.email)) return false;
        if (!validPhone(this.invoice.phone)) return false;
      }

      return true;
    },
    emitInvoiceData() {
      if (this.total > 0) {
        if (this.validateInvoiceForm()) {
          this.invoice.amount = this.total.toFixed(2);

          //TODO: define internal code field structure
          this.invoice.codigoint = "TODO";

          this.$emit("invoice-data", this.invoice);
        } else {
          this.$emit("invoice-data", "error");
        }

        return;
      }

      this.$emit("invoice-data", null);
    },
    handleLocationsCr(locationsCostaRica) {
      this.invoice.province = locationsCostaRica.province;
      this.invoice.canton = locationsCostaRica.canton;
      this.invoice.district = locationsCostaRica.district;
    },
    loadCurrentUserData() {
      if(!this.isUserDataLoaded){
        if (!this.isLogged) {
          return;
        }

        ApiService.GET('/users/protected/' + this.userInfo.id, (err, apiResponse) => {
          if (!err) {
            this.isUserDataLoaded = true;

            let user = new User(apiResponse);
            this.invoice.idType = user.idType;
            this.invoice.identification = user.identification;
            this.invoice.fullname = user.name + " " + user.lastName + " " + user.lastName2;
            this.invoice.email = user.email;

            if(user.phoneList.length > 0) {
              this.invoice.phone = user.phoneList[0].phone
            }

            this.locationsCR = new LocationsCR({
              province: user.province,
              canton: user.canton,
              district: user.district
            });

            this.handleLocationsCr(this.locationsCR);
          }
        });
      }
    },
  },
  created() {
    this.loadCurrentUserData();
  }
};
</script>