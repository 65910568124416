<template>
  <div v-if="isRequestInProgress">
    <loading></loading>
  </div>
  <div v-else class="container">
    <common-result 
      :primaryText="primaryText"
      :redirecPath="buyPlan" />
  </div>
</template>

<script>
// @ is an alias to /src
import CommonResult from '@/components/common/CommonResult.vue'
import {ApiService} from "@/rest";
import paymentResponse from "@/utils/paymentResponse";
import Loading from "@/components/utilities/Loading.vue";

export default {
  name: 'BuyPlanResultView',
  components: {
    Loading,
    CommonResult
  },
  data() {
    return {
      isRequestInProgress : true,
      handledPaymentResponse: '',
      primaryText: 'success-messages.boughtPlan',
      buyPlan : '/buyPlan'
    };
  },
  methods: {
      goHome () {
          this.$router.push("/");
      },
      saveResponse() {
          let callback = (err) => {
              if (!err) {
                  this.isRequestInProgress = false;
              }
          }

          if(this.handledPaymentResponse == 'cancel'){
              this.isRequestInProgress = false;
              this.primaryText = 'success-messages.canceledPayment'
              this.buyPlan = '/buyPlan'
          } else {
            ApiService.POST('/payment/paymentResponse/', this.handledPaymentResponse, callback);
          }
      }
    },
    mounted() {
      window.scrollTo(0,0);
      this.saveResponse();
    },
    created() {
      this.handledPaymentResponse = paymentResponse.handlePaymentResponse();

    }
}
</script>
