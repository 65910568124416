<template>
  <div>
    <div class="table-responsive">
      <table class="table table-striped" aria-hidden="true">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('commons.details') }}</th>
            <th scope="col">{{ $t('commons.dateCreated') }}</th>
            <th scope="col">{{ $t('opportunity.price') }}</th>
            <th scope="col">{{ $t('commons.status') }}</th>
            <th scope="col">{{ $t('opportunity.level') }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(opportunity, index) in paginatedItems.items" :key="opportunity.id">
            <th scope="row">{{index + 1}}</th>
            <td>{{opportunity.brand}} {{opportunity.model}}</td>
            <td>{{formattedDate(opportunity.dateCreated)}}</td>
            <td>{{formattedPrice(opportunity.price, opportunity.currency)}}</td>
            <td>{{ $t('opportunityStatus.' + opportunity.status) }}</td>
            <td>{{opportunity.level}}</td>
            <td class="text-right">
              <button type="button" class="btn btn-primary btn-xs" @click="goToOpportunityDetail(opportunity.id)">
                {{ $t('commons.viewDetail') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination
                :total="paginatedItems.totalItems"
                :perPage="paginatedItems.perPage"
                :pageNumber="paginatedItems.pageNumber"
                @loadNewPageData="$emit('loadNewPageData', $event)"
                class="mt-4"
    ></pagination>
  </div>
</template>
<script>

import {formatIso8601ToShorTime, priceFormatter} from "@/helper";
import Pagination from "@/components/utilities/Pagination.vue";
import {PaginatedItems} from "@/entities";

export default {
  name: 'UsersOpportunitiesList',
  components: {Pagination},
  props: {
    paginatedItems: {
      type: Object,
      default: () => new PaginatedItems()
    }
  },
  methods: {
    formattedDate(dateCreated) {
      return formatIso8601ToShorTime(dateCreated);
    },
    formattedPrice(amount, currency) {
      return priceFormatter(amount, currency);
    },
    goToOpportunityDetail(opportunityId) {
      this.$router.push({
        name: 'OpportunityDetailView',
        params: {
          opportunityId: opportunityId
        }
      })
    }
  }
}
</script>
