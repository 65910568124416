<template>
  <div class="zthumbnail">
    <img :src="imgUrl" class="zthumbnail__img" alt="">
    <a class="zthumbnail__trash" href="#" @click.prevent="deleteAttachment">
      <em class="bi bi-x-circle"></em>
    </a>
    <div class="zthumbnail__makeMain">
      <div class="form-check">
        <input :checked="isMain" @click="$emit('is-main-thumbnail', identifier)"
               class="form-check-input" type="checkbox" :id="identifier">
        <label class="form-check-label" :for="identifier">
          {{$t('opportunity.cover')}}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
  import { ApiService } from "@/rest.js"

  export default {
    name: 'Thumbnail',
    props: {
      identifier: {
        type: String
      },
      isMain : {
        type : Boolean
      }
    },
    computed: {
      imgUrl() {
        return ApiService.getImageURL(this.identifier, true)
      }
    },
    methods: {
      deleteAttachment() {
        ApiService.DELETE('/attachment/protected/' + this.identifier, (err, apiResponse) => {
          if (err) {
            // TODO
          } else {
            this.$emit('removed-image', this.identifier)
          }
        })
      }
    }
  }
</script>
