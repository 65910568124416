<template>
  <div class="container">
    <div>
      <h2>
        {{ $t('invoice.title') }}
      </h2>
      <div class="white-box">
        <user-invoices-list :user-invoices="userInvoices"/>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { ApiService } from '@/rest.js'
import UserInvoicesList from "@/components/administration/opportunity/UserInvoicesList"

export default {
  name: 'UserInvoicesView',
  components: {
    UserInvoicesList
  },
  data() {
    return {
      userInvoices: [],
      userId: this.$route.params.userId
    };
  },
  methods: {
    getUserInvoices() {
      ApiService.GET(
          "/opportunity/protected/invoices/" + this.userId,
          (err, apiResponse) => {
            if (!err) {
              this.userInvoices = apiResponse;
            }
          }
      );
    },
  },
  created() {
    this.getUserInvoices(this.userId);
  }
}
</script>
