<template>
  <div>
    <opportunity-search @query="query($event)"></opportunity-search>
    <template v-if="isRequestInProgress">
      <loading></loading>
    </template>
    <template v-else>
      <template v-if="paginatedItems.items && paginatedItems.items.length > 0">

          <div class="row mt-1">
            <div class="col h2 text-center search_results">
               <strong>{{ $t('search.results') }} </strong>
            </div>
          </div>


        <opportunity-list
            class="search-content"
            :paginatedItems="paginatedItems"
            @loadNewPageData="getNewPageData($event)"
            ref="listComponent"
        />
      </template>
      <template v-else>
        <not-results-found></not-results-found>
      </template>
    </template>
  </div>

</template>

<style scoped>

.search-content {
  grid-area: content;
}
</style>

<script>
// @ is an alias to /src
import {ApiService} from "@/rest.js";
import OpportunityList from "@/components/opportunity/OpportunityList.vue";
import OpportunitySearch from "@/components/search/OpportunitySearch.vue";
import {AdvancedSearchFilters, PaginatedItems} from "@/entities.js";
import Loading from '@/components/utilities/Loading.vue'
import NotResultsFound from '@/components/common/NotResultsFound.vue'

export default {
  name: "SearchingOpportunitiesView",
  components: {
    OpportunityList,
    OpportunitySearch,
    Loading,
    NotResultsFound
  },
  data() {
    return {
      paginatedItems: new PaginatedItems(),
      advancedSearchFilters: null,
      currentPage: 1,
      isRequestInProgress: true
    };
  },
  methods: {
    getOpportunities(pageNumber) {
      this.currentPage = pageNumber;
      this.isRequestInProgress = true;

      if (this.advancedSearchFilters) {
        this.advancedSearchFilters.pageNumber = this.currentPage;

        ApiService.POST(
            "/search/advanceSearch",
            this.advancedSearchFilters,
            (err, apiResponse) => {
              if (!err) {
                this.paginatedItems = apiResponse
              }
              this.isRequestInProgress = false;
            }
        );
      } else {
        ApiService.GET(
            "/opportunity/available/" + this.currentPage,
            (err, apiResponse) => {
              if (!err) {
                this.paginatedItems = apiResponse;
              }
              this.isRequestInProgress = false;
            }
        );
      }
    },
    getNewPageData(pageNumber) {
      this.currentPage = pageNumber;

      if (this.advancedSearchFilters) {
        this.advancedSearchFilters.pageNumber = this.currentPage;
      }

      this.getOpportunities(pageNumber);
    },
    query(advancedSearchFilters) {
      this.advancedSearchFilters = advancedSearchFilters;
      this.getOpportunities(1);
      try {
        this.$refs.listComponent.displayPreviewList();
      } catch (err) {
        // Ignore null ref
      }
    }
  }
};
</script>
