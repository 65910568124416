<template>
  <div class="fupload">
    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
      <div class="fupload__dropbox">
        <input type="file" :multiple="isMultiSelect" :name="uploadFieldName" :disabled="isSaving"
          @change="filesChange($event.target.name, $event.target.files)"
          :accept="acceptedTypes" class="fupload__input-file">

        <div v-if="isInitial" class="fupload__displaytext">
          <div class="fs-3">
            <em class="bi bi-upload"></em>
          </div>
          <div>
            <span>{{ $t('fupload.message') }}</span>
            <br>
            <span>{{ $t('fupload.submessage') }}</span>
            <br>
            <span class="fupload__highlighted_red">{{ $t('fupload.maxAttach', { maxAttachments }) }}</span>
          </div>
        </div>

        <div v-if="isSaving" class="fupload__displaytext">
          <div class="spinner-border" role="status">
            <span class="visually-hidden"></span>
          </div>
          <div>
            {{ $t('fupload.uploading', { fileCount }) }}
          </div>
        </div>
      </div>
    </form>

    <div class="fupload__displaybox" v-if="isSuccess || isFailed">
      <div v-if="isSuccess" class="fupload__displaytext">
        <div class="fs-3">
          <em class="bi bi-check-circle"></em>
        </div>
        {{ $t('fupload.success', { fileCount: uploadedFiles.length }) }}
        <br>
        <button type="button" class="btn btn-default fupload__highlighted_red" @click="reset()">{{ $t('fupload.more') }}</button>
      </div>

      <div v-if="isFailed" class="fupload__displaytext fupload__highlighted_red">
        <div class="fs-3">
          <em class="bi bi-exclamation-triangle"></em>
        </div>
        {{ $t('fupload.error') }}
        <br>
        <button type="button" class="btn btn-default" @click="reset()">{{ $t('fupload.again') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
  import { ApiService } from "@/rest.js"

  const STATUS_INITIAL = 0
  const STATUS_SAVING = 1
  const STATUS_SUCCESS = 2
  const STATUS_FAILED = 3

  export default {
    name: 'FileUpload',
    props: {
      acceptedTypes: {
        type: String,
        default: 'image/*'
      },
      uploadFieldName: {
        type: String,
        default: 'photos'
      },
      uploadURL: {
        type: String
      },
      isMultiSelect: {
        type: Boolean,
        default: true
      },
      maxAttachments: {
        type: Number,
        default: 10
      }
    },
    data() {
      return {
        uploadedFiles: [],
        currentStatus: null,
        fileCount: 0
      }
    },
    computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED
      }
    },
    methods: {
      reset() {
        this.currentStatus = STATUS_INITIAL
        this.uploadedFiles = []
        this.fileCount = 0
      },
      filesChange(fieldName, fileList) {
        this.fileCount = Math.min(fileList.length, this.maxAttachments);

        if (this.fileCount) {
          const formData = new FormData()

          Array.from(Array(this.fileCount).keys())
            .forEach(x => {
              formData.append(fieldName, fileList[x], fileList[x].name)
            })

          this.save(formData)
        }
      },
      save(formData) {
        this.currentStatus = STATUS_SAVING

        ApiService.UPLOAD(this.uploadURL, formData, (err, apiResponse) => {
          if (err) {
            this.currentStatus = STATUS_FAILED
          } else {
            this.currentStatus = STATUS_SUCCESS
            this.uploadedFiles = []
            
            let addedIds = apiResponse.map(att => att.id)

            for (let i = 1; i <= this.fileCount; i+= 1) {
              this.uploadedFiles.push("File " + i)
            }

            this.$emit('added-images', addedIds.join(','))
          }
        })
      }
    },
    mounted() {
      this.reset()
    }
  }
</script>
