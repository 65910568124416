<template>
  <div>
    <div>
      <template v-if="isRequestInProgress">
        <loading></loading>
      </template>
      <template v-else>
        <template v-if="paginatedItems.items && paginatedItems.items.length > 0">
          <seller-info-banner :seller-info="sellerInfo"></seller-info-banner>
          <br>
          <div class="container-fluid">
            <opportunity-list
                :paginatedItems="paginatedItems"
                :getOpportunities="getOpportunities"
                @loadNewPageData="getNewPageData($event)"
                ref="listComponent"
            />
          </div>
        </template>
        <template v-else>
          <not-results-found></not-results-found>
        </template>
      </template>
    </div>
  </div>
</template>

<style scoped>
.search-layout {
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "sidebar"
    "content";
}

@media (min-width: 768px) {
  .search-layout {
    grid-template-columns: 320px 1fr;
    grid-template-areas: "sidebar content";
  }
}

.search-side {
  grid-area: sidebar;
}

.search-content {
  grid-area: content;
}
</style>

<script>
// @ is an alias to /src
import { ApiService } from "@/rest.js";
import OpportunityList from "@/components/opportunity/OpportunityList.vue";
import SellerInfoBanner from "@/components/opportunity/SellerInfoBanner.vue";
import Loading from '@/components/utilities/Loading.vue'
import NotResultsFound from '@/components/common/NotResultsFound.vue'
import { PaginatedItems, SellerInfo } from "@/entities.js";

export default {
  name: "SellerOpportunitiesView",
  components: {
    OpportunityList,
    Loading,
    NotResultsFound,
    SellerInfoBanner
  },
  props: {
    sellerId: {
      type: String
    }
  },
  data() {
    return {
      paginatedItems: new PaginatedItems(),
      advancedSearchFilters: null,
      currentPage: 1,
      isRequestInProgress : true,
      sellerInfo : new SellerInfo()
    };
  },
  methods: {
    getOpportunities(pageNumber) {
      this.currentPage = pageNumber;
      this.isRequestInProgress = true;

      ApiService.GET(
        "/opportunity/sellerAvailableOpportunities/"+ this.sellerId + "/" + this.currentPage,
        (err, apiResponse) => {
          if (!err) {
            this.paginatedItems = apiResponse;
          }
          this.isRequestInProgress = false;
        }
      );

      ApiService.GET(
          "/users/sellerInfo/"+ this.sellerId,
          (err, apiResponse) => {
            if (!err) {
              this.sellerInfo = apiResponse;
            }
          }
      );


    },
    getNewPageData(pageNumber) {
      this.currentPage = pageNumber;
      this.getOpportunities(pageNumber);
    }
  },
  mounted() {
    window.scrollTo(0,0);
    this.getOpportunities(1);
  }
};
</script>
