<template>
  <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document" :class="{ 'modal-xl': isLarge }">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">{{ $t(title) }}</h2>
          <button type="button" class="btn-close" :aria-label="$t('commons.close')" @click="close()">
          </button>
        </div>
        <div v-if="active" class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer" v-show="this.displayButtons">
          <button type="button" class="btn" :class="[okText ? 'btn-default' : 'btn-primary']" @click="close()">{{ $t(closeText) }}</button>
          <button v-if="okText" type="button" class="btn btn-primary" @click="$_modal_ok()">{{ $t(okText) }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'modal',
    props: {
      title: {
        type: String,
        default: 'label_title'
      },
      okText: {
        type: String,
        default: 'commons.send'
      },
      closeText: {
        type: String,
        default: 'commons.close'
      },
      isLarge: {
        type: Boolean,
        default: false
      },
      displayButtons: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        active: false
      }
    },
    methods: {
      show() {
        this.active = true
        $(this.$refs.modal).modal('show')
      },
      close() {
        $(this.$refs.modal).modal('hide')
      },
      $_modal_ok() {
        this.$emit('proceed')
      }
    },
    mounted: function () {
      $(this.$refs.modal).on('hidden.bs.modal', (e) => {
        this.active = false
        this.$emit('close')
      })
    }
  }
</script>
