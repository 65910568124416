<template>
  <header class="sellerBanner">
    <div class="sellerBanner__center">
      <div>
        <div class="text-white text-center">
          <h1>{{ sellerInfo.fullName }}</h1>
          <h5> {{ buildPhoneTextToDisplay() }} </h5>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

import {SellerInfo} from "@/entities";

export default {
  name: "SellerInfo",
  props: {
    sellerInfo: {
      type: Object,
      default: () => new SellerInfo()
    }
  },
  methods: {
    buildPhoneTextToDisplay() {

      const phonesCount =  this.sellerInfo.phones.length;

      if(phonesCount === 0){
        return ""
      }

      if(phonesCount === 1){
        let phone = this.sellerInfo.phones[0];

        return this.getLabelForPhoneType(phone.phoneType) + " : " + phone.phone;
      }

      let textToDisplay = "";

      for(let index = 0; index < phonesCount; index++) {
        let phone = this.sellerInfo.phones[index];

        if(index < phonesCount - 1){
          textToDisplay += this.getLabelForPhoneType(phone.phoneType) + " : " + phone.phone + " / ";
        } else {
          textToDisplay += this.getLabelForPhoneType(phone.phoneType) + " : " + phone.phone;
        }
      }

      return textToDisplay;

    },
    getLabelForPhoneType(phoneType) {

      let label = "";

      switch (phoneType) {
        case 'COMERCIAL':
          label = this.$i18n.t("phoneDescription.merchant");
          break;
        case 'HOME':
          label = this.$i18n.t("phoneDescription.home");
          break;
        case 'MOBILE':
          label = this.$i18n.t("phoneDescription.mobile");
          break;
        case 'WHATSAPP':
          label = this.$i18n.t("phoneDescription.whatsapp");
          break;
      }

      return label;
    }
  }
};
</script>
