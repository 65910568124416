<template>
  <div v-if="isRequestInProgress">
    <loading></loading>
  </div>
  <div v-else class="container">
    <h2 class="mb-3">{{ $t('user.maintenance.userOpportunities') }}</h2>
    <div class="mb-3">
    <span>{{ $t('commons.email') }}: <strong>{{user.email}}</strong>, {{$t('commons.fullName') }}: <strong>{{user.name}} {{user.lastName}} {{user.lastName2}}</strong></span>
    </div>
    <div v-if="!isRequestInProgress && localPaginatedItems.items && localPaginatedItems.items.length == 0">
      <not-results-found></not-results-found>
    </div>
    <div v-else>
      <div class="mb-3">
        <button
            type="button"
            class="btn btn-primary me-2"
            @click="displayList()"
        >{{ $t('commons.list') }}</button>
        <button
            type="button"
            class="btn btn-primary"
            @click="displayMiniatures()"
        >{{ $t('commons.miniatures') }}</button>
      </div>
      <users-opportunities-list v-if="viewList"
          :paginatedItems="localPaginatedItems"
          @loadNewPageData="getNewPageData($event)">
      </users-opportunities-list>
      <opportunity-list v-if="viewMiniatures"
          class="search-content"
          ref="listComponent"
          :paginatedItems="localPaginatedItems"
          :getOpportunities="getOpportunities"
          :isEditableList="true"
          :sendToEdit="sendToEdit"
          @loadNewPageData="getNewPageData($event)"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ApiService } from "@/rest.js";
import OpportunityList from '@/components/opportunity/OpportunityList.vue';
import { PaginatedItems, User } from "@/entities.js";
import OpportunityRegister from '@/components/opportunity/OpportunityRegister.vue'
import UsersOpportunitiesList from '@/components/opportunity/UsersOpportunitiesList.vue'
import NotResultsFound from '@/components/common/NotResultsFound.vue'
import Loading from "@/components/utilities/Loading.vue";

export default {
  name: 'UserOpportunitiesViewAdmin',
  components: {
    OpportunityList,
    OpportunityRegister,
    NotResultsFound,
    Loading,
    UsersOpportunitiesList
  },
  props:{
    userId: {
      type: String
    }
  },
  data() {
    return {
      localPaginatedItems : new PaginatedItems(),
      currentPage : 1,
      isEditing : true,
      user : new User(),
      isRequestInProgress : false,
      viewList : true,
      viewMiniatures : false
    };
  },
  methods :{
    getOpportunities(pageNumber) {
      this.isRequestInProgress = true;
      this.currentPage = pageNumber;
      ApiService.GET('/opportunity/protected/userOpportunities/'+this.userId +'/'+this.currentPage, (err, apiResponse) => {
			if (!err) this.localPaginatedItems = apiResponse;
      this.isRequestInProgress = false;
		  })
    },
    getNewPageData(pageNumber) {
      this.currentPage = pageNumber;

      if (this.advancedSearchFilters) {
        this.advancedSearchFilters.pageNumber = this.currentPage;
      }

      this.getOpportunities(pageNumber);
    },
    loadUserData(){
      ApiService.GET('/users/protected/'+this.userId, (err, apiResponse) => {
        if (!err) this.user = apiResponse;
      })
    },
    sendToEdit(editingOpportunity){
      this.toUpdateOpportunity = editingOpportunity;
    },
    displayList(){
      this.viewList = true;
      this.viewMiniatures = false;
    },
    displayMiniatures(){
      this.viewList = false;
      this.viewMiniatures = true;
    }
  },
  created() {
    this.getOpportunities(this.currentPage);
    this.loadUserData();
  }
}
</script>