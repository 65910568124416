let flagCache = null;

function regenCache() {
  flagCache = new Set((localStorage.getItem("ff") || "").split(","));
  const flagParam = /\bff=([\w,]+)/.exec(location.href);

  (flagParam ? flagParam[1] : "").split(",").forEach(param => {
    const [_, neg, flag] = /^(no)?(.*)$/.exec(param);

    if (neg) {
      flagCache.delete(flag);
    } else {
      flagCache.add(flag);
    }
  });

  flagCache.delete("");
  document.body.dataset.ff = Array.from(flagCache)
    .sort()
    .join(" ");
}

export function getFlag(flag) {
  if (!flagCache) regenCache();
  return flagCache.has(flag);
}

export function initFlags(flagSpec) {
  localStorage.setItem("ff", flagSpec);
  regenCache();
}
