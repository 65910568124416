export default {
    locale: state => {
      return state.locale
    },
  
    userInfo: state => {
      return state.userInfo
    },
    
    name: (getters) => {
      let userInfo = getters.userInfo
  
      if (userInfo) {
        return userInfo.name
      }
  
      return ''
    },
  
    lastName: (getters) => {
      let userInfo = getters.userInfo
  
      if (userInfo) {
        return userInfo.lastName
      }
  
      return ''
    },
    
    fullName: (getters) => {
      let userInfo = getters.userInfo
  
      if (userInfo) {
        return userInfo.name + ' ' + userInfo.lastName 
      }
  
      return ''
    },
  
    isLogged: (state, getters) => {
      return getters.userInfo != null
    },
  
    isAdmin: (state, getters) => {
      let userInfo = getters.userInfo
  
      if (userInfo) {
        return userInfo.role === 'ADMINISTRATOR'
      }
  
      return false
    },

    isImpersonalizator: (state, getters) => {
        let userInfo = getters.userInfo

        if (userInfo) {
            return userInfo.role === 'IMPERSONALIZATOR'
        }

        return false
    }
  }