<template>
  <div>
    <div class="table-responsive">
      <table class="table table-striped" aria-hidden="true">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('invoice.fullname') }}</th>
            <th scope="col">{{ $t('invoice.email') }}</th>
            <th scope="col">{{ $t('invoice.amount') }}</th>
            <th scope="col">{{ $t('invoice.status') }}</th>
            <th scope="col">{{ $t('invoice.createdDate') }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(invoice, index) in userInvoices" :key="invoice.id">
            <th scope="row">{{index + 1}}</th>
            <td>{{invoice.fullname}}</td>
            <td>{{invoice.email}}</td>
            <td>{{ '₡' }}{{ invoice.amount | currency }}</td>
            <td>{{invoice.status}}</td>
            <td>{{invoice.creationDate}}</td>
            <td v-if="invoice.opportunityId">
              <button type="button" class="btn btn-primary btn-xs" @click="viewOpportunityDetail(invoice.opportunityId)">
                {{ $t('commons.viewOpportunity') }}
              </button>
            </td>
            <td v-else></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

export default {
  name: 'UserInvoicesList',
  props: {
    userInvoices: {
      type: Array,
      default: () => []
    }
  },
  methods : {
    viewOpportunityDetail(opportunityId) {
      this.$router.push({
        name: 'OpportunityDetailView',
        params: {opportunityId: opportunityId }
      })
    },
  }
}
</script>
