<template>
  <div class="container">
    <template v-if="isRequestInProgress">
      <loading></loading>
    </template>
    <template v-else>
      <template v-if="localPaginatedItems.items && localPaginatedItems.items.length > 0">
        <opportunity-list
          :paginatedItems="localPaginatedItems"
          :getOpportunities="loadPageItems"
          @loadNewPageData="getNewPageData($event)"
        />
      </template>
      <template v-else>
        <not-results-found></not-results-found>
      </template>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import { ApiService } from "@/rest.js";
import OpportunityList from "@/components/opportunity/OpportunityList.vue";
import { PaginatedItems } from "@/entities.js";
import Loading from '@/components/utilities/Loading.vue'
import NotResultsFound from '@/components/common/NotResultsFound.vue'

export default {
  name: "MyWishListView",
  components: {
    OpportunityList,
    Loading,
    NotResultsFound
  },
  data() {
    return {
      localPaginatedItems: new PaginatedItems(),
      currentPage: 1,
      isRequestInProgress: false
    };
  },
  methods: {
    loadPageItems() {
      this.isRequestInProgress = true;
      ApiService.GET("/users/protected/myWishList/"+this.currentPage, (err, apiResponse) => {
        this.isRequestInProgress = false;
        if (!err) this.localPaginatedItems = apiResponse;
      });
    },
    getNewPageData(newPageNumber) {
      this.currentPage = newPageNumber;
      this.loadPageItems();
    }
  },
  created() {
    this.loadPageItems(this.currentPage);
  }
};
</script>
