<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-10 col-md-6">
          <h1 class="text-center text-md-start home__title">{{ $t('home.mainTitle') }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button id="ccmc_button_usedCards" @click="checkOpportunities()" class="home__button btn btn-primary btn-lg m-lg-3 ">{{ $t('home.mainButton') }}</button>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-md-5 col-lg-4">
          <div class="home__card home__card__gold">
            <h2>{{ $t('home.goldBox.title') }}</h2>
            <p>{{ $t('home.goldBox.text') }}</p>
            <button id="ccmc_button_plan_gold" class="ccmc__red-button btn btn-primary btn-md"
                    @click.prevent="handleRedirectForCreateOpportunity()">{{ $t('home.goldBox.button') }}</button>
          </div>
        </div>
        <div class="col-12 col-md-5 col-lg-4">
          <div class="home__card home__card__silver d-none d-md-block">
            <h2>{{ $t('home.silverBox.title') }}</h2>
            <p>{{ $t('home.silverBox.text') }}</p>
            <button id="ccmc_button_plan_silver" class="ccmc__red-button btn btn-primary btn-md"
                    @click.prevent="handleRedirectForCreateOpportunity()">{{ $t('home.silverBox.button') }}</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Autocomplete from "@/components/search/Autocomplete.vue";
import {mapGetters} from "vuex";

export default {
  name: "Banner",
  components: {
    Autocomplete
  },
  computed: {
    ...mapGetters(["isLogged"])
  },
  methods: {
    checkOpportunities() {
      this.$router.push("searchingOpportunities");
    },
    handleRedirectForCreateOpportunity() {
      if (!this.isLogged) {
        localStorage.setItem('afterLogin', '/opportunityRegister');
        this.$router.push("/signin");
        return;
      }

      this.$router.push("/opportunityRegister");
    },
  }
};
</script>