<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col text-center mb-2">
        <span class="mx-2">{{ $t(title) }}</span>
        <select v-if="isCurrencyFormat" class="ccmc_input search_currency" id="currency"
                v-model="currency"
                :title="$t('commons.currency')"
                @change="resetDefaultValues()">
          <option value="CRC">CRC</option>
          <option value="USD">USD</option>
        </select>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col">
        <div class="price-input-container">
          <div class="slider-container">
            <div class="price-slider" :style="{left: leftPosition, right: rightPosition}"></div>
          </div>
        </div>
        <div class="range-input">
          <input type="range" :min="minimum" :max="maximum" v-model="minValue" :step="sliderStep">
          <input type="range" :min="minimum" :max="maximum" v-model="maxValue" :step="sliderStep">
        </div>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col mt-1">
        <div class="price-field">
          <span v-if="isCurrencyFormat"> {{ $t('search.minimum') }}</span>
          <strong> {{ formattedMinValue }}</strong>

        </div>
      </div>
      <div class="col mt-1 text-end">
        <div class="price-field">
          <span v-if="isCurrencyFormat"> {{ $t('search.maximum') }}</span>
          <strong> {{ formattedMaxValue }}</strong>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {priceFormatter} from "@/helper.js";
import _ from 'lodash';

export default {
  name: "MultirangeSlider",
  props: {
    title: {
      type: String,
      default: ''
    },
    isCurrencyFormat: {
      type: Boolean,
      default: false
    },
    priceInitialMinValue: { type: String, default: null },
    priceInitialMaxValue: { type: String, default: null },
    yearInitialMinValue: { type: Number, default: null },
    yearInitialMaxValue: { type: Number, default: null }
  },
  data() {
    return {
      minimum: 0,
      maximum: 0,
      minValue: 0,
      maxValue: 0,
      sliderStep: 0,
      stepSize: 0,
      currency: 'CRC'
    };
  },
  computed: {
    leftPosition() {
      return `${(this.minValue - this.minimum) / (this.maximum - this.minimum) * 100}%`;
    },
    rightPosition() {
      return `${(this.maximum - this.maxValue) / (this.maximum - this.minimum) * 100}%`;
    },
    formattedMinValue() {
      return this.isCurrencyFormat ? priceFormatter(this.minValue, this.currency) : this.minValue
    },
    formattedMaxValue() {
      return this.isCurrencyFormat ? priceFormatter(this.maxValue, this.currency) : this.maxValue
    }
  },
  methods: {
    setPriceDefaultValue() {
      if (this.currency === 'CRC') {
        this.minimum = 0;
        this.maximum = 40000000;
        this.stepSize = 100000;
        this.sliderStep = 100000;
      } else if (this.currency === 'USD') {
        this.minimum = 0;
        this.maximum = 80000;
        this.stepSize = 1000;
        this.sliderStep = 1000;
      }

      this.minValue = this.priceInitialMinValue !== null ? this.priceInitialMinValue : this.minimum;
      this.maxValue = this.priceInitialMaxValue !== null ? this.priceInitialMaxValue : this.maximum;
    },
    setYearDefaultValue() {
      let maxYear = this.calculateLatestYearTo() + 1;

      this.minimum = 1990;
      this.maximum = maxYear;
      this.stepSize = 1;
      this.sliderStep = 1;

      this.minValue = this.yearInitialMinValue !== null ? this.yearInitialMinValue : this.minimum;
      this.maxValue = this.yearInitialMaxValue !== null ? this.yearInitialMaxValue : this.maximum;

    },
    resetDefaultValues() {
      if (this.isCurrencyFormat) {
        this.setPriceDefaultValue();
        this.$emit("currency-value", this.currency);
      } else {
        this.setYearDefaultValue();
      }

      this.$emit("currency-changed", this.currency);
    },
    calculateLatestYearTo() {
      return new Date().getFullYear();
    },
    updateMinValue: _.debounce(function (newVal) {
      if (newVal < 0) {
        this.minValue = 0;
      } else if (newVal >= this.maxValue) {
        this.minValue = this.maxValue - this.sliderStep;
      }
      this.$emit("min-value", this.minValue);
    }, 300),
    updateMaxValue: _.debounce(function (newVal) {
      if (newVal > this.maximum) {
        this.maxValue = this.maximum;
      } else if (newVal <= this.minValue) {
        this.maxValue = this.minValue + this.sliderStep;
      }
      this.$emit("max-value", this.maxValue);
    }, 300)
  }
  ,
  watch: {
    minValue(newVal) {
      this.updateMinValue(newVal);
    }
    ,
    maxValue(newVal) {
      this.updateMaxValue(newVal);
    }
  },
  mounted() {
    this.resetDefaultValues();
  }
}

</script>
