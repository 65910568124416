<template>
  <div class="container justify-content-center">
    <div class="row">
      <div class="col">
        <div v-if="!opportunity">
          <div class="opp-container opp-container--is-aligned mt-3">
            <opportunity-miniature
                v-for="opp in paginatedItems.items"
                :key="opp.id"
                :opportunity="opp"
                :isEditable="isEditableList"
                :isCompact="false"
                :is-owner="isOwner"
                @showOpportunityDetail="displayDetail"
            />
          </div>
        </div>
        <div v-if="opportunity">
          <opportunity-detail
              :opportunity="opportunity"
              :isEditable="isEditableList"></opportunity-detail>
        </div>
        <pagination v-if="!opportunity"
                    :total="paginatedItems.totalItems"
                    :perPage="paginatedItems.perPage"
                    :pageNumber="paginatedItems.pageNumber"
                    @loadNewPageData="$emit('loadNewPageData', $event)"
                    class="mt-4"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import OpportunityMiniature from "@/components/opportunity/OpportunityMiniature.vue";
import OpportunityDetail from "@/components/opportunity/OpportunityDetail.vue";
import Pagination from "@/components/utilities/Pagination.vue";
import {PaginatedItems} from "@/entities";

export default {
  name: "OpportunityList",
  components: {
    OpportunityMiniature,
    OpportunityDetail,
    Pagination
  },
  props: {
    paginatedItems: {
      type: Object,
      default: () => new PaginatedItems()
    },
    isEditableList: {
      type: Boolean,
      default: false
    },
    isCompact: {
      type: Boolean,
      default: false
    },
    isOwner: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      opportunity: null
    };
  },
  methods: {
    displayDetail(opportunity) {
      this.opportunity = opportunity;
    },
    updateOpportunity(pageNumber) {
      this.getOpportunities(pageNumber);
    }
  }
};
</script>
