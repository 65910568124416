<template>
  <div v-if="isRequestInProgress">
    <loading></loading>
  </div>
  <div v-else class="container">
    <div>
      <h2>
        {{ $t('dashboard.commons.reported.title') }}
      </h2>
      <template v-if="paginatedItems.items && paginatedItems.items.length > 0" class="white-box">
          <reported-opportunity-list :paginatedItems="paginatedItems"/>
      </template>
      <template v-else>
        <not-results-found></not-results-found>
      </template>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { ApiService } from '@/rest.js'
import ReportedOpportunityList from "@/components/opportunity/ReportedOpportunityList";
import {PaginatedItems} from "@/entities";
import NotResultsFound from '@/components/common/NotResultsFound.vue'
import Loading from "@/components/utilities/Loading.vue";

export default {
  name: 'ReportedOpportunitiesView',
  components: {
    ReportedOpportunityList,
    NotResultsFound,
    Loading
  },
  data() {
    return {
      paginatedItems: new PaginatedItems(),
      isRequestInProgress : false
    };
  },
  methods: {
    loadReported() {
      this.isRequestInProgress = true;
      ApiService.GET('/complaint/protected/unresolved/1', (err, apiResponse) => {
        this.isRequestInProgress = false;
        if (!err){
          this.paginatedItems = new PaginatedItems(apiResponse);
        }
      });
    }
  },
  created() {
    this.loadReported();
  }
}
</script>
