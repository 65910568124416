<template>
  <div>
    <div class="table-responsive">
      <table class="table table-striped" aria-hidden="true">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">{{ $t('dashboard.opportunityId') }}</th>
            <th scope="col">{{ $t('commons.details') }}</th>
            <th scope="col">{{ $t('user.name') }}</th>
            <th scope="col">{{ $t('dashboard.resolved') }}</th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(report, index) in paginatedItems.items" :key="report.id">
             <th scope="row">{{index + 1}}</th>
            <td>{{report.opportunityId}}</td>
            <td>{{report.details}}</td>
            <td>{{report.dateCreated}}</td>
            <td>{{ $t(findLabelForStatus(report.resolved)) }}</td>
            <td class="text-right">
              <button type="button" class="btn btn-primary btn-xs" @click="sendToEdit(report.opportunityId)">
                {{ $t('commons.viewOpportunity') }}
              </button>
            </td>
            <td class="text-right">
              <button type="button" class="btn btn-primary btn-xs" @click="resolveComplaint(report.id)">
                {{ $t('dashboard.resolve') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
     <pagination :total="paginatedItems.totalItems" :perPage="paginatedItems.perPage" :pageNumber="paginatedItems.pageNumber"
      @loadNewPageData=""></pagination>
  </div>
</template>
<script>
import { PaginatedItems } from "@/entities.js";
import Pagination from '@/components/utilities/Pagination.vue'
import {ApiService} from "@/rest";

export default {
  name: 'ReportedOpportunityList',
  components: {
    Pagination	
	},
  props: {
    paginatedItems: {
      type: Object,
      default: () => new PaginatedItems()
    }
  },
  methods: {
    findLabelForStatus (resolved) {
      if (resolved == 'true'){
        return 'commons.yes'
      }

      return 'commons.no'
    },
    sendToEdit(oppId) {
      this.$router.push({
        name: 'opportunityUpdateView',
        params: {opportunityId: oppId }
      })
    },
    resolveComplaint(id) {
      ApiService.POST('/complaint/protected/'+ id +'/resolve/', (err) => {
        if (err) {}
      })
    }
  }
}
</script>
