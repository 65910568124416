<template>
  <div class="white-box">
    <div class="row">
      <div
          v-for="category of extrasCategories"
          :key="category.name"
          class="col"
      >
        <div class="sec-header text-center mb-2 pb-1">
          <strong>{{
              category.name
            }}</strong>
        </div>
        <div
            v-for="extra of category.extras"
            :key="extra.key"
            :class="{'form-check': editing}"
        >
          <template v-if="editing">
            <input
                class="form-check-input"
                type="checkbox"
                :value="extra.key"
                :id="extra.key"
                v-model="selectedExtras"
            />
            <label class="form-check-label" :for="extra.key">
              {{ extra.label }}
            </label>
          </template>
          <template v-else-if="isChecked(extra.key)">
            <em class="bi bi-check-circle" aria-hidden="true"></em>
            {{" "}}
            <span>{{ $t("opportunity.extras." + extra.key) }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let extrasCategoriesKeys = [
  {
    categoryName: "confort",
    extrasKeys: [
      "AIR_CONDITIONING",
      "ADJUSTABLE_STEERING_WHEEL",
      "CRUISE_CONTROL",
      "ELECTRIC_GLASSES",
      "ELECTRIC_MIRRORS",
      "ELECTRONIC_STABILITY",
      "HYDRAULIC_STEERING",
      "LEATHER_UPHOLSTERY",
      "LEATHER_UPHOLSTERY_CLOTH",
      "MEMORY_SEAT",
      "MULTIFUNCTION_STEERING",
      "RUBBER_MAT",
      "STEERING_WHEEL_CONTROL",
      "STEERING_WHEEL_GEARSHIFT",
      "TRAVEL_COMPUTER",
      "DUAL_GEARBOX"
    ]
  },
  {
    categoryName: "segurity",
    extrasKeys: [
      "ABS_BRAKES",
      "AIRBAGS",
      "ALARM",
      "CENTRAL_LOCKING",
      "SMART_KEY",
      "TIRE_PRESSURE_MONITOR",
      "TURBO",
      "POLARIZED_GLASSES",
      "POWER_SEATS"
    ]
  },
  {
    categoryName: "exterior",
    extrasKeys: [
      "DIRECTIONAL_MIRROR",
      "LUGGAGE_COVER",
      "LUXURY_RINGS",
      "FRONT_SENSORS",
      "HALOGEN",
      "RAIN_SENSOR",
      "RACKS",
      "REAR_DEFROSTER",
      "REVERSE_CAMERA",
      "REVERSE_SENSORS",
      "ROOF_BASKET",
      "ROOF_RAILS",
      "SELF_RETRACTING_MIRRORS",
      "SUNROOF",
      "XENON_LIGHTS"
    ]
  },
  {
    categoryName: "audioVideo",
    extrasKeys: [
      "DASH_SCREEN",
      "DVD_SCREEN",
      "RADIO_WITH_CASSETTE",
      "RADIO_WITH_CD",
      "RADIO_WITH_USB"
    ]
  }
];

import {Extra, ExtraCategory} from "@/entities";
import { GlobalEventEmitter } from '@/main.js'

export default {
  name: "Extras",
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    preSelectedExtras: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      extrasCategories: [],
      selectedExtras:
          this.preSelectedExtras && this.preSelectedExtras.length > 0
              ? this.preSelectedExtras.split(",").sort()
              : []
    };
  },
  methods: {
    isChecked(extraKey) {
      return this.selectedExtras.includes(extraKey);
    },
    compareExtra(extra1, extra2) {
      return extra1.label.localeCompare(extra2.label);
    },
    loadExtrasLabels() {
      this.extrasCategories = [];
      extrasCategoriesKeys.forEach((category) => {
        const extraCategory = new ExtraCategory()
        extraCategory.name = this.$i18n.t("opportunity.extrasCategory." + category.categoryName)
        let extras = [];

        category.extrasKeys.forEach((extraKey) => {
          let extra = new Extra()
          extra.label = this.$i18n.t("opportunity.extras." + extraKey)
          extra.key = extraKey;

          extras.push(extra)
        });

        extraCategory.extras = extras.sort(
            (a, b) => {
              if (a.label > b.label) return 1;
              if (a.label < b.label) return -1;
              return 0
            });

        this.extrasCategories.push(extraCategory)
      });
    }
  },
  watch: {
    selectedExtras: function () {
      this.$emit("extra-selected", this.selectedExtras.toString());
    }
  },
  mounted() {
    this.loadExtrasLabels();
  },
  created() {
    GlobalEventEmitter.$on('localeChanged', () => {
      this.loadExtrasLabels();
    })
  },
  beforeDestroy() {
    GlobalEventEmitter.$off('localeChanged', () => {})
  },
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";

.sec-header {
  border-bottom: 2px solid $border-color;
  margin-top: -4px;
}
</style>
