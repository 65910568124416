import { STORAGE_KEY } from './state'

// for testing
if (navigator.userAgent.indexOf('PhantomJS') > -1) {
  window.localStorage.clear()
}

const localStoragePlugin = store => {
  store.subscribe((mutation, { userInfo, locale }) => {
    window.localStorage.setItem(STORAGE_KEY + '.userInfo', JSON.stringify(userInfo))
    window.localStorage.setItem(STORAGE_KEY + '.locale', locale)
  })
}

export default [localStoragePlugin]