import en from './en'
import es from './es'

let supportedLoacles = [ 'es', 'en' ]

export const dictionary = {
  en,
  es
}

function isSupportedLocale(locale) {
  for (let supportedLocale of supportedLoacles) {
    if (locale === supportedLocale) {
      return true
    }
  }

  return false
}

export function getPreferedLocale() {
  try {
    // TODO: get window.preferedLocale negotiated via JSP

    if (navigator) {
      if (navigator.languages) {
        for (let locale of navigator.languages) {
          if (isSupportedLocale(locale)) {
            return locale
          }
        }
      }

      if (navigator.language) {
        if (isSupportedLocale(navigator.language)) {
          return navigator.language
        }
      }
    }
  } catch (err) {
    // Ignore
  }

  return 'es'
}
