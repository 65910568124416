<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-10">
        <div class="input-group mb-3" v-bind:class="{ open: openSuggestion }">
          <input
              ref="search"
              class="form-control"
              type="text"
              :placeholder="$t('search.simpleSearchText')"
              v-model="searchText"
              @input="updateValue()"
              @keydown.enter.prevent="doSearch()"
              @keydown.down.prevent="down"
              @keydown.up.prevent="up"
              aria-describedby="ccmc_search_simple"
          />

          <button id="ccmc_search_simple"
                  class="btn btn-lg btn-secondary ccmc-header__search-button"
                  type="button"
                  @click="doSearch()"
          >
            {{ $t("commons.search") }}
          </button>

        </div>
        <div class="option-wrapper">
          <ul
              class="dropdown-menu option-list"
              :class="{ show: openSuggestion }"
          >
            <li
                v-for="(objValue, index) in suggestions"
                v-bind:class="{ active: isActive(index) }"
                @click="suggestionClick(objValue)"
                @keydown.enter="suggestionClick(objValue)"
                :key="index"
            >
              <span class="dropdown-item" href="#">{{ objValue }} </span>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { isEmpty } from "@/validations";
import { availableBrandsAndModelsFallback, allMakeLabels, makeIdForLabel } from "@/cardata";

function extractFuel(term) {
  try {
    if (/[iíy]br[iy]d/i.test(term)) {
      return ['HYBRID', term.replace(/h?[iíy]br[iy]d\S*/ig, '')];
    } else if (/el[eé]ctric/i.test(term)) {
      return ['ELECTRIC', term.replace(/el[eé]ctric\S*/ig, '')];
    } else if (/di[eé]?sel/i.test(term)) {
      return ['DIESEL', term.replace(/di[eé]?sel/ig, '')];
    } else if (/gas\s?l|lpg|natural/i.test(term)) {
      return ['LPG', term.replace(/gas\s?l\S*|lpg|natural/ig, '')];
    } else if (/gas|nafta|petrol/i.test(term)) {
      return ['GASOLINE', term.replace(/gas|nafta|petrol/ig, '')];
    }
  } catch (err) {

  }

  return null;
}

function extractTransmission(term) {
  try {
    if (/autom[aá]tic\S*|h?[iy]dr[aá]ulic\S*/i.test(term)) {
      return ['AUTOMATIC', term.replace(/autom[aá]tic\S*|h?[iy]dr[aá]ulic\S*/ig, '')];
    } else if (/manual/i.test(term)) {
      return ['MANUAL', term.replace(/manual/ig, '')];
    } else if (/\S+iptronic|twin|dual/i.test(term)) {
      return ['TIPTRONIC', term.replace(/\S+iptronic|twin|dual/ig, '')];
    }
  } catch (err) {

  }

  return null;
}

function extractYear(term) {
  try {
    const m = /\b((19|20)?\d\d)\b/.exec(term);

    if (m) {
      const nextYear = new Date().getFullYear() + 1;
      let y = parseInt(m[1], 10);

      if (y <= nextYear) {
        if (y < 100) {
          const nextYearShort = nextYear - 2000;

          if (y <= nextYearShort) {
            y += 2000;
          } else {
            y += 1900;
          }
        }

        if (y >= 1950) {
          return [y, term.replace(/\b((19|20)?\d\d)\b/ig, '')];
        }
      }
    }
  } catch (err) {

  }

  return null;
}

const commonColors = [
  "amarillo", "arena", "azul", "beige", "blanco", "bronce", "bronze",
  "cafe", "café", "celeste", "champagne", "claro", "cobre", "crema",
  "dorado", "grafito", "gris", "hueso", "ladrillo", "marron", "mercurio",
  "metalico", "metalizado", "metálico", "morado", "mostaza", "musgo",
  "naranja", "negro", "olivo", "oscuro", "perla", "plata", "plateado",
  "plomo", "rojo", "rosado", "silver", "terracota", "turquesa", "verde",
  "vino"
];

function extractColor(term) {
  try {
    const matches = [];
    let q = term;
    let qLower = q.toLowerCase();

    for (let color of commonColors) {
      if (qLower.includes(color)) {
        matches.push(color);
        q = q.replace(new RegExp(`\\S*${color}\\S*`, 'gi'), '');
        qLower = q.toLowerCase();
      }
    }

    if (matches.length) {
      return [matches.join(' '), q];
    }
  } catch (err) {

  }

  return null;
}

function extractStyle(term) {
  try {
    if (/van/i.test(term)) {
      return ['VAN', term.replace(/van/ig, '')];
    } else if (/panel/i.test(term)) {
      return ['PANEL', term.replace(/panel/ig, '')];
    } else if (/convertible/i.test(term)) {
      return ['CONVERTIBLE', term.replace(/convertible/ig, '')];
    } else if (/cami[oó]n|carga/i.test(term)) {
      return ['CAMION', term.replace(/cami[oó]n|carga/ig, '')];
    } else if (/hatchback/i.test(term)) {
      return ['HATCHBACK', term.replace(/hatchback/ig, '')];
    } else if (/sed[aá]n/i.test(term)) {
      return ['SEDAN', term.replace(/sed[aá]n/ig, '')];
    } else if (/pick.?up/i.test(term)) {
      return ['PICK_UP_4X4', term.replace(/pick.?up/ig, '')];
    } else if (/todo\sterreno|4\s?x\s?4/i.test(term)) {
      return ['TODO_TERRENO_4X4', term.replace(/todo\sterreno|4\s?x\s?4/ig, '')];
    } else if (/4\s?x\s?2/i.test(term)) {
      return ['TODO_TERRENO_4X2', term.replace(/4\s?x\s?2/ig, '')];
    }
  } catch (err) {

  }

  return null;
}

function extractBrand(term) {
  try {
    for (let brand of allMakeLabels()) {
      let tester = new RegExp(brand, 'i');
      if (tester.test(term)) {
        return [makeIdForLabel(brand), term.replace(tester, '')];
      }
    }
  } catch (err) {

  }

  return null;
}

function extractComponents(term) {
  let q = term;
  const components = {
    brand: '',
    model: '',
    fuelType: '',
    transmissionType: '',
    year: '',
    exteriorColor: '',
    style: ''
  };

  const fuelExtract = extractFuel(q);
  if (fuelExtract) {
    [components.fuelType, q] = fuelExtract;
  }

  const transmissionExtract = extractTransmission(q);
  if (transmissionExtract) {
    [components.transmissionType, q] = transmissionExtract;
  }

  const yearExtract = extractYear(q);
  if (yearExtract) {
    [components.year, q] = yearExtract;
  }

  const colorExtract = extractColor(q);
  if (colorExtract) {
    [components.exteriorColor, q] = colorExtract;
  }

  const styleExtract = extractStyle(q);
  if (styleExtract) {
    [components.style, q] = styleExtract;
  }

  const brandExtract = extractBrand(q);
  if (brandExtract) {
    [components.brand, q] = brandExtract;
  }

  const modelExtract = q.replace(/\s+/, ' ').trim();
  if (modelExtract) {
    components.model = modelExtract;
  }

  return components;
}

function quickMatches(term) {
  const termLower = term.toLowerCase();
  const matches = availableBrandsAndModelsFallback()
      .map(e => [e.toLowerCase().indexOf(termLower), e])
      .filter(x => x[0] >= 0)
      .sort((a, b) => a[0] - b[0] || a[1].localeCompare(b[1]))
      .map(x => x[1]);

  if (/.* .* $/.test(term)) {
    const year = new Date().getFullYear();
    matches.unshift(`${term}${year}`);
  }

  if (!matches.length && term.length) {
    matches.push(term);
  }

  return matches.slice(0, 10);
}

export default {
  name: "Autocomplete",
  data() {
    return {
      open: false,
      current: 0,
      searchText: "",
      suggestions: []
    };
  },
  computed: {
    openSuggestion() {
      return this.searchText !== "" && this.open === true;
    }
  },
  methods: {
    doSearch() {
      this.open = false;

      const components = extractComponents(this.searchText);

      this.$emit('query', components);
      this.$emit('querySimpleSearch', components);
    },

    updateValue() {
      if (!isEmpty(this.searchText)) {
        this.suggestions = quickMatches(this.searchText);
      }

      if (this.open === false) {
        this.open = true;
        this.current = 0;
      }
    },

    // When enter pressed on the input
    enter() {
      this.searchText = this.suggestions[this.current];
      this.open = false;
    },

    // When up pressed while suggestions are open
    up() {
      if (this.current > 0) {
        this.current--;
      }
    },

    // When up pressed while suggestions are open
    down() {
      if (this.current < Object.keys(this.suggestions).length - 1) {
        this.current++;
      }
    },

    // For highlighting element
    isActive(index) {
      return index === this.current;
    },

    // When one of the suggestion is clicked
    suggestionClick(suggestionText) {
      this.open = false;
      this.searchText = suggestionText;
      this.$refs.search.focus();
      this.doSearch();
    }
  }
};
</script>