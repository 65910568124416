<template>
  <div :class="{ 'has-error': transmissionTypeError }">
    <select
      id="transmissionType"
      class="ccmc_input form-control"
      v-model="selectedTransmission"
      @change="updateTransmissionType()"
      :title="$t('opportunity.transmission')"
    >
      <option v-if="mode === 'search'" value="">{{ $t("opportunity.transmission") }} {{ $t("commons.any") }}</option>
      <option
        v-for="transmissionType of transmissionTypeList"
        :key="transmissionType.value"
        :value="transmissionType.value"
        >{{ $t(transmissionType.label) }}</option
      >
    </select>
    <span v-if="transmissionTypeError" class="help-block">{{
      $t(transmissionTypeError)
    }}</span>
  </div>
</template>

<script>
import { isEmpty } from "@/validations";

let availableTransmission = [
  { value: "AUTOMATIC", label: "transmissionTypes.automatic" },
  { value: "TIPTRONIC", label: "transmissionTypes.tiptronic" },
  { value: "MANUAL", label: "transmissionTypes.manual" }
];

export default {
  name: "CarTransmission",
  props: {
    value: String,
    attempts: {
      type: Number,
      default: 0
    },
    mode: {
      type: String,
      default: "form" // or 'search'
    }
  },
  data() {
    return {
      transmissionTypeList: availableTransmission,
      selectedTransmission: this.value
    };
  },
  computed: {
    transmissionTypeError() {
      if (this.attempts > 0 && isEmpty(this.value)) {
        return "error-messages.invalidTransmissionType";
      }
    }
  },
  methods: {
    updateTransmissionType() {
      this.$emit("input", this.selectedTransmission);
    }
  }
};
</script>
