<template>
  <div>
    <div class="table-responsive">
      <table class="table table-striped" aria-hidden="true">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('commons.email') }}</th>
            <th scope="col">{{ $t('user.dateCreated') }}</th>
            <th scope="col">{{ $t('user.name') }}</th>
            <th scope="col">{{ $t('user.lastName') }}</th>
            <th scope="col">{{ $t('user.secondLastName') }}</th>
            <th scope="col">{{ $t('user.userRol') }}</th>
            <th scope="col">{{ $t('commons.status') }}</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in users" :key="user.id">
             <th scope="row">{{index + 1}}</th>
            <td>{{user.email}}</td>
            <td>{{formattedDate(user.dateCreated)}}</td>
            <td>{{user.name}}</td>
            <td>{{user.lastName}}</td>
            <td>{{user.lastName2}}</td>
            <td>{{ $t('user.roles.' + user.role) }}</td>
            <td>{{ $t('commons.generalStatus.' + user.status) }}</td>
            <td class="text-right">
              <button type="button" class="btn btn-primary btn-xs" @click="changeSelectedUser(user.id)">
                {{ $t('commons.edit') }}
              </button>
            </td>
            <td class="text-right">
              <button type="button" class="btn btn-primary btn-xs" @click="goToUserOpportunities(user.id)">
                {{ $t('user.maintenance.ownOpportunities') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import {formatIso8601ToShorTime} from "@/helper";

export default {
  name: 'UsersList',
  props: {
    users: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    changeSelectedUser (id) {
      this.$router.push({ 
        name: 'adminUserMaintenance', 
        params: {userId: id }
      })
    },
    goToUserOpportunities (userId) {
      this.$router.push({
        name: 'userOpportunitiesViewAdmin',
        params: {userId: userId }
      })
    },
    formattedDate(dateCreated) {
      return formatIso8601ToShorTime(dateCreated);
    }
  }
}
</script>
