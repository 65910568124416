const makeData = [
  ["TOYOTA", "Toyota", ["Yaris", "Rav4", "Corolla", "Hilux", "Echo", "Hiace", "Hilux SRV", "Land Cruiser", "Tercel", "Tacoma", "Fortuner", "4Runner", "Coaster", "Dyna", "Rush", "Prado", "Prius", "Etios", "Starlet"]],
  ["HYUNDAI", "Hyundai", ["Accent", "Tucson", "Elantra", "Santa Fe", "Accent Blue", "Grand i10", "H1", "Grace", "Starex", "Terracan", "i10", "Creta", "County", "Getz", "Galloper", "Excel", "Porter", "Avante", "Sonata"]],
  ["NISSAN", "Nissan", ["Tiida", "Sentra", "X-Trail", "Versa", "Qashqai", "Frontier", "Pathfinder", "March", "Almera", "Kicks", "XTerra", "D21", "Navarra", "Urvan", "Platina", "200sx", "Murano", "Leaf"]],
  ["SUZUKI", "Suzuki", ["Grand Vitara", "Celerio", "Swift", "Sx4", "Vitara", "Ciaz", "Alto", "Sidekick", "Samurai", "APV", "Ertiga", "Jimny", "Ignis", "Baleno", "Scross", "Dzire"]],
  ["HONDA", "Honda", ["Civic", "CR-V", "Civic EX", "Civic SI", "Civic LX", "Accord", "Pilot", "City", "Odyssey", "Fit", "HRV"]],
  ["KIA", "Kia", ["Sportage", "Rio", "Picanto", "Sorento", "Cerato", "Soul", "K2700", "Bongo III", "Carnival", "Morning", "Forte"]],
  ["CHEVROLET", "Chevrolet", ["Spark", "Tracker", "Aveo", "Optra", "Trax", "Cruze", "Sonic", "Beat", "Captiva"]],
  ["MITSUBISHI", "Mitsubishi", ["Montero Sport", "Montero", "Lancer", "Outlander", "L200", "Mirage", "ASX", "Canter", "Zinger"]],
  ["BMW", "BMW", ["x5", "x1", "318i", "x3", "325i", "x6", "320i", "i3", "525i", "x4"]],
  ["FORD", "Ford", ["Escape", "Explorer", "Edge", "Ranger", "Mustang", "EcoSport"]],
  ["MERCEDES_BENZ", "Mercedes Benz", ["C180", "C250", "GLA180"]],
  ["VOLKSWAGEN", "Volkswagen", ["Jetta", "Tiguan", "Amarok", "CrossFox", "Polo"]],
  ["MAZDA", "Mazda", ["2", "3", "5", "6", "323", "BT50", "CX5", "CX7", "CX9", "CX3", "B2200", "MIATA"]],
  ["ISUZU", "Isuzu", ["DMax", "DMax LS", "NPR", "Rodeo", "Trooper", "NKR", "KB"]],
  ["AUDI", "Audi", ["Q5", "A4", "Q7", "A3", "A5", "A1", "Q3", "A6"]],
  ["LAND_ROVER", "Land Rover", ["Range Rover Sport", "Discovery", "Range Rover", "Freelander"]],
  ["DAIHATSU", "Daihatsu", ["Terios", "Terios BEGO", "Charade", "Sirion"]],
  ["JEEP", "Jeep", ["Grand Cherokee", "Wrangler", "Compass", "Cherokee"]],
  ["FIAT", "Fiat", ["Punto EVO", "Siena", "Fiorino", "Palio"]],
  ["PEUGEOT", "Peugeot", ["Partner", "3008", "206", "207", "208", "301", "307", "308", "407"]],
  ["RENAULT", "Renault", ["Duster", "CLIO", "Sandero Stepway", "Megane"]],
  ["MINI", "Mini", ["Cooper", "Cooper S"]],
  ["SUBARU", "Subaru", ["Impreza", "Forester"]],
  ["DODGE", "Dodge", ["Durango"]],
  ["RAM", "RAM", ["1500", "2500"]],
  ["SSANG_YONG", "Ssang Yong", ["Korando", "Rexton", "Tivoli"]],
  ["BYD", "BYD", ["F3", "F0"]],
  ["PORSCHE", "Porsche", ["Cayenne"]],
  ["GEO", "Geo", ["Tracker"]],
  ["CHANGAN", "Changan", ["CS35"]],
  ["ALFA_ROMEO", "Alfa Romeo", []],
  ["ACURA", "Acura", []],
  ["ASIA", "Asia", []],
  ["ASTON_MARTIN", "Aston Martin", []],
  ["AUSTIN", "Austin", []],
  ["BENTLEY", "Bentley", []],
  ["BLUEBIRD", "Bluebird", []],
  ["BUICK", "Buick", []],
  ["CADILLAC", "Cadillac", []],
  ["CHANA", "Chana", []],
  ["CHERY", "Chery", []],
  ["CHRYSLER", "Chrysler", []],
  ["CITROEN", "Citroen", []],
  ["DAEWOO", "Daewoo", ["Damas", "Espero", "Lanos", "Matiz", "Nubira"]],
  ["DATSUN", "Datsun", []],
  ["EAGLE", "Eagle", []],
  ["FERRARI", "Ferrari", []],
  ["FREIGHTLINER", "Freightliner", []],
  ["GEELY", "Geely", []],
  ["GMC", "GMC", []],
  ["GREAT_WALL", "Great Wall", ["Florid", "Haval", "Voleex", "Wingle"]],
  ["HINO", "Hino", []],
  ["HUMMER", "Hummer", []],
  ["INFINITI", "Infiniti", []],
  ["INTERNATIONAL", "International", []],
  ["JAC", "JAC", []],
  ["JAGUAR", "Jaguar", []],
  ["JMC", "JMC", []],
  ["KENWORTH", "Kenworth", []],
  ["LADA", "Lada", []],
  ["LAMBORGHINI", "Lamborghini", []],
  ["LANCIA", "Lancia", []],
  ["LEXUS", "Lexus", []],
  ["LIFAN", "Lifan", []],
  ["LINCOLN", "Lincoln", []],
  ["LOTUS", "Lotus", []],
  ["MACK", "Mack", []],
  ["MAHINDRA", "Mahindra", []],
  ["MASERATI", "Maserati", []],
  ["MERCURY", "Mercury", []],
  ["MG", "MG", []],
  ["OPEL", "Opel", []],
  ["PLYMOUTH", "Plymouth", []],
  ["PONTIAC", "Pontiac", []],
  ["REVA", "Reva", []],
  ["RIVIAN", "Rivian", []],
  ["ROLLS_ROYCE", "Rolls Royce", []],
  ["ROVER", "Rover", []],
  ["SAMSUNG", "Samsung", []],
  ["SATURN", "Saturn", []],
  ["SCANIA", "Scania", []],
  ["SEAT", "Seat", []],
  ["SKODA", "Skoda", []],
  ["SMART", "Smart", []],
  ["TIANMA", "Tianma", []],
  ["TIGER_TRUCK", "Tiger Truck", []],
  ["VOLVO", "Volvo", []],
  ["WESTERN_STAR", "Western Star", []],
  ["YUGO", "Yugo", []],
  ["AMC", "AMC", []],
  ["BRILLIANCE", "Brilliance", []],
  ["DONFENG", "Donfeng", []],
  ["FAW", "Faw", []],
  ["FOTON", "Foton", []],
  ["GONOW", "Gonow", []],
  ["HAFEI", "Hafei", []],
  ["HIGER", "Higer", []],
  ["IVECO", "Iveco", []],
  ["JIMBEI", "Jinbei", []],
  ["JONWAY", "Jonway", []],
  ["MAXUS", "Maxus", []],
  ["PETERBILT", "Peterbilt", []],
  ["POLARSUN", "Polarsun", []],
  ["PROTON", "Proton", []],
  ["SCION", "Scion", []],
  ["SOUEAST", "Soueast", []],
  ["TESLA", "Tesla", []]
];

let allAvailableBrandsCache = null;
export function allAvailableBrands() {
  if (allAvailableBrandsCache === null) {
    allAvailableBrandsCache = makeData.map(entry => ({
      value: entry[0],
      label: entry[1]
    }));

    allAvailableBrandsCache.sort((a, b) => a.label.localeCompare(b.label));
  }

  return allAvailableBrandsCache;
}

let allMakeLabelsCache = null;
export function allMakeLabels() {
  if (allMakeLabelsCache === null) {
    allMakeLabelsCache = makeData.map(e => e[1]);
  }

  return allMakeLabelsCache;
}

let makeIdForLabelCache = null;
export function makeIdForLabel(label) {
  if (makeIdForLabelCache === null) {
    makeIdForLabelCache = {};

    for (let makeEntry of makeData) {
      makeIdForLabelCache[makeEntry[1].toLowerCase().replace(' ', '')] = makeEntry[0];
    }
  }

  const key = label.toLowerCase().replace(' ', '');
  const value = makeIdForLabelCache[key];
  return value ? value : null;
}

// XXX: Provisional list of car models (for the initial prod stage)
let modelsForMakeCache = null;
export function modelsForMake(make) {
  if (modelsForMakeCache === null) {
    modelsForMakeCache = {};

    for (let makeEntry of makeData) {
       modelsForMakeCache[makeEntry[1]] = makeEntry[2];
    }
  }

  const models = modelsForMakeCache[make];
  return models ? models : [];
}

/*
SELECT distinct brand, model, count(model) as cant
FROM opportunity
group by model
order by brand, cant desc;
*/
let availableBrandsAndModelsFallbackCache = null;
export function availableBrandsAndModelsFallback() {
  if (availableBrandsAndModelsFallbackCache === null) {
    availableBrandsAndModelsFallbackCache = [];

    for (let makeEntry of makeData) {
      availableBrandsAndModelsFallbackCache.push(makeEntry[1]);

      for (let model of makeEntry[2]) {
        availableBrandsAndModelsFallbackCache.push(`${makeEntry[1]} ${model}`);
      }
    }
  }

  return availableBrandsAndModelsFallbackCache;
}
