<template>
  <div class="container">
    <template>
      <loading
        v-show="isRequestInProgress"
        :message="'registration.registingAccount'"
      ></loading>
    </template>
    <div v-show="!isRequestInProgress">
      <h2>{{ $t("impersonalRegistration.title") }}</h2>
      <section class="container white-box">
        <user-data
          :attempts="registerAttempts"
          @user-data="user = $event"
        ></user-data>
        <div v-if="isAdmin" class="row mb-3">
          <div class="col-md-6 col-lg-4">
            <label class="form-label" for="roleForUser">
              {{ $t("user.userRol") }}
            </label>
            <select
                id="roleForUser"
                class="form-control"
                v-model="roleSelectedByAdmin"
            >
              <option
                  v-for="role of availableRoles"
                  :key="role.key"
                  :value="role.key"
              >{{ $t(role.label) }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mb-3" :class="{ 'has-error': emailError }">
          <div class="col text-start">
            <label class="form-label" for="email">{{$t("commons.email")}}</label>
            <input
                type="text"
                id="email"
                class="form-control"
                v-model="email"
                maxlength="64"
            />
            <span v-if="emailError" class="help-block">{{
                $t(emailError, { value: email })
              }}</span>
          </div>
        </div>
        <h3>{{ $t("impersonalRegistration.promotionalPlan") }}</h3>
        <div class="row mb-3">
          <div class="col-md-6 col-lg-4">
            <label class="form-label" for="planSelect">
              {{ $t("plan.label") }}
            </label>
            <select
                id="planSelect"
                class="form-control"
                v-model="plan.planLevel"
            >
              <option value="4">{{ $t("plan.gold.name") }}</option>
              <option value="3">{{ $t("plan.silver.name") }}</option>
              <option value="2">{{ $t("plan.bronze.name") }}</option>
            </select>
          </div>

          <div class="col-md-6 col-lg-4" :class="{ 'has-error': giftedOpportunitiesError }">
            <div class="form-group">
              <label class="form-label" for="impersonalRegistration">{{ $t('impersonalRegistration.giftedOpportunities') }} </label>
              <input class="form-control" id="impersonalRegistration" type="number" min="0" v-model.number="plan.totalOpportunities" />
              <span v-if="giftedOpportunitiesError" class="help-block">{{$t(plan.totalOpportunities)}}</span>
            </div>
          </div>
        </div>
        <div v-if="errorMessage" class="row mt-3 gy-3">
          <p class="text-danger">
            {{ $t(errorMessage) }}
          </p>
        </div>
      </section>
      <section class="button-box">
        <button type="button" class="btn btn-primary" @click="doSave()">
          {{ $t("registration.registerAccount") }}
        </button>
      </section>
    </div>
  </div>
</template>

<script>
import { ApiService } from "@/rest.js";

import { User, Plan } from "@/entities.js";
import UserData from "@/components/user/UserData.vue";
import Loading from "@/components/utilities/Loading.vue";
import {isEmpty, isNumber, validEmail} from "@/validations";
import {mapGetters} from "vuex";

const ROLES = [
    {  key: "ADVERTISER", label: "user.roles.ADVERTISER" },
    {  key: "IMPERSONALIZATOR", label: "user.roles.IMPERSONALIZATOR"},
    {  key: "TESTUSER", label: "user.roles.TESTUSER" },
    {  key: "ADMINISTRATOR", label: "user.roles.ADMINISTRATOR" }
];

export default {
  name: "ImpersonalRegisterView",
  components: {
    Loading,
    UserData
  },
  data() {
    return {
      user: new User(),
      plan: new Plan(),
      updatingUser: new User(),
      registerAttempts: 0,
      errorMessage: "",
      isRequestInProgress: false,
      email: "",
      roleSelectedByAdmin:"ADVERTISER",
      availableRoles: ROLES
    };
  },
  computed:{
    ...mapGetters(["isAdmin"]),
    emailError() {
      if (this.registerAttempts > 0) {
        if (!validEmail(this.email))
          return "error-messages.invalidEmail";
      }
    },
    giftedOpportunitiesError() {
      if (this.registerAttempts > 0) {
        if (isEmpty(this.plan.totalOpportunities) || !isNumber(this.plan.totalOpportunities)) return 'error-messages.invalidNumber'
      }
      return ''
    },
  },
  methods: {
    doSave() {
      this.registerAttempts++;

      if (this.user != null && validEmail(this.email) && isNumber(this.plan.totalOpportunities)) {
        this.isRequestInProgress = true;
        this.errorMessage = "";
        this.user.email = this.email;
        this.user.userRol = this.roleSelectedByAdmin;

        let callback = (err, data) => {
          if (err) {
            this.isRequestInProgress = false;
            this.registerAttempts += 1;
            this.errorMessage = "error-messages.errorOccurred";
          } else {
            this.$router.push("/impersonalRegistrationResult");
          }
        };

        ApiService.POST(
          "/users/impersonalRegister",
          {
            user: this.user,
            plan: this.plan
          },
          callback
        );
      }
    }
  }
};
</script>
