<template>
  <div class="container">
    <div class="white-box">
      <h1 class="mt-4 mb-5">{{ $t("content.sellingUsedCardTips.title") }}</h1>
      <section>
        <h2>{{ $t("content.sellingUsedCardTips.tip1.title") }}</h2>
        <p>{{ $t("content.sellingUsedCardTips.tip1.detail") }}</p>
      </section>
      <section>
        <h2>{{ $t("content.sellingUsedCardTips.tip2.title") }}</h2>
        <p>{{ $t("content.sellingUsedCardTips.tip2.detail") }}</p>
      </section>
      <section>
        <h2>{{ $t("content.sellingUsedCardTips.tip3.title") }}</h2>
        <p>{{ $t("content.sellingUsedCardTips.tip3.detail") }}</p>
      </section>
      <section>
        <h2>{{ $t("content.sellingUsedCardTips.tip4.title") }}</h2>
        <p>{{ $t("content.sellingUsedCardTips.tip4.detail") }}</p>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: "SellingUsedCardTips"
};
</script>