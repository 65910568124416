<template>
  <div class="loading-box text-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="lead">
      {{ $t(message) }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Loading',
    props: {
      message: {
        type: String,
        default: 'commons.loading'
      }
    },
    mounted() {
      window.scrollTo(0,0);
    }
  }

</script>

<style scoped lang="scss">
.loading-box {
  padding-top: 5.5em;
  padding-bottom: 2em;
}
</style>