const nameRegexp = /^([a-zA-Záéíóúñ ]){2,}$/
const cedulaRegexp = /^[1-9][ -]?\d{3,4}[ -]?\d{3,4}$/
const dimexRegexp = /^\d{11,}$/
const merchantNameRegexp = /^([1-9-a-zA-Záéíóúñ ]){2,}$/
const alphanumeric = /^[0-9a-zA-Z -]+$/;
/*
Password validation expression:
  ^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})

Break down and explain:
  ^	The password string will start this way
  (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
  (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
  (?=.*[0-9])	The string must contain at least 1 numeric character
  (?=.*[!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
  (?=.{8,})	The string must be eight characters or longer
*/
const passportRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/;
const phoneRegexp = /^\d{4}[ -]?\d{4}$/
const emailRegexp = /^[^@]+@[^@]+\.[^@]{2,}$/
const numbersOnly = /^\d+$/
const numericTwoDecimal = /^\d+(\.\d{1,2})?$/
const plateRegexp = /^[a-z0-9-]+$/i

// Password related patterns
const trimRegexp = /^\s|\s$/
const complexityRequirements = [
  /[a-z]/,
  /[A-Z]/,
  /[0-9]/,
  /[ !@%#$?:,.'^(){}[\]_~*+-]/
]
const threeInRowRegexp = /(.)\1\1/
const sequences = [
  /012|123|234|345|456|567|678|789|890/i,
  /987|876|765|654|543|432|321/i,
  /abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz/i
]
const unsafeRegexp = /pass|qwerty|qaz/i

export function isEmpty(val) {
  if (typeof val === 'string') {
    return val.trim().length === 0
  }

  return (val === undefined || val === null || val === '')
}

export function validName(val) {
  return nameRegexp.test(val)
}

export function validMerchantName(val) {
  return merchantNameRegexp.test(val)
}

export function validCedula(val) {
  return cedulaRegexp.test(val)
}

export function validDimex(val) {
  return dimexRegexp.test(val)
}

export function validPassport(val) {
  return passportRegexp.test(val)
}

export function validPhone(val) {
  return phoneRegexp.test(val)
}

export function validEmail(val) {
  return emailRegexp.test(val)
}

export function isNumber(val) {
  return numbersOnly.test(val)
}

export function validPrice(val) {
  if (numericTwoDecimal.test(val)) {
    const n = parseFloat(val);

    if (n && n > 0) {
      return true;
    }
  }

  return false;
}

export function validTransferPrice(val) {
  if (numericTwoDecimal.test(val)) {
    const n = parseFloat(val);

    if (n !== null && n !== undefined && n >= 0) {
      return true;
    }
  }

  return false;
}

export function validPlate(val) {
  return plateRegexp.test(val)
}

export function isAlphanumeric(val) {
  return alphanumeric.test(val)
}
export function validPasswordLight(val) {
  // Passwords length must be at least 6 but no longer than 20
  if (val.length < 6) return false
  if (val.length > 20) return false

  // Passwords cannot have leading or trailing spaces
  if (trimRegexp.test(val)) return false

  return true
}

export function validPassword(val) {
  // Passwords length must be at least 6 but no longer than 20
  if (val.length < 6) return false
  if (val.length > 20) return false

  // Passwords cannot have leading or trailing spaces
  if (trimRegexp.test(val)) return false

  let charTypesCount = 0

  for (let req of complexityRequirements) {
    if (req.test(val)) charTypesCount += 1
  }

  // Password must be formed by at least 3 classes of characters:
  // lowercase, uppercase, digits, special charactes
  if (charTypesCount < 3) return false

  // Password cannot have the same letter repeated 3 times in a row
  if (threeInRowRegexp.test(val)) return false

  // Password cannot contain common sequences like 123 or abc
  for (let seq of sequences) {
    if (seq.test(val)) return false
  }

  // Password cannot contain unsafe substrings
  return !unsafeRegexp.test(val);


}
