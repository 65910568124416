<template>
  <div class="container">
    <global-parameters/>
  </div>
</template>

<script>
// @ is an alias to /src
import GlobalParameters from '@/components/administration/configuration/GlobalParameters.vue'

export default {
  name: 'AdminGlobalParameters',
  components: {
    GlobalParameters
  }
}
</script>
