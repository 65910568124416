<template>
  <div class="container">
    <template v-if="isRequestInProgress">
      <loading></loading>
    </template>
    <template v-else>
      <h2>{{ $t('user.userProfile') }}</h2>
      <section class="white-box">
        <h3>{{ $t('user.personalInformation') }}</h3>
        <user-data
           :user="user"
           :attempts="attempts"
           @user-data="handleUser($event)">
        </user-data>
        <div class="row">
          <div class="col-xs-12">
            <a href="#" @click.prevent="changeIsUpdating()">{{ $t('registration.changePassword') }}</a>
          </div>
        </div>
        <h3 v-show="isUpdatingCredentials" >{{ $t('commons.loginInformation') }}</h3>
        <user-credentials v-show="isUpdatingCredentials"
          :credentials="credentials"
          :attempts="attempts"
          :isUpdatingMyProfile="isUpdatingCredentials"
          :disableEmail="true"
          @user-credentials="credentials = $event">
        </user-credentials>
        <correct-data-disclaimer 
          :attempts="attempts"
          @disclaimer-agreed="agreed =$event">
        </correct-data-disclaimer>
      </section>
      <div class="row">
          <p v-if="errorMessage" class="text-danger">
            {{ $t(errorMessage) }}
          </p>
      </div>
      <section class="button-box">
        <button type="button" class="btn btn-primary" @click="doUpdate()">{{ $t('commons.update') }}</button>
      </section>
    </template>
  </div>
</template>

<script>
  import { ApiService } from '@/rest.js'

  import {Credentials, User} from '@/entities.js'
  import UserData from '@/components/user/UserData.vue'
  import UserCredentials from '@/components/user/UserCredentials.vue'
  import CorrectDataDisclaimer from '@/components/user/CorrectDataDisclaimer.vue'
  import Loading from '@/components/utilities/Loading.vue'

  export default {
    name: 'EditMyProfileView',
    components: {
      UserData,
      UserCredentials,
      Loading,
      CorrectDataDisclaimer
    },
    data() {
      return {
        user: new User(),
        updatingUser: new User(),
        credentials: new Credentials(),
        attempts: 0,
        isAgreementChecked: 0,
        errorMessage: '',
        isRequestInProgress: true,
        agreed: false,
        isUpdatingCredentials : false
      }
    },
    methods: {
      loadCurrentUserData() {
        const userId = (this.$store.getters.userInfo) ? (this.$store.getters.userInfo.id) : 0;
        this.isRequestInProgress = true;
        ApiService.GET('/users/protected/' + userId, (err, apiResponse) => {
          if (err) {
            this.errorMessage = 'error-messages.errorOccurred'
          } else {
            this.user = new User(apiResponse)
          }
          this.credentials.email = this.user.email;
          this.isRequestInProgress = false;
        });
      },
      doUpdate() {
        this.attempts++;

        if(this.updatingUser != null && this.agreed) {
          
          this.updatingUser.id = this.user.id;
          this.updatingUser.password = this.credentials.currentPassword;
          this.updatingUser.newPassword = this.credentials.newPassword;

          let callback = (err, data) => {
            this.isRequestInProgress = false;
            if (err) {
              this.errorMessage = 'error-messages.errorOccurred'
            } else {
              this.$router.push('/editMyProfileResult')
            }
          }

          ApiService.PUT('/users/protected/updateMyProfile', this.updatingUser, callback)
          this.isRequestInProgress = true;
        }
      },
      changeIsUpdating() {
        this.isUpdatingCredentials = !this.isUpdatingCredentials;
      },
      handleUser(event) {
          this.updatingUser = event;
      }
    },
    created() {
      this.loadCurrentUserData();
    }
  }
</script>