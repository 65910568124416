import {state} from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import plugins from './plugins';

export default {
    state,
    actions,
    mutations,
    getters,
    plugins
};