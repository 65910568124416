<template>
<fragment>
  <div v-if="isRequestInProgress">
    <loading></loading>
  </div>
  <div v-else>
    <div class="white-box">
      <h3 class="h3">{{ $t('passwordRecovery.title') }}</h3>
      <div class="row mb-3 gy-3">
        <div class="col col-xs-12 col-sm-8" :class="{ 'has-error': userPasswordError }">
          <label class="form-label" for="email">{{$t("passwordRecovery.mainText")}}:</label>
          <input type="text" id="userEmail" class="form-control" v-model="userEmail"
                 :placeholder="$t('commons.email')" maxlength="64"/>
            <span v-if="userPasswordError" class="help-block">{{ $t(userPasswordError, { value: userEmail }) }}</span>
        </div>
      </div>
      <div class="row mb-3 gy-3">
        <p v-if="errorMessage" class="text-danger">
          {{ $t(errorMessage) }}
        </p>
      </div>
      <div class="row mb-3 gy-3">
        <div class="col text-center">
          <form role="form">
            <button @click="requestPasswordRecovery()"
                    id="passwordRecoverySubmit"
                    class="btn btn-lg btn-primary"
                    type="submit">{{ $t('passwordRecovery.submit') }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</fragment>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { ApiService } from '@/rest.js'
  import { validEmail, isEmpty } from '@/validations'
  import Loading from '@/components/utilities/Loading.vue'

  export default {
    name: 'ForgotPassword',
    components: {
      Loading
    }, 
    data() {
      return {
        userEmail: '',
        errorMessage: '',
        attempts: 0,
        isRequestInProgress: false
      }
    },      
    computed: {
      userPasswordError(){
        if (this.attempts > 0 && !this.isFormValid()) {
          return 'error-messages.invalidEmail'
        }

        return ''
      },
      ...mapGetters([
        'locale'
      ])
    },
    methods: {
      isFormValid() {
        
        if(isEmpty(this.userEmail)) return false
        return validEmail(this.userEmail);
      

      },
      requestPasswordRecovery () {
        this.attempts += 1;

        if (this.isFormValid()) {
          this.errorMessage = ''
          const language = this.locale;
          this.isRequestInProgress = true;
          ApiService.POST('/users/passwordResetGenerateEmail/'+this.userEmail+'/'+language, (err, apiResponse) => {
            this.isRequestInProgress = false;
            if (err) {
              this.errorMessage = 'error-messages.errorOccurred'
            } else {
               this.$router.push('forgotPasswordResult')
            }
          })
        }
      }
    }
  }
  </script>
