<template>
  <div>
    <div class="container">
      <opportunity-register
        v-if="loaded"
        :toUpdateOpportunity="opportunity"
        :registerAttempts="attempts"
        :is-building-opportunity="false"
        @opportunity-data="opportunity = $event"
        @invoice-data="invoice = $event"
      />
    </div>
    <section class="button-box">
      <button
        class="btn btn-primary"
        type="button"
        @click="updateOpportunity()"
      >
        <span>{{ $t("commons.update") }}</span>
      </button>
    </section>
  </div>
</template>

<script>
import OpportunityRegister from "@/components/opportunity/OpportunityRegister.vue";
import { ApiService } from "@/rest";

export default {
  name: "OpportunityUpdateView",
  components: {
    OpportunityRegister
  },
  props: {
    opportunityId: {
      type: String
    }
  },
  data() {
    return {
      opportunity: null,
      invoice: null,
      attempts: 0,
      loaded: false
    };
  },
  methods: {
    updateOpportunity() {
      this.attempts++;

      if (!this.opportunity || this.invoice === "error") {
        return;
      }

      ApiService.PUT(
        "/opportunity/protected/updateMyOpportunity",
        {
          opportunity: this.opportunity,
          invoice: this.invoice
        },
        err => {
          if (!err) {
            this.$router.push("/opportunityUpdateResultView");
          }
        }
      );
    }
  },
  mounted() {
    if (this.opportunityId) {
      ApiService.GET(
        "/opportunity/" + this.opportunityId,
        (err, apiResponse) => {
          this.opportunity = apiResponse;
          this.loaded = true;
        }
      );
    }
  }
};
</script>
