<template>
  <div class="container">
    <common-result 
      :primaryText="'success-messages.updateUserByAdmin'" 
      :redirecPath="'/usersMaintenance'" />
  </div>
</template>

<script>
// @ is an alias to /src
import CommonResult from '@/components/common/CommonResult.vue'

export default {
  name: 'AdminUserEditResultView',
  components: {
    CommonResult
  },
  mounted() {
    window.scrollTo(0,0);
  }
}
</script>
