<template>
  <div :class="{ 'has-error': fuelTypeError }">
    <select
      id="fuelType"
      class="ccmc_input form-control"
      :value="value"
      v-model="selectedFuel"
      @change="updateFuel()"
    >
      <option v-if="mode === 'search'" value="" selected>{{ $t("opportunity.fuelType") }} {{ $t("commons.any") }}</option>
      <option
        v-for="fuelType of fuelTypeList"
        :key="fuelType.value"
        :value="fuelType.value"
        >{{ $t(fuelType.label) }}</option
      >
    </select>
    <span v-if="fuelTypeError" class="help-block">{{ $t(fuelTypeError) }}</span>
  </div>
</template>
<script>
import { isEmpty } from "@/validations";

let availableFuels = [
  { value: "GASOLINE", label: "fuelTypes.gasoline" },
  { value: "DIESEL", label: "fuelTypes.diesel" },
  //{ value: "LPG", label: "fuelTypes.lpg" },
  { value: "HYBRID", label: "fuelTypes.hybrid" },
  { value: "ELECTRIC", label: "fuelTypes.electric" }
];

export default {
  name: "CarFuels",
  props: {
    value: String,
    attempts: {
      type: Number,
      default: 0
    },
    mode: {
      type: String,
      default: "form" // or 'search'
    }
  },
  data() {
    return {
      fuelTypeList: availableFuels,
      selectedFuel: this.value
    };
  },
  computed: {
    fuelTypeError() {
      if (this.attempts > 0) {
        if (isEmpty(this.value)) return "error-messages.invalidFuelType";
      }
    }
  },
  methods: {
    updateFuel() {
      this.$emit("input", this.selectedFuel);
    }
  }
};
</script>
