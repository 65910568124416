<template>
  <div>
    <div v-show="isRequestInProgress">
      <loading :message="'payment.loadingPaymentForm'" />
    </div>
    <div v-show="!isRequestInProgress">
    <div class="container">
      <buy-plan
        :registerAttempts="attempts"
        @plan-data="plan = $event"
        @invoice-data="invoice = $event"/>
    </div>
    <section class="button-box">
      <div v-if="attempts > 0 && !this.isValid()" class="alert alert-danger mt-3" role="alert">
        <span>{{ $t("error-messages.opportunityReviewAlert") }} </span>
      </div>
      <button id="ccmc_payment_plan" class="payment__button btn btn-lg btn-primary" type="button" @click="buyPlan()">
        <span>{{ $t('plan.buy.process') }}</span>
      </button>
    </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PlanStatus from '@/components/opportunity/PlanStatus.vue'
import BuyPlan from '@/components/opportunity/BuyPlan.vue'
import {ApiService} from "@/rest";
import Loading from "@/components/utilities/Loading.vue";

export default {
  name: 'BuyPlanView',
  components: {
    Loading,
    PlanStatus,
    BuyPlan
  },
  data() {
    return {
      plan: {
        planLevel: 4,
        totalOpportunities: 10
      },
      invoice: null,
      attempts: 0,
      isRequestInProgress: false
    }
  },
  methods: {
    isValid(){
      return (this.plan)
    },
    buyPlan() {
      this.attempts++;

      if (this.isValid()) {
        this.isRequestInProgress = true;

        ApiService.POST("/plan/protected/plan", {
          plan: this.plan,
          invoice: this.invoice
        }, (err, apiResponse) => {
          if (!err) {
            window.location.replace(apiResponse.redirectUrl + "");
          } else {
            this.isRequestInProgress = false;
          }
        })
        window.scrollTo(0,0);
      }
    }
  },
  mounted() {
    window.scrollTo(0,0);
  }
}
</script>
