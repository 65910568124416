<template>
  <div class="container-fluid">
    <!--First row-->
    <div class="row justify-content-between">
      <div class="col-auto">
        <router-link id="ccmc_header_logo" class="navbar-brand" to="/">
          <img src="../assets/logo-ccmc.png" alt="" class="ccmc-header__logo">
        </router-link>
      </div>
      <div class="col-auto">
        <div>
          <a href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img class="ccmc-header__profile-icon"
                 src="../assets/profile-icon.svg"
                 width="32"
                 height="32"
                 :title="fullName"
            />
          </a>
          <ul v-if="isLogged" class="dropdown-menu">
            <li>
              <span class="profile-fullName">{{ fullName }}</span>
              <hr class="dropdown-divider"/>
            </li>
            <li v-if="!isImpersonalizator">
              <router-link id="ccmc_profile_me" to="/editprofile" class="dropdown-item">
                <em class="bi bi-person-circle"></em>
                {{ $t("user.profile") }}
              </router-link>
            </li>
            <li v-if="!isImpersonalizator">
              <router-link id="ccmc_profile_myAdvertisments" to="/myOpportunities" class="dropdown-item">
                <em class="bi bi-badge-ad"></em>
                {{ $t("mainMenu.myAdvertisements") }}
              </router-link>
            </li>
            <li v-if="!isImpersonalizator">
              <router-link id="ccmc_profile_favorites" to="/myWhishList" class="dropdown-item">
                <em class="bi bi-star"></em>
                {{ $t("mainMenu.bookmarks") }}
              </router-link>
            </li>
            <li v-if="!isImpersonalizator">
              <router-link id="ccmc_profile_myPlans" to="/buyPlan" class="dropdown-item">
                <em class="bi bi-receipt"></em>
                {{ $t("mainMenu.plans") }}
              </router-link>
            </li>
            <li v-if="!isImpersonalizator">
              <hr class="dropdown-divider"/>
            </li>
            <li>
              <a @click="doLogout()" class="dropdown-item">
                <em class="bi bi-box-arrow-right"></em>
                {{ $t("mainMenu.logout") }}
              </a>
            </li>
          </ul>
          <ul v-else class="dropdown-menu">
            <router-link to="/signin" class="dropdown-item">
              <em class="bi bi-box-arrow-in-right"></em>
              {{ $t("mainMenu.signInLogin") }}
            </router-link>
          </ul>
        </div>
      </div>
    </div>

    <!--Second row-->
    <div class="row justify-content-center">
      <div class="col-auto">
        <router-link id="ccmc_menu_home" class="ccmc-header__link" to="/">{{ $t("mainMenu.home") }}
        </router-link>
      </div>
      <div class="col-auto">
        <a id="ccmc_menu_usedCards" href="#" @click.prevent="goToUsedCards()"
           class="ccmc-header__link nav">{{ $t("mainMenu.usedVehicles") }}</a>
      </div>
      <div class="col-auto">
        <a id="ccmc_menu_publishAdvertisement" href="#" @click.prevent="handleRedirectForCreateOpportunity()"
           class="ccmc-header__link">{{ $t("mainMenu.advertisement") }}</a>
      </div>
      <div class="col-auto">
        <a id="ccmc_menu_buyPlan" href="#" @click.prevent="handleRedirectForBuyingPlan()" class="ccmc-header__link">{{
            $t("plan.buy.menuLabel")
          }}</a>
      </div>

      <div v-if="isAdmin || isImpersonalizator" class="col-auto">
        <div class="dropdown">
          <a href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
             class="dropdown-toggle ccmc-header__custom-dropdown">
            {{ $t("mainMenu.admin") }}
          </a>
          <ul class="dropdown-menu">
            <li v-if="isAdmin" class="nav-item dropdown">
              <router-link to="/dashboard" class="dropdown-item">
                <em class="bi bi-speedometer"></em>
                {{ $t("mainMenu.dashboard") }}
              </router-link>
            </li>
            <li v-if="isAdmin">
              <router-link to="/globalParameters" class="dropdown-item">
                <em class="bi bi-globe"></em>
                {{ $t("globalParameters.globalParameters") }}
              </router-link>
            </li>
            <li v-if="isAdmin">
              <router-link
                  to="/opportunityAdministration"
                  class="dropdown-item"
              >
                <em class="bi bi-card-heading"></em>
                {{ $t("mainMenu.opportunitiesAdministration") }}
              </router-link>
            </li>
            <li>
              <router-link to="/usersMaintenance" class="dropdown-item">
                <em class="bi bi-person"></em>
                {{ $t("user.usersMaintenance") }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-auto">
        <div class="header__nav-underline"></div>
      </div>
    </div>

    <hr class="ccmc-header__hr">

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ApiService} from "@/rest.js";
import {AVAILABLE_LOCALES} from '@/languages.js'
import {GlobalEventEmitter} from '@/main.js'
import Autocomplete from '@/components/search/Autocomplete.vue'
import {ccmcRedirect} from "@/helper";

export default {
  name: "PageMenu",
  components: {Autocomplete},
  data() {
    return {
      availableLocales: AVAILABLE_LOCALES
    };
  },
  computed: {
    ...mapGetters(["locale", "fullName", "isAdmin", "isLogged", "isImpersonalizator", "isLogged"])
  },
  methods: {
    changeLanguage(newLocale) {

      this.$store.commit("setLocale", newLocale.code);
      this.$i18n.locale = newLocale.code;

      if (this.isLogged) {
        ApiService.PUT("/users/protected/newLanguage", newLocale.code);
      }

      GlobalEventEmitter.$emit('localeChanged')

    },
    doLogout() {
      ApiService.POST("/users/logout", {}, () => {
        this.$store.commit("clearUserInfo");
        localStorage.removeItem("JWT");
        document.location.href = document.location.href.replace(/#.*/, "#/");
      });
    },
    handleRedirectForCreateOpportunity() {
      if (!this.isLogged) {
        localStorage.setItem('afterLogin', '/opportunityRegister');
        ccmcRedirect(this.$router, "/signin")
        return;
      }
      ccmcRedirect(this.$router, "/opportunityRegister")
    },
    handleRedirectForBuyingPlan() {
      if (!this.isLogged) {
        localStorage.setItem('afterLogin', '/buyPlan');
        ccmcRedirect(this.$router, "/signin")
        return;
      }

      ccmcRedirect(this.$router, "/buyPlan")
    },
    goToUsedCards() {
      ccmcRedirect(this.$router, "/searchingOpportunities")
    }
  }
};
</script>