<template>
  <div v-if="isRequestInProgress">
    <loading></loading>
  </div>
  <div v-else class="white-box">
    <div class="row mt-4">
      <div class="col h2 text-center">
        <p><strong>{{$t(primaryText)}} </strong></p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col h3 text-center">
        <p>{{$t(secondaryText)}}</p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col text-center">
        <form role="form">
          <button @click="goHome()" class="btn btn-lg btn-primary" type="submit">{{$t('payment.result.backButton')}}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import paymentResponse from "@/utils/paymentResponse";
import {ApiService} from "@/rest";
import Loading from "@/components/utilities/Loading.vue";

export default {
  name: 'PaymentResultView',
  components: {
    Loading
  },
  data() {
    return {
      isRequestInProgress : true,
      handledPaymentResponse: '',
      primaryText: 'payment.result.paymentCompleted',
      secondaryText: 'payment.result.clarification',
      buyPlan : '/buyPlan'
    };
  },
  methods: {
    goHome () {
      this.$router.push("/");
    },
    saveResponse() {
      let callback = (err) => {
        if (!err) {
          this.isRequestInProgress = false;
        }
      }

      if(this.handledPaymentResponse == 'cancel'){
          this.isRequestInProgress = false;
          this.primaryText = 'success-messages.canceledPayment'
          this.secondaryText = ''
      } else {
          localStorage.removeItem("buildingOpportunityId");
          ApiService.POST('/payment/paymentResponse/', this.handledPaymentResponse, callback);
      }

    }
  },
  mounted() {
    window.scrollTo(0,0);
    this.saveResponse();
  },
  created() {
    this.handledPaymentResponse = paymentResponse.handlePaymentResponse();
  }
}
</script>
