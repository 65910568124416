<template>
  <div class="container">
    <common-result
      :primaryText="'success-messages.registerOpportunity'"
      :redirecPath="'/myOpportunities'"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import CommonResult from "@/components/common/CommonResult.vue";

export default {
  name: "OpportunityRegisterResultView",
  components: {
    CommonResult
  },
  mounted() {
    window.scrollTo(0,0);
  }
};
</script>
