<template>
  <div class="container">
    <set-new-password :passwordResetToken="passwordResetToken"></set-new-password>
  </div>
</template>

<script>
// @ is an alias to /src
import SetNewPassword from '@/components/user/SetNewPassword.vue'

export default {
  name: 'SetNewPasswordView',
  components: {
    SetNewPassword
  },    
  props: {
      passwordResetToken: {
        type: String,
        default: ''
      }
    },
}
</script>