<template>
  <div id="app">
    <common-header />
    <div id="wrapper">
      <router-view />
    </div>
    <page-footer/>
    <login-dialog ref="loginModal" v-show="!isLogged" />
  </div>
</template>

<script>
// @ is an alias to /src
import CommonHeader from "@/components/CommonHeader.vue";
import LoginDialog from "@/components/user/LoginDialog.vue";
import { mapGetters } from "vuex";
import { initFlags } from "@/utils/featureflag";
import PageFooter from "@/components/PageFooter.vue";

export default {
  components: {
    PageFooter,
    CommonHeader,
    LoginDialog
  },
  computed: {
    ...mapGetters(["isLogged"])
  },
  methods: {
    showLoginDialog() {
      this.$refs.loginModal.showLoginDialog();
    }
  },
  mounted() {
    setTimeout(() => {
      const loaded = localStorage.getItem("PendingLoadReLogin");

      if (loaded && loaded === "true") {
        localStorage.removeItem("PendingLoadReLogin");
        this.showLoginDialog();
      }
    }, 3000);

    this.$root.$on("reLogin", data => {
      this.showLoginDialog();
    });
  },
  created() {
    initFlags("");
  }
};
</script>
