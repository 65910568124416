<template>
  <div class="container-fluid mt-5 h-100">
    <div class="row justify-content-center">
      <div class="col col-md-8">
        <div class="card signin shadow rounded">
          <div class="card-header text-center signin signin__card-header pt-3">
            <h2>{{ $t("signin.tittle") }}</h2>
          </div>
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-8 col-md-6 col-lg-4 text-center">
                <div v-show="!isRequestProcessing">
                  <user-credentials
                      :attempts="signinAttempts"
                      :isSignin="isSignin"
                      @user-credentials="credentials = $event"
                  >
                  </user-credentials>
                  <div class="row">
                    <div class="col text-end">
                      <p class="signin__link">
                        <a href="#" class="signin__link" id="password-recovery" @click.prevent="resetPassword()">
                          {{ $t("registration.forgotPassword") }}
                        </a>
                      </p>
                      <p v-if="signinMessage" class="text-danger">
                        {{ $t(signinMessage) }}
                      </p>
                    </div>
                  </div>

                  <div class="row justify-content-center">
                    <div class="col">
                      <button
                          id="signin"
                          @click="singin()"
                          class="btn btn-md btn-primary ccmc__red-button mt-4 form-control"
                          type="submit"
                      >
                        {{ $t("signin.signin") }}
                      </button>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col mt-5 signin__link">
                      <p>
                        {{ $t("registration.notRegistered") }}?
                        <a href="#" class="signin__link signin__link--bold" id="create-account" @click.prevent="createAccount()">{{
                            $t("registration.createAccount")
                          }}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="isRequestProcessing">
                <loading :message="'registration.login.logining'"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
// @ is an alias to /src
import {ApiService} from "@/rest.js";
import {UserInfo} from "@/entities.js";

import UserCredentials from "@/components/user/UserCredentials.vue";
import Loading from "@/components/utilities/Loading.vue";

export default {
  name: "SigninView",
  components: {
    UserCredentials,
    Loading
  },
  data() {
    return {
      credentials: null,
      isRequestProcessing: false,
      isSignin: true,
      signinAttempts: 0,
      signinMessage: ""
    };
  },
  methods: {
    displayLoading(isRequestProcessing) {
      this.isRequestProcessing = isRequestProcessing;
    },
    singin() {
      this.signinAttempts++;
      this.signinMessage = "";

      if (this.credentials != null) {
        this.isRequestProcessing = true;

        ApiService.POST(
            "/users/login",
            {
              email: this.credentials.email,
              password: this.credentials.currentPassword
            },
            (err, apiResponse) => {
              this.isRequestProcessing = false;
              if (err) {
                if (err.responseText) {
                  this.signinMessage =
                      "error-codes.errorCode_" + err.responseText;
                } else {
                  this.signinMessage = "error-messages.errorOccurred";
                }
              } else {
                localStorage.setItem("JWT", apiResponse.token);

                let userInfo = new UserInfo(apiResponse);
                userInfo.isLogged = true;
                userInfo.isAdmin = userInfo.role == "ADMINISTRATOR";

                this.$store.dispatch("setUserInfo", userInfo);
                this.$i18n.locale = userInfo.language;

                let target = localStorage.getItem('afterLogin', '/opportunityRegister');
                if (target) {
                  localStorage.removeItem('afterLogin');
                } else {
                  target = "/";
                }

                this.$router.push(target);
              }
            }
        );
      }
    },
    createAccount() {
      this.$router.push("register");
    },
    resetPassword() {
      this.$router.push("forgotPassword");
    }
  }
};
</script>
