export default {
    handlePaymentResponse: function() {
        let urlParams = window.location.hash;

        let reducedUrlParams = urlParams.split("?")[1];

        let params = reducedUrlParams.split('&').reduce(function (res, item) {
            let parts = item.split('=');
            res[parts[0]] = parts[1];
            return res;
        }, {});

        return decodeURIComponent(params["responseGP"]).substring(1);
    }
};