<template>
  <div v-if="isRequestInProgress">
    <loading></loading>
  </div>
  <div v-else>
    <template v-if="items.length > 0">
      <div class="table-responsive">
        <table class="table table-striped" aria-hidden="true">
          <thead>
          <tr>
            <td colspan="3"></td>
            <th colspan="6" scope="colgroup" class="th-bg th-bg__opportunities">{{ $t('dashboard.purchases.opportunities') }}</th>
            <th colspan="5" scope="colgroup" class="th-bg th-bg__plans">{{ $t('dashboard.purchases.plans') }}</th>
            <th colspan="3" scope="colgroup"></th>
          </tr>
          <tr>
            <th scope="col"></th>
            <th scope="col">{{ $t('dashboard.purchases.fullName') }}</th>
            <th scope="col" @click="sort('totalPaid')">{{ $t('dashboard.purchases.totalPaid') }}</th>
            <th scope="col" class="th-bg th-bg__opportunities" @click="sort('totalPaid')">{{ $t('dashboard.purchases.totalPaid') }}</th>
            <th scope="col" class="th-bg th-bg__opportunities" @click="sort('totalPurchasesQuantity')">{{ $t('dashboard.purchases.publishedQuantity') }}</th>
            <th scope="col" class="th-bg th-bg__opportunities">{{ $t('dashboard.purchases.goldLevel') }}</th>
            <th scope="col" class="th-bg th-bg__opportunities">{{ $t('dashboard.purchases.silverLevel') }}</th>
            <th scope="col" class="th-bg th-bg__opportunities">{{ $t('dashboard.purchases.bronzeLevel') }}</th>
            <th scope="col" class="th-bg th-bg__opportunities">{{ $t('dashboard.purchases.freeLevel') }}</th>
            <th scope="col" class="th-bg th-bg__plans" @click="sort('totalPaid')">{{ $t('dashboard.purchases.totalPaid') }}</th>
            <th scope="col" class="th-bg th-bg__plans" @click="sort('totalPurchasesQuantity')">{{ $t('dashboard.purchases.purchasedQuantity') }}</th>
            <th scope="col" class="th-bg th-bg__plans">{{ $t('dashboard.purchases.goldLevel') }}</th>
            <th scope="col" class="th-bg th-bg__plans">{{ $t('dashboard.purchases.silverLevel') }}</th>
            <th scope="col" class="th-bg th-bg__plans">{{ $t('dashboard.purchases.bronzeLevel') }}</th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(userPurchase, index) in sortedItems" :key="userPurchase.id">
            <th scope="row">{{index + 1}}</th>
            <td>{{userPurchase.opportunities.userFullName || userPurchase.plans.userFullName}}</td>
            <td>
              <div class="row">
                <div class="col">
                  {{ '₡' }}{{ userPurchase.opportunities.totalPaid + userPurchase.plans.totalPaid | currency }}
                </div>
              </div>
            </td>
            <td>
              <div class="row">
                <div class="col">
                  {{ '₡' }}{{ userPurchase.opportunities.totalPaid | currency }}
                </div>
              </div>
            </td>
            <td>
              <div class="row">
                <div class="col">
                  {{ '₡' }}{{ userPurchase.opportunities.totalPurchasesQuantity | currency }}
                </div>
              </div>
            </td>
            <td>
              <purchases-totals
                  :total-purchased="userPurchase.opportunities.countGoldLevel"
                  :paid-amount="userPurchase.opportunities.amountGoldLevel"
              ></purchases-totals>
            </td>
            <td>
              <purchases-totals
                  :total-purchased="userPurchase.opportunities.countSilverLevel"
                  :paid-amount="userPurchase.opportunities.amountSilverLevel"
              ></purchases-totals>
            </td>
            <td>
              <purchases-totals
                  :total-purchased="userPurchase.opportunities.countBronzeLevel"
                  :paid-amount="userPurchase.opportunities.amountBronzeLevel"
              ></purchases-totals>
            </td>
            <td>
              <purchases-totals
                  :total-purchased="userPurchase.opportunities.countFreeLevel"
              ></purchases-totals>
            </td>
            <td>
              <div class="row">
                <div class="col">
                  {{ '₡' }}{{ userPurchase.plans.totalPaid | currency }}
                </div>
              </div>
            </td>
            <td>
              <div class="row">
                <div class="col">
                  {{ '₡' }}{{ userPurchase.plans.totalPurchasesQuantity | currency }}
                </div>
              </div>
            </td>
            <td>
              <purchases-totals
                  :total-purchased="userPurchase.plans.countGoldLevel"
                  :paid-amount="userPurchase.plans.amountGoldLevel"
              ></purchases-totals>
            </td>
            <td>
              <purchases-totals
                  :total-purchased="userPurchase.plans.countSilverLevel"
                  :paid-amount="userPurchase.plans.amountSilverLevel"
              ></purchases-totals>
            </td>
            <td>
              <purchases-totals
                  :total-purchased="userPurchase.plans.countBronzeLevel"
                  :paid-amount="userPurchase.plans.amountBronzeLevel"
              ></purchases-totals>
            </td>
            <td class="text-right">
              <button type="button" class="btn btn-primary btn-xs" @click="editUser(userPurchase.userId)">
                {{ $t('dashboard.purchases.userData') }}
              </button>
            </td>
            <td class="text-right">
              <button type="button" class="btn btn-primary btn-xs" @click="goToUserOpportunities(userPurchase.userId)">
                {{ $t('dashboard.purchases.userOpportunities') }}
              </button>
            </td>
            <td class="text-right">
              <button type="button" class="btn btn-primary btn-xs" @click="checkUserInvoices(userPurchase.userId)">
                {{ $t('dashboard.purchases.invoices') }}
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else>
      <not-results-found></not-results-found>
    </template>
  </div>
</template>
<script>
import {ApiService} from "@/rest";
import {priceFormatter} from "@/helper";
import PurchasesTotals from "@/components/administration/opportunity/PurchasesTotals.vue";
import NotResultsFound from '@/components/common/NotResultsFound.vue'
import Loading from "@/components/utilities/Loading.vue";

export default {
  name: 'UserPurchases',
  components:{
    PurchasesTotals,
    Loading,
    NotResultsFound
  },
  data() {
    return {
      items: [],
      currentSort:'totalPaid',
      currentSortDir:'asc',
      isRequestInProgress : false
    };
  },
  props: {
    startDate: {
      type: String,
      default: ""
    },
    endDate: {
      type: String,
      default: ""
    }
  },
  computed:{
    sortedItems:function() {
      return this.items.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  },
  methods: {
    sort:function(sortBy) {
      if(sortBy === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = sortBy;
    },
    loadBestUsers() {
      this.isRequestInProgress = true;
      ApiService.GET(
          "/opportunity/protected/userPurchases/" + this.startDate + "/" + this.endDate,
          (err, apiResponse) => {
            this.isRequestInProgress = false;
            if (!err) {
              this.items = apiResponse;
            }
          }
      );
    },
    editUser(id) {
      this.$router.push({
        name: 'adminUserMaintenance',
        params: {userId: id}
      })
    },
    goToUserOpportunities (userId) {
      this.$router.push({
        name: 'userOpportunitiesViewAdmin',
        params: {userId: userId }
      })
    },
    checkUserInvoices(userId) {
      this.$router.push({
        name: 'userInvoices',
        params: {userId: userId }
      })
    },
    formattedAmount(amount) {
      return priceFormatter(amount, "₡");
    }
  },
  created() {
    this.loadBestUsers();
  }
}
</script>
