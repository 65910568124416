<template>
  <div>
    <plan-option
        v-model="tempPlan"
        value="GOLD"
        :target="target"
        :label="$t('plan' + (target === 'plan' ? '.buy' : '') + '.gold.radio')"
        :preamble="$t('plan.gold.preamble')"
        :bullets="[
        $t('plan.gold.p1'),
        $t('plan.gold.p2'),
        $t('plan.gold.p3'),
        $t('plan.gold.p4'),
        $t('plan.gold.p5'),
        $t('plan.gold.p6'),
        $t('plan.gold.p7'),
        ...(target === 'plan' ? [$t('plan.buy.gold.p7')] : [])
      ]"
        :price="target === 'plan' ? this.tempPrices.inPlan.goldLevel : this.tempPrices.single.goldLevel"
        :disabled="disabled"
        :user-plan-info="tempUserPlanInfo"
        :is-collapsed="isCollapsed"
    >
      <div v-if="target === 'opportunity'">
        <label for="cr5">
          {{ $t("opportunity.step4.optionCustom") }}
        </label>
        <div  :class="{ 'has-error': customMsgError }">
          <input
              v-model="tempMessage"
              :disabled="tempPlan !== 'GOLD'"
              type="text"
              class="form-control ccmc_input"
              id="cr5"
              maxlength="56"
              style="max-width: 20rem;"
          />
          <span v-if="customMsgError" class="help-block">{{ $t(customMsgError, { chars: CUSTOM_MESSAGE_LENGTH }) }}</span>
        </div>
      </div>
    </plan-option>
    <plan-option
        v-model="tempPlan"
        value="SILVER"
        :target="target"
        :label="$t('plan' + (target === 'plan' ? '.buy' : '') + '.silver.radio')"
        :bullets="[
        $t('plan.silver.p1'),
        $t('plan.silver.p2'),
        $t('plan.silver.p3'),
        $t('plan.silver.p4'),
        $t('plan.silver.p5'),
        $t('plan.silver.p6')
      ]"
        :price="target === 'plan' ? this.tempPrices.inPlan.silverLevel : this.tempPrices.single.silverLevel"
        :disabled="disabled"
        :user-plan-info="tempUserPlanInfo"
        :is-collapsed="isCollapsed"
    />
    <plan-option
        v-if="target === 'opportunity'"
        v-model="tempPlan"
        value="FREE"
        :target="target"
        :label="$t('plan.free.radio')"
        :bullets="[$t('plan.free.p1'), $t('plan.free.p2'), $t('plan.free.p3')]"
        :price="freePrice"
        :disabled="disabled"
    >
      <div class="form-check">
        <input
            class="form-check-input"
            type="checkbox"
            name="cr"
            id="autoApp"
            value="custom"
            :disabled="tempPlan !== 'FREE' || disabled"
            v-model="tempAutomaticApproval"
        />
        <label class="form-check-label" for="autoApp">
          {{ $t("plan.free.payToApprove", { price: autoAppPrice }) }}
        </label>
      </div>
    </plan-option>
  </div>
</template>

<script>
import PlanOption from "./PlanOption.vue";
import { Prices, UserPlanInfo } from "@/entities";
import {mapGetters} from "vuex";

const PLANS = {
  FREE: 1,
  SILVER: 3,
  GOLD: 4
}

export default {
  name: "OpportunityPlan",
  components: {
    PlanOption
  },
  props: {
    target: {
      type: String,
      default: 'opportunity'
    },
    plan: {
      type: Number,
      default: PLANS.GOLD
    },
    message: {
      type: String,
      default: ""
    },
    automaticApproval: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isCollapsed: {
      type: Boolean,
      default: true
    },
    userPlanInfo: {
      type: Object,
      default: () => new UserPlanInfo()
    },
    prices: {
      type: Object,
      default: () => new Prices()
    },
  },
  data() {
    let plan = "GOLD";

    if (this.plan === 1) {
      plan = 'FREE';
    } else if (this.plan === 3) {
      plan = 'SILVER';
    }

    return {
      tempPlan: plan,
      tempMessage: this.message,
      tempAutomaticApproval: this.automaticApproval,
      CUSTOM_MESSAGE_LENGTH : 56,
      tempPrices: this.prices,
      tempUserPlanInfo: this.userPlanInfo
    };
  },
  computed: {
    ...mapGetters(["isLogged"]),
    autoAppPrice() {
      return this.tempPrices.quickApproval.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    freePrice() {
      return this.tempAutomaticApproval ? this.tempPrices.quickApproval : 0;
    },
    customMsgError() {
      if (this.tempMessage.length >= 56)
        return 'plan.gold.msgHelp'

      return ''
    },
  },
  methods: {
    emitTotal() {
      let total = 0;

      if (this.tempPlan === 'GOLD') {
        total = this.target === 'plan' ? this.prices.inPlan.goldLevel : this.prices.single.goldLevel;
      } else if (this.tempPlan === 'SILVER') {
        total = this.target === 'plan' ? this.prices.inPlan.silverLevel : this.prices.single.silverLevel;
      } else if (this.tempPlan === 'BRONZE') {
        total = this.target === 'plan' ? this.prices.inPlan.bronzeLevel : this.prices.single.bronzeLevel;
      } else if (this.tempPlan === 'FREE') {
        if (this.tempAutomaticApproval) {
          total = this.prices.quickApproval;
        }
      }

      this.$emit("setTotal", total);
    }
  },
  watch: {
    userPlanInfo: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.tempUserPlanInfo = {...newValue};
      }
    },
    prices: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.tempPrices = {...newValue};
      }
    },
    tempPlan(val) {
      this.$emit("setPlan", PLANS[val]);
      this.$emit("setMessage", val === "GOLD" ? this.tempMessage : "");
      this.$emit(
          "setAutomaticApproval",
          val === "FREE" ? this.tempAutomaticApproval : false
      );

      if(this.tempPlan !== "FREE") this.tempAutomaticApproval = false;
      this.emitTotal();
    },
    tempMessage(val) {
      this.$emit("setMessage", val);
    },
    tempAutomaticApproval(val) {
      this.$emit("setAutomaticApproval", val);
      this.emitTotal();
    }
  },
  mounted() {
    this.tempUserPlanInfo = {...this.userPlanInfo};
    this.emitTotal();
  }
};
</script>