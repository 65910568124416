<template>
  <div class="white-box">
    <div class="row mt-4">
      <div class="col h2 text-center">
        <p> <strong>{{ $t('commons.noResultsFound') }} </strong> </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NotResultsFound',
    mounted() {
      window.scrollTo(0,0);
    }
  }
  </script>
