<template>
  <div>
    <banner></banner>
    <gold-opportunities-carousel/>
    <section>
      <div class="container" style="background-color: white">
      <div class="row row-cols-1 justify-content-center text-center home__tips--title">
        <div class="col-12 col-md-10">
          <span class="home__tips--titleText">{{ $t('content.buyingUsedCardTips.titlePart1') }} </span>
          <span class="home__tips--titleRed">{{ $t('content.buyingUsedCardTips.titlePart2') }} </span>
          <span class="home__tips--titleText">{{ $t('content.buyingUsedCardTips.titlePart3') }}</span>
        </div>
      </div>
      <div class="row row-cols-3">
        <div class="col-12 col-md-4">
          <advice
              :filename="'buying-test-drive.png'"
              :title="'content.buyingUsedCardTips.tip1.title'"
              :advice-text="'content.buyingUsedCardTips.tip1.detail'"
          ></advice>
        </div>
        <div class="col-12 col-md-4">
          <advice
              :filename="'buying-appearance.png'"
              :title="'content.buyingUsedCardTips.tip2.title'"
              :advice-text="'content.buyingUsedCardTips.tip2.detail'"
          ></advice>
        </div>
        <div class="col-12 col-md-4">
          <advice
              :filename="'buying-check-engine.png'"
              :title="'content.buyingUsedCardTips.tip3.title'"
              :advice-text="'content.buyingUsedCardTips.tip3.detail'"
          ></advice>
        </div>
      </div>
      <div class="row row-cols-3">
        <div class="col-12 col-md-4">
          <advice
              :filename="'buying-compare.png'"
              :title="'content.buyingUsedCardTips.tip4.title'"
              :advice-text="'content.buyingUsedCardTips.tip4.detail'"
          ></advice>
        </div>
        <div class="col-12 col-md-4">
          <advice
              :filename="'buying-professional-inspection.png'"
              :title="'content.buyingUsedCardTips.tip5.title'"
              :advice-text="'content.buyingUsedCardTips.tip5.detail'"
          ></advice>
        </div>
        <div class="col-12 col-md-4">
          <advice
              :filename="'buying-take-your-time.png'"
              :title="'content.buyingUsedCardTips.tip6.title'"
              :advice-text="'content.buyingUsedCardTips.tip6.detail'"
          ></advice>
        </div>
      </div>
      </div>
    </section>
    <section>
    <div class="container">
      <div class="row row-cols-1 justify-content-center text-center home__tips--title">
        <div class="col-12 col-md-10">
          <span class="home__tips--titleText">{{ $t('content.sellingUsedCardTips.titlePart1') }} </span>
          <span class="home__tips--titleRed">{{ $t('content.sellingUsedCardTips.titlePart2') }} </span>
          <span class="home__tips--titleText">{{ $t('content.sellingUsedCardTips.titlePart3') }}</span>
        </div>
      </div>
      <div class="row row-cols-3">
        <div class="col-12 col-md-4">
          <advice
              :filename="'selling-good-photos.png'"
              :title="'content.sellingUsedCardTips.tip1.title'"
              :advice-text="'content.sellingUsedCardTips.tip1.detail'"
          ></advice>
        </div>
        <div class="col-12 col-md-4">
          <advice
              :filename="'selling-good-price.png'"
              :title="'content.sellingUsedCardTips.tip2.title'"
              :advice-text="'content.sellingUsedCardTips.tip2.detail'"
          ></advice>
        </div>
        <div class="col-12 col-md-4">
          <advice
              :filename="'selling-negotiation.png'"
              :title="'content.sellingUsedCardTips.tip3.title'"
              :advice-text="'content.sellingUsedCardTips.tip3.detail'"
          ></advice>
        </div>
      </div>
      <div class="row row-cols-3">
        <div class="col-12 col-md-4">
          <advice
              :filename="'selling-appearance.png'"
              :title="'content.sellingUsedCardTips.tip4.title'"
              :advice-text="'content.sellingUsedCardTips.tip4.detail'"
          ></advice>
        </div>
      </div>
    </div>
    </section>
  </div>

</template>

<script>
import GoldOpportunitiesCarousel from "@/views/opportunity/GoldOpportunitiesCarousel.vue";
import Banner from "@/components/banner/Banner.vue";
import Advice from "@/components/tips/Advice.vue";

export default {
  name: 'home',
  components: {
    Banner,
    GoldOpportunitiesCarousel,
    Advice
  }
}
</script>
