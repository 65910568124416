<template>
  <div v-if="isRequestInProgress">
    <loading></loading>
  </div>
  <div v-else>
    <form class="form-horizontal" role="form">
      <h2>
        {{ $t('globalParameters.globalParameters') }}
      </h2>
      <div class="white-box">
        <div class="table-responsive">
          <table class="table table-striped" aria-hidden="true">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{{ $t('commons.option') }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(globalParam, index) in globalParametersList" :key="globalParam.id">
                <th scope="row">{{index + 1}}</th>
                <td>{{ $t(globalParam.label) }} </td>
                <td>
                  <div class="col-lg-2">
                    <div class="input-group">
                      <input type="number" :ref="'globalParam_' + globalParam.id" :value="globalParam.quantity" @change="changeValue(globalParam.id)"
                        min="1" max="100">
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="button-box">
        <button class="btn btn-primary" type="button" @click="updateGlobalParameter()">
          {{ $t('commons.update') }}
        </button>
      </div>
      <div v-if="errorMessage" class="form-group has-error">
        <p class="help-block">{{ $t(errorMessage) }}</p>
      </div>
    </form>
  </div>
</template>
<script>
import { ApiService } from '@/rest.js'
import Loading from "@/components/utilities/Loading.vue";

export default {
  name: 'GlobalParameters',
  components: {
    Loading
  },
  data() {
    return {
      globalParametersList: [],
      changedGlobalParametersList: [],
      errorMessage: "",
      isRequestInProgress: false
    }
  },
  methods: {
    loadGlobalParameters() {
      this.isRequestInProgress = true;
      ApiService.GET('/globalParameter/protected', (err, apiResponse) => {
        if (!err) {
          this.globalParametersList = apiResponse
        }
        this.isRequestInProgress = false;
      })
    },
    changeValue(id) {
      let alreadyChanged = false

      for (let changedGlobalParameter of this.changedGlobalParametersList) {
        if (changedGlobalParameter.id == id) {
          changedGlobalParameter.quantity = this.$refs["globalParam_" + id][0].value
          alreadyChanged = true
          break
        }
      }

      if (!alreadyChanged) {
        for (let globalParameter of this.globalParametersList) {
          if (globalParameter.id == id) {
            globalParameter.quantity = this.$refs["globalParam_" + id][0].value
            this.changedGlobalParametersList.push(globalParameter)
            break
          }
        }
      }
    },
    updateGlobalParameter() {
      this.isRequestInProgress = true;
       ApiService.PUT(
          "/globalParameter/protected",
                  this.changedGlobalParametersList,
          (err, apiResponse) => {
            this.isRequestInProgress = false;
            if (err) {
              this.errorMessage = "error-messages.errorOccurred";
            } else {
              this.$router.push("/globalParametersEditResult");
            }
          }
      );
    },
    resetPassword() {
      this.errorMessage = "";
      this.attempts++;

      if (this.validateForm()) {
        this.isRequestInProgress = true;
        ApiService.POST(
            "/users/passwordReset",
            {
              token: this.passwordResetToken,
              password: this.newPassword
            },
            (err, apiResponse) => {
              this.isRequestInProgress = false;
              if (err) {
                this.errorMessage = "error-messages.errorOccurred";
              } else {
                this.$router.push("/setNewPasswordResult");
              }
            }
        );
      }
    }
  },
  created() {
    this.loadGlobalParameters();
  }
}
</script>
