<template>
  <div class="container">
    <template v-if="isRequestInProgress">
      <loading></loading>
    </template>
    <template v-else>
      <h2>{{ $t("mainMenu.myAdvertisements") }}</h2>
      <template v-if="!isEditing">
        <template v-if="localPaginatedItems.items && localPaginatedItems.items.length > 0">
          <opportunity-list
              :paginatedItems="localPaginatedItems"
              :getOpportunities="loadPageItems"
              :isEditableList="true"
              :sendToEdit="sendToEdit"
              :isOwner="true"
          />
        </template>
        <template v-else>
          <not-results-found></not-results-found>
        </template>
      </template>
      <template v-else>
        <opportunity-register
            :toUpdateOpportunity="toUpdateOpportunity"
            :isEditing="isEditing"
        />
      </template>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import { ApiService } from "@/rest.js";
import OpportunityList from "@/components/opportunity/OpportunityList.vue";
import { PaginatedItems, Opportunity } from "@/entities.js";
import OpportunityRegister from "@/components/opportunity/OpportunityRegister.vue";
import Loading from '@/components/utilities/Loading.vue'
import NotResultsFound from '@/components/common/NotResultsFound.vue'

export default {
  name: "MyOpportunitiesView",
  components: {
    OpportunityList,
    OpportunityRegister,
    Loading,
    NotResultsFound
  },
  data() {
    return {
      localPaginatedItems: new PaginatedItems(),
      currentPage: 1,
      toUpdateOpportunity: new Opportunity(),
      isEditing: false,
      isRequestInProgress : false
    };
  },
  methods: {
    loadPageItems(pageNumber) {
      this.currentPage = pageNumber;
      this.isRequestInProgress = true;
      ApiService.GET(
        "/opportunity/protected/myOpportunities/" + this.currentPage,
        (err, apiResponse) => {
          this.isRequestInProgress = false;
          if (!err) this.localPaginatedItems = apiResponse;
        }
      );
    },
    sendToEdit(editingOpportunity) {
      this.isEditing = true;
      this.toUpdateOpportunity = editingOpportunity;
    }
  },
  created() {
    this.loadPageItems(this.currentPage);
  }
};
</script>
