<template>
  <div>
    <div class="container mt-4">
      <opportunity-detail
        v-if="opportunityToRender"
        mode="full"
        :opportunity="opportunityToRender"
      />
    </div>
  </div>
</template>

<script>
import OpportunityDetail from "@/components/opportunity/OpportunityDetail.vue";
import { ApiService } from "@/rest";

export default {
  name: "OpportunityDetailView",
  components: {
    OpportunityDetail
  },
  props: {
    opportunity: {
      type: Object
    },
    opportunityId: {
      type: String
    }
  },
  data() {
    return {
      opportunityToRender: this.opportunity,
      attempts: 0
    };
  },
  methods: {

  },
  mounted() {
    if (!this.opportunity && this.opportunityId) {
      ApiService.GET(
        "/opportunity/" + this.opportunityId,
        (err, apiResponse) => {
          this.opportunityToRender = apiResponse;
        }
      );
    }
  }
};
</script>
