<template>
  <div :class="{ 'has-error': brandError }">
    <select
      id="brand"
      class="ccmc_input form-control"
      :value="value"
      v-model="selectedBrand"
      @change="updateBrand()"
    >
      <option v-if="mode === 'search'" value="">{{ $t('opportunity.brand') }}  {{ $t("commons.any") }}</option>
      <option v-else value="" disabled>{{ $t('opportunity.brand') }} {{ $t("commons.asterisk") }}</option>
      <option v-for="brand in brands" :key="brand.value" :value="brand.value">{{
        brand.label
      }}</option>
    </select>
    <span v-if="brandError" class="help-block">{{ $t(brandError) }}</span>
  </div>
</template>
<script>
import { isEmpty } from "@/validations";
import { allAvailableBrands } from "@/cardata";

export default {
  name: "CarBrands",
  props: {
    value: String,
    attempts: {
      type: Number,
      default: 0
    },
    mode: {
      type: String,
      default: "form" // or 'search'
    }
  },
  data() {
    return {
      brands: allAvailableBrands(),
      selectedBrand: this.value
    };
  },
  computed: {
    brandError() {
      if (this.mode === "form" && this.attempts > 0) {
        if (isEmpty(this.value)) return "error-messages.invalidBrand";
      }
    }
  },
  methods: {
    updateBrand() {
      this.$emit("input", this.selectedBrand);
    }
  }
};
</script>
