<template>
<fragment>
  <div class="row mb-3 gy-3">
    <div class="col col-md-6 col-lg-4" :class="{ 'has-error': userTypesError }">
      <label class="form-label" for="userType">{{ $t('user.userType') }}</label>
      <select id="userType" class="form-control"
        :value="newUser.userType" v-model="newUser.userType">
        <option v-for="type of userTypes" :key="type.value" :value="type.value">{{ $t(type.label) }}</option>
      </select>
      <span v-if="userTypesError" class="help-block">{{ $t(userTypesError) }}</span>
    </div>
    <div v-show="isMerchant" class="col col-md-6 col-lg-4" :class="{ 'has-error': merchantNameError }">
      <label class="form-label" for="merchantName">{{ $t('user.merchantName') }}</label>
      <input type="text" id="merchantName" class="form-control" v-model="newUser.merchantName"/>
      <span v-if="merchantNameError" class="help-block">{{ $t(merchantNameError, { value: newUser.merchantName }) }}</span>
    </div>
  </div>
  <div class="row mb-3 gy-3">
    <div class="col col-md-6 col-lg-4" :class="{ 'has-error': nameError }">
      <label class="form-label" for="name">{{ $t(getNameLabel) }}</label>
      <input type="text" id="name" class="form-control" v-model="newUser.name"/>
      <span v-if="nameError" class="help-block">{{ $t(nameError, { value: newUser.name }) }}</span>
    </div>
    <div class="col col-md-6 col-lg-4">
      <label class="form-label" for="lastName">{{ $t(getLastNameLabel) }}</label>
      <input type="text" id="lastName" class="form-control" v-model="newUser.lastName"/>
    </div>
    <div class="col col-md-6 col-lg-4">
      <label class="form-label" for="lastName2">{{ $t(getSecondLastNameLabel) }}</label>
      <input type="text" id="lastName2" class="form-control" v-model="newUser.lastName2"/>
    </div>
  </div>
  <div class="row mb-3 gy-3">
    <div class="col col-md-6 col-lg-4" :class="{ 'has-error': idTypeError }">
      <label class="form-label" for="identificationType"> {{ $t('identificationTypes.title') }} </label>
      <select class="form-control" id="identificationType" v-model="newUser.idType">
        <option v-for="identType of idTypeList" :key="identType.value" :value="identType.value">{{ $t(identType.label) }}</option>
      </select>
      <span v-if="idTypeError" class="help-block">{{ $t(idTypeError, { value: newUser.idType }) }}</span>
    </div>
    <div class="col col-md-6 col-lg-4" :class="{ 'has-error': identificationError }">
      <label class="form-label" for="identification">{{ $t('user.idNumber') }}</label>
      <input type="text" id="identification" class="form-control" v-model="newUser.identification"
        :disabled="!newUser.idType"/>
      <span v-if="identificationError" class="help-block">{{ $t(identificationError, { value: newUser.identification }) }}</span>
    </div>
    <div class="col col-md-6 col-lg-4" :class="{ 'has-error': languageError }">
      <label class="form-label" for="language">{{ $t('user.language') }}</label>
      <select class="form-control" id="language" v-model="newUser.language">
        <option v-for="locale of availableLocales" :key="locale.code" :value="locale.code">{{ locale.name }}</option>
      </select>
      <span v-if="identificationError" class="help-block">{{ $t(languageError, { value: newUser.language }) }}</span>
    </div>
  </div>
  <locations-costa-rica
      :locationsCostaRica="locationsCR"
      :attempts="attempts"
      @locations-CR="handleLocationsCr($event)"
  ></locations-costa-rica>
  <phones
      :phoneList="newUser.phoneList"
      :attempts="attempts"
      @phone-list="newUser.phoneList = $event">
  </phones>
  <div class="row gy-3">
    <div class="alert alert-info" role="alert">
      <span>{{ $t("registration.phonesDisplayedWithOpportunities") }} </span>
    </div>
  </div>
</fragment>
</template>
<script>
  import { User, LocationsCR } from '@/entities.js'
  import { validName, validMerchantName, validEmail, validPasswordLight, isEmpty, validCedula, validDimex } from '@/validations'
  import Loading from '@/components/utilities/Loading.vue'
  import LocationsCostaRica from '@/components/utilities/LocationsCostaRica.vue'
  import Phones from '@/components/utilities/Phones.vue';
  import { AVAILABLE_LOCALES } from '@/languages.js'

  let availableUserTypes = [
    {"value": "PERSON", "label": "sellerTypes.individual"},
    {"value": "CARS_SELLER_MERCHANT", "label": "sellerTypes.carsSellerMerchant"}
  ];

  let availableIdTypes = [
    {"value":"NATIONAL_ID","label":"identificationTypes.nationalId"},
    {"value":"RESIDENCE_ID","label":"identificationTypes.residenceId"}
  ];

  export default {
    name: 'UserData',
    components: {
      Loading,
      LocationsCostaRica,
      Phones
    },
    props: {
      user: {
        type: Object,
        default: () => new User()
      },
      attempts: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        userTypes: availableUserTypes,
        newUser: this.user !== undefined ? this.user : new User(),
        idTypeList: availableIdTypes,
        locationsCR: new LocationsCR({ province : this.user.province, canton : this.user.canton, district : this.user.district }),
        availableLocales: AVAILABLE_LOCALES
      }
    },
    computed: {
      userTypesError() {
        if (this.attempts > 0)
          if (isEmpty(this.newUser.userType)) return 'error-messages.invalidUserType'
      },
      nameError() {
        if (this.attempts > 0) {
          if (!validName(this.newUser.name)) return 'error-messages.invalidNameUser'
        }

        return ''
      },
      merchantNameError() {
        if (this.attempts > 0) {
          if (!validMerchantName(this.newUser.merchantName)) return 'error-messages.invalidMerchantNameUser'
        }

        return ''
      },
      registerEmailError() {
        if (this.attempts > 0) {
          if (!validEmail(this.newUser.email)) return 'error-messages.invalidEmail'
        }

        return ''
      },
      registerPasswordError() {
        if (this.attempts > 0) {
          if (!validPasswordLight(this.newUser.password)) return 'error-messages.invalidPassword'
        }

        return ''
      },
      idTypeError() {
        if (this.attempts > 0 && isEmpty(this.newUser.idType)) {
          return 'error-messages.invalidIdType'
      }
        return ''
      },
      identificationError() {
        if (this.attempts > 0 && this.newUser.identification.length > 0) {
          if (this.newUser.idType === 'NATIONAL_ID') {
            if (!validCedula(this.newUser.identification)) return 'error-messages.invalidIdentification'
          } else  if (this.newUser.idType === 'RESIDENCE_ID') {
            if (!validDimex(this.newUser.identification)) return 'error-messages.invalidIdentification'
          }
        }

        return ''
      },
      languageError() {
        if (this.attempts > 0) {
          if (this.newUser.language === '')
             return 'error-messages.invalidLanguage'
        }
        return ''
      },
      isMerchant() {
          return this.newUser.userType == 'CARS_SELLER_MERCHANT'
      },
      getNameLabel() {
          return this.isMerchant ? 'user.contactName' : 'user.name'
      },
      getLastNameLabel() {
          return this.isMerchant ? 'user.contactLastName' : 'user.lastName'
      },
      getSecondLastNameLabel() {
          return this.isMerchant ? 'user.contactLastName2' : 'user.secondLastName'
      }
    },
    watch: {
      newUser: {
        deep: true,
        handler() {
          this.emitUserData();
        }
      }
    },
    methods: {
      emitUserData() {
        if (this.validateUserForm()) {
          this.$emit('user-data', this.newUser)
        } else {
          this.$emit('user-data', null)
        }
      },
      handleLocationsCr(locationsCostaRica) {
        this.newUser.province = locationsCostaRica.province;
        this.newUser.canton = locationsCostaRica.canton;
        this.newUser.district = locationsCostaRica.district;
        this.emitUserData();
      },
      validateUserForm() {
        if (isEmpty(this.newUser.userType)) return false
        if (this.newUser.userType == this.userTypes[1].value){
          if (!validMerchantName(this.newUser.merchantName)) return false
        }
        if (!validName(this.newUser.name)) return false
        if (!this.isValidIdType()) return false
        if (!this.newUser.phoneList || this.newUser.phoneList.length === 0) return false

        return true
      },
      isValidIdType() {

        if (isEmpty(this.newUser.idType)) return false 

        if (this.newUser.idType === 'NATIONAL_ID' && !isEmpty(this.newUser.identification)) {
          if (!validCedula(this.newUser.identification)) return false
        } 
        
        if (this.newUser.idType === 'RESIDENCE_ID' && !isEmpty(this.newUser.identification)) {
          if (!validDimex(this.newUser.identification)) return false
        }

        return true
      },
    }, mounted(){
      this.newUser.language = this.$store.getters.locale;
    }
  }
</script>
