<template>
  <div class="container">
    <div v-if="isRequestInProgress">
      <loading></loading>
    </div>
    <div v-else>
      <h2>{{ $t('commons.status') }}</h2>
        <div class="row mb-3 gy-3">
          <select id="statusSelect" class="form-control"
                  v-model="selectedStatus"
                  @change="getOpportunities()">
            <option v-for="status of opportunitiesStatus" :key="status.value"
                    :value="status.value">{{ $t(status.label) }}</option>
          </select>
        </div>
        <template v-if="!isRequestInProgress && resultPaginatedItems.items && resultPaginatedItems.items.length > 0">
          <h3 v-if="displayQuantity">{{ $t('commons.total') }}: {{this.resultPaginatedItems.items.length}} </h3>
          <div class="row mb-3 gy-3">
            <opportunity-list
                :paginatedItems="resultPaginatedItems"
                @loadNewPageData="getNewPageData($event)"
                ref="listComponent"
            />
          </div>
        </template>
        <template v-else>
          <not-results-found></not-results-found>
        </template>
    </div>
  </div>
</template>

<script>
let statusList = [
  {"value":"PUBLIC_ONLINE","label":"opportunityStatus.PUBLIC_ONLINE"},
  {"value": "PENDING_APPROVAL", "label": "opportunityStatus.PENDING_APPROVAL"},
  {"value": "APPROVED", "label": "opportunityStatus.APPROVED"},
  {"value": "MODIFIED", "label": "opportunityStatus.MODIFIED"},
  {"value": "REJECTED", "label": "opportunityStatus.REJECTED"},
  {"value": "SOLD", "label": "opportunityStatus.SOLD"},
  {"value": "EXPIRED", "label": "opportunityStatus.EXPIRED"},
  {"value": "RENEWED", "label": "opportunityStatus.RENEWED"}
];

// @ is an alias to /src
import { ApiService } from "@/rest";
import { Opportunity, PaginatedItems } from "@/entities";
import OpportunityList from '@/components/opportunity/OpportunityList.vue'
import Loading from '@/components/utilities/Loading.vue'
import NotResultsFound from '@/components/common/NotResultsFound.vue'

export default {
  name: 'OpportunityAdministrationView',
  components: {
    OpportunityList,
    Loading,
    NotResultsFound
  },
  data() {
    return {
      opportunitiesStatus: statusList,
      selectedStatus: "",
      opportunity : new Opportunity(),
      resultPaginatedItems : new PaginatedItems(),
      currentPageNumber : 1,
      isRequestInProgress : false
    };
  },
  computed: {
    displayQuantity() {
      if(this.resultPaginatedItems && this.resultPaginatedItems.items && this.resultPaginatedItems.items.length > 0){
        return true;
      }

      return false;
    }
  },
	methods: {
    getNewPageData(pageNumber) {
      this.currentPageNumber = pageNumber;
      this.getOpportunities(pageNumber);
    },
    getOpportunities() {
      this.isRequestInProgress = true;
      ApiService.GET('/opportunity/protected/'+this.selectedStatus+'/'+ this.currentPageNumber, (err, apiResponse) => {
        if (!err) {
          this.resultPaginatedItems = apiResponse
        }
        this.isRequestInProgress = false;
      }
      )
    }
	}
}
</script>