import { CONTEXT_PATH, API_URL } from "@/constants.js";

/**
 * REST API Service
 */
const ApiService = (function() {
  const apiRestURL = CONTEXT_PATH + API_URL;
  const INVALID_TOKEN = "0003";

  function serialize(obj) {
    let str = [];

    for (let p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }

    return str.join("&");
  }

  function getApiRestURL(resource) {
    let parts = /^(!?)(.*)$/.exec(resource);
    let url;

    if (parts[1] === "!") {
      url = CONTEXT_PATH + parts[2];
    } else {
      url = apiRestURL + resource;
    }

    // TODO: define backend URL as a single string
    if (process.env.NODE_ENV === "development") {
      url = "http://localhost:8080" + url;
    }

    return url;
  }

  function send(config, callback) {
    let wrapper = function(err, data) {
      if (callback) {
        callback(err, data);
      }

      //window.console && console.log("REST_API", config, err, data)
    };

    const jwtToken = localStorage.getItem("JWT");

    if(isProtectedUrl(config)) {
      if(!jwtToken){
        reLogin()
        return;
      }
    }

    config.beforeSend = function(xhr) {
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + jwtToken
      );
    };

    $.ajax(config).then(
      function(data, textStatus, jqXHR) {
        wrapper(null, data);
      },
      function(jqXHR, textStatus, errorThrown) {
        if (jqXHR.responseText === INVALID_TOKEN) {
          reLogin()
        }
        wrapper(jqXHR, errorThrown);
      }
    );
  }

  function isProtectedUrl(config) {
    return config.url.includes("/protected");
  }

  function reLogin() {
    try {
      window.app.$root.$emit("reLogin");
    } catch (ex) {
      localStorage.setItem("PendingLoadReLogin", "true")
    }
  }

  function DELETE(resource, params, callback) {
    const config = {
      method: "DELETE",
      url: getApiRestURL(resource),
      contentType: "application/json"
    };

    if (typeof params === "function") {
      callback = params;
      params = undefined;
    }

    if (params !== undefined && params !== null) {
      if (typeof params !== "string") {
        params = JSON.stringify(params);
      }

      config.data = params;
    }

    send(config, callback);
  }

  function GET(resource, params, callback) {
    const config = {
      url: getApiRestURL(resource)
    };

    if (typeof params === "function") {
      callback = params;
      params = undefined;
    }

    if (params !== undefined && params !== null) {
      config.data = params;
    }

    send(config, callback);
  }

  function POST(resource, params, callback) {
    const config = {
      method: "POST",
      url: getApiRestURL(resource),
      contentType: "application/json"
    };

    if (typeof params === "function") {
      callback = params;
      params = undefined;
    }

    if (params !== undefined && params !== null) {
      if (typeof params !== "string") {
        params = JSON.stringify(params);
      }

      config.data = params;
    }

    send(config, callback);
  }

  function PUT(resource, params, callback) {
    const config = {
      method: "PUT",
      url: getApiRestURL(resource),
      contentType: "application/json"
    };

    if (typeof params === "function") {
      callback = params;
      params = undefined;
    }

    if (params !== undefined && params !== null) {
      if (typeof params !== "string") {
        params = JSON.stringify(params);
      }

      config.data = params;
    }

    send(config, callback);
  }

  function SUBMIT(resource, params, callback) {
    const config = {
      method: "POST",
      url: getApiRestURL(resource)
    };

    if (typeof params === "function") {
      callback = params;
      params = undefined;
    }

    if (params !== undefined && params !== null) {
      if (typeof params !== "string") {
        params = serialize(params);
      }

      config.data = params;
    }

    send(config, callback);
  }

  function UPLOAD(resource, params, callback) {
    const config = {
      method: "POST",
      url: getApiRestURL(resource),
      processData: false,
      contentType: false
    };

    config.data = params;

    send(config, callback);
  }

  function getImageURL(id, thumbnail) {
    let url = getApiRestURL("/attachment");

    url += "/" + id;

    if (thumbnail) url += "?thumb=true";

    return url;
  }

  return {
    SUCCESS: "Success",
    DELETE: DELETE,
    GET: GET,
    POST: POST,
    PUT: PUT,
    SUBMIT: SUBMIT,
    UPLOAD: UPLOAD,
    getImageURL: getImageURL
  };
})();

export { ApiService };
