<template>
  <section class="pt-2">
    <div class="gold-carousel">
      <div class="gold-carousel__body container-fluid">
        <h2 class="mt-2 gold-carousel--title">{{ $t("commons.goldOpportunities") }}</h2>
        <div
            id="carousel-container"
            class="carousel slide"
        >
          <div class="carousel-inner">
            <template v-for="(opportunityList, index) in splitCarouselItems">
              <carousel-items
                  :opportunities="opportunityList"
                  v-bind:isActive="index === 0"
                  @showOpportunityDetail="displayDetail"
                  v-bind:key="index"
                  :maxOppsPerRow="maxOppsPerRow"
              ></carousel-items>
            </template>
          </div>
        </div>
      </div>
      <div class="gold-carousel__arroy gold-carousel__body gold-carousel__arroy--prev">
        <button
            class="btn btn-outline-primary border-0 py-0 px-2 fs-1"
            style="color: white"
            data-bs-target="#carousel-container"
            role="button"
            data-bs-slide="prev"
        >
          <em class="bi bi-arrow-left"></em>
        </button>
      </div>
      <div class="gold-carousel__arroy gold-carousel__body gold-carousel__arroy--next">
        <button
            class="btn btn-outline-primary border-0 py-0 px-2 fs-1"
            style="color: white"
            data-bs-target="#carousel-container"
            role="button"
            data-bs-slide="next"
        >
          <em class="bi bi-arrow-right"></em>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { ApiService } from "@/rest.js";
import OpportunityMiniature from "@/components/opportunity/OpportunityMiniature.vue";
import OpportunityDetail from "@/components/opportunity/OpportunityDetail.vue";
import CarouselItems from "@/components/opportunity/CarouselItems.vue";

export default {
  name: "GoldOpportunitiesCarousel",
  components: {
    OpportunityMiniature,
    OpportunityDetail,
    CarouselItems
  },
  data() {
    return {
      goldOpportunities: [],
      maxOppsPerRow: 4
    };
  },
  computed: {
    splitCarouselItems() {
      let copy = this.goldOpportunities.slice(0);
      let results = [];

      while (copy.length) {
        results.push(copy.splice(0, this.maxOppsPerRow));
      }

      return results;
    }
  },
  methods: {
    populateCarousel() {
      ApiService.GET("/opportunity/homeCarousel", (err, apiResponse) => {
        if (!err) {
          this.goldOpportunities = apiResponse;
        }
      });
    },
    displayDetail(opportunity) {
      this.$router.push("/opportunity/" + opportunity.id);
    },
    adjustWidth() {
      this.maxOppsPerRow = Math.min(
          Math.max(Math.round(window.innerWidth / 350), 1),
          4
      );
    }
  },
  created() {
    this.populateCarousel();
    window.addEventListener("resize", this.adjustWidth);
  },
  mounted() {
    this.adjustWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.adjustWidth);
  }
};
</script>