<template>
  <div class="container">
    <div>
      <h2>
        {{ $t('opportunityStatusHistory.title') }} : {{this.opportunityId}}
      </h2>
      <div class="white-box">
        <opportunity-status-list :statusHistory="statusList"/>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { ApiService } from '@/rest.js'
import OpportunityStatusList from "@/components/administration/opportunity/OpportunityStatusList"

export default {
  name: 'OpportunityStatusHistoryView',
  components: {
    OpportunityStatusList
  },
  props: {
    opportunityId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      statusList: []
    };
  },
  methods: {
    loadHistory(id) {
      ApiService.GET('/opportunity/protected/statusHistory/' + id, (err, apiResponse) => {
        if (!err){
          this.statusList = apiResponse;
        }
      });
    }
  },
  created() {
    this.loadHistory(this.opportunityId);
  }
}
</script>
