<template>
  <div class="white-box">
    <div class="row mt-4">
      <div class="col h2 text-center">
        <p><strong>{{$t('opportunity.freeRegistrationCompleted.mainMessage')}} </strong></p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col h3 text-center">
        <p>{{$t('opportunity.freeRegistrationCompleted.clarification')}}</p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col text-center">
        <form role="form">
          <button @click="goHome()" class="btn btn-lg btn-primary" type="submit">{{$t('opportunity.freeRegistrationCompleted.backButton')}}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoPayedOpportunityResultView',
  methods: {
    goHome () {
      this.$router.push("/");
    }
  },
  mounted() {
    window.scrollTo(0,0);
    localStorage.removeItem("buildingOpportunityId");
  }
}
</script>
