<template>
  <div class="modal fade" ref="modalAlert" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" :aria-label="$t('commons.close')" @click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">{{ $t(title) }}</h4>
        </div>
        <div v-if="active" class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="close()">{{ $t(closeText) }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { MESSAGES_TITLES } from '@/constants.js'
  export default {
    name: 'AlertModal',
    props: {
      closeText: {
        type: String,
        default: 'commons.close'
      }
    },
    data() {
      return {
        active: false,
        title : ""
      }
    },
    methods: {
      show(messageTitle) {
        if(null == messageTitle){
          this.title = MESSAGES_TITLES.info.label
        } else {
          this.title = messageTitle.label
        }

        this.active = true
        $(this.$refs.modalAlert).modal('show')
      },
      close() {
        $(this.$refs.modalAlert).modal('hide')
      }
    },
    mounted: function () {
      $(this.$refs.modalAlert).on('hidden.bs.modal', (e) => {
        this.active = false
        this.$emit('close')
      })
    }
  }
</script>
<style scoped>
.modal-header, .modal-footer {
  background-color: #f5f5f5;
}
</style>
